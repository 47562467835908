//------------------------------------------------------------------------
//	IMPORTS für alle Routen
//------------------------------------------------------------------------
import Vue from "vue";
import VueRouter from "vue-router";
// import store from "@/store";

import Home from "@/views/Home.vue";

import accountRoutes from "./accountRoutes";
import authRoutes from "./authRoutes";
import taskRoutes from "./taskRoutes";
import jobRoutes from "./jobRoutes";

Vue.use(VueRouter);

const routes = [

  ...accountRoutes,
  ...authRoutes,
  ...taskRoutes,
  ...jobRoutes,    
  
  {
    path: "/",
    name: "Home",
    component: Home,
  },
 
  {
    path: "*",
    name: "404",
    component: () => import("@/views/404.vue"),
  },

];
const router = new VueRouter({
  routes,
  
});

// SOBALD DER ROUTER BZW LINK GEÄNDERT WIRD, WERDEN DIE EINSTELLUNGEN GELADEN.
router.beforeEach((to, from, next) => {
  // if (store.getters["authenticated"]) {
  //   // if (this.authenticated) {
  //   store.dispatch("loadCreditBalance");
  //   // LADET DIE GLOBALEN EINSTELLUNGEN, NEWS, ECT ALS JSON
  //   store.dispatch("loadGlobalOptions").then(() => {
  //     store.dispatch("appVersionChecker");
  //   });
  //   store.dispatch("getAllChatMessagesUnread");
  // }
  next();
});

export default router;
