<template>
  <v-expansion-panels v-model="taskData.panel" multiple>
    <v-expansion-panel>
      <v-expansion-panel-header>Kundeninformationen</v-expansion-panel-header>
      <v-expansion-panel-content>
        <strong>ID#: </strong> {{ taskData.user_id }}<br />
        <strong>Vorname: </strong> {{ taskData.user_firstName }}<br />
        <strong>Nachname: </strong> {{ taskData.user_secondName }}<br />
        <strong>Email: </strong> {{ taskData.user_email }}<br />
        <strong>Registriert: </strong> {{ taskData.user_registered }}<br />
        <strong>Activ: </strong> {{ taskData.user_active }}<br />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header>Fahrzeuginformationen</v-expansion-panel-header>

      <v-expansion-panel-content>
        <strong>Kategorie:</strong> {{ taskData.vehicle_category }}<br />
        <strong>Fahrzeug:</strong> {{ taskData.vehicle_brand }} {{ taskData.vehicle_model }}<br />
        <strong>Motor:</strong> {{ taskData.vehicle_engineCCm }}L {{ taskData.vehicle_enginePS }}PS {{ taskData.vehicle_ecuBrand }} {{ taskData.vehicle_ecuType }}<br />
        <strong>Checksumme:</strong>{{ taskData.vehicle_ecuChecksum }} <br />
        <strong>ACM ID#: </strong> {{ taskData.vehicle_acm_fileID }}<br />
        <strong>Tier#: </strong> {{ taskData.vehicle_emission }}<br />
        <v-btn @click="changeVehicle(taskData)" small rounded color="primary" class="white--text mt-2"> Fahrzeug ändern</v-btn>
        <v-divider class="mt-2 mb-2"></v-divider>
        <v-text-field v-model="taskData.job_vehicle_VIN" label="VIN" outlined hide-details dense>
          {{ taskData.job_vehicle_VIN }}
        </v-text-field>
        <br />

        <v-btn @click="changeVINNumber(taskData)" small rounded color="primary" class="white--text"> VIN ändern</v-btn>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel>
      <v-expansion-panel-header>Fileinformationen</v-expansion-panel-header>
      <v-expansion-panel-content>
        <strong>Filename: </strong>{{ taskData.task_fileName }} <br />
        <strong>Filentemp: </strong>{{ taskData.task_fileNameTemp }} <br />
        <strong>Filegröße: </strong>{{ taskData.task_fileSize }}<br />
        <strong>FileType: </strong>{{ taskData.task_fileType }} <br />
        <strong>Location: </strong>{{ taskData.task_fileLocation }} <br />
        <v-divider class="mt-2 mb-2"></v-divider>
        <strong>Modfile: </strong>{{ taskData.task_fileNameModFile }} <br />
        <strong>ModfileType: </strong>{{ taskData.task_fileTypeModFile }} <br />
        <strong>ModfileLocation: </strong>{{ taskData.task_fileLocationModFile }} <br />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
  name: "TaskItemInformations",
  props: {
    taskData: Object,
  },

  data() {
    return {};
  },

  methods: {
    changeVehicle(taskData) {},
    changeVINNumber(taskData) {},
  },
};
</script>
