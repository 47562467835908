// Store jobs im VUEX Store
import axios from "axios";
export default {
  state: {},
  mutations: {},

  //read our informations from the state and show it anywhere in the app
  getters: {},
  //actions commit mutations
  actions: {
    // erstellt einen neuen Aufträge in der Datenbank
    uploadOriginalTaskFile({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/uploadOriginalTaskFile", payload, {
            headers: {
              "Content-Type": "application/json",
              "Content-Disposition": "multipart/form-data",
            },
          })
          .then((response) => {
            resolve(response.data);
            const statusObj = {
              toast: true,
              position: "top-start",
              icon: "success",
              title: "Job, Task und Fileupload OK!",
              showConfirmButton: false,
              timer: 3000,
            };
            commit("ALERT_STATUS", statusObj);
          })
          .catch((error) => {
            console.log(error);
            resolve(false);
            const statusObj = {
              toast: true,
              position: "top-start",
              icon: "error",
              title: "Job, Task und Fileupload konnte nicht erstellt werden! 0x7755",
              showConfirmButton: false,
              timer: 5000,
            };
            commit("ALERT_STATUS", statusObj);
          });
      });
    },
    uploadSupportFile({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/uploadSupportFile", payload, {
            headers: {
              "Content-Type": "application/json",
              "Content-Disposition": "multipart/form-data",
            },
          })
          .then((response) => {
            resolve(response.data);
            const statusObj = {
              toast: true,
              position: "top-start",
              icon: "success",
              title: "Support File Upload OK",
              showConfirmButton: false,
              timer: 3000,
            };
            commit("ALERT_STATUS", statusObj);
          })
          .catch((error) => {
            console.log(error);
            resolve(false);
            const statusObj = {
              toast: true,
              position: "top-start",
              icon: "error",
              title: "Support Fileupload nicht ok! 0x77455",
              showConfirmButton: false,
              timer: 5000,
            };
            commit("ALERT_STATUS", statusObj);
          });
      });
    },

    /**
     * Lädt eine Datei herunter
     * @param commit
     * @param {Object} payload
     * @return {Promise<unknown>}
     */

    downloadFile({ commit }, payload) {
      return new Promise((resolve) => {
        let fileData = {
          task_id: payload.taskData.task_id,
          downloadType: payload.downloadType,
        };

        console.log("downloadFile: ", payload.taskData);
        console.log("data download : ", fileData);

        axios.post("/api/file/download", fileData).then((response) => {
          const data = response.data;

          if (data.code === 55) {
            console.log("Kein File vorhanden!", data);
            commit("ALERT_STATUS", response.data.message);
            resolve(data);
          } else {
            let ori_fileName = payload.taskData.task_fileName;
            let ori_fileType = payload.taskData.task_fileType;
            let mod_fileName = payload.taskData.task_fileNameModFile;
            let mod_fileType = payload.taskData.task_fileTypeModFile;

            const blob = new Blob([response.data]);
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);

            console.log("ori_fileName: ", ori_fileName);
            console.log("ori_fileType: ", ori_fileType);
            console.log("mod_fileName: ", mod_fileName);
            console.log("mod_fileType: ", mod_fileType);
            // ori or mod

            if (ori_fileName !== null && ori_fileName !== undefined) {
              link.download = ori_fileName;
            } else if (mod_fileName !== null && mod_fileName !== undefined) {
              link.download = mod_fileName;
            }
            link.click();
          }
        });
      });
    },
  },
};
