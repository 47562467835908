<template>
  <div
    class="dropzone"
    @dragover.prevent
    @dragenter.prevent="(dragging = true), (showDropMessage = true)"
    @dragleave.prevent="(dragging = false), (showDropMessage = false)"
    @drop.prevent="handleDrop"
    :class="{ dragging: dragging }">
    <div v-if="!file">
      <!-- <input type="file" ref="fileInput" style="display: none" @change="handleFileInput"> -->
      <input type="file" ref="fileInput" style="display: none" @change="handleFileInput" />
      <span v-if="!file">Datei wählen oder (Drag-drop)</span><br />
      <v-btn text color="primary" @click="selectFile" v-if="!dragging">Datei auswählen</v-btn>
      <span v-if="showDropMessage" class="green accent-4">Datei hier ablegen</span>
    </div>
    <div v-else>
      <span>Datei: {{ file.name }}</span
      ><br />
      <v-btn text color="red accent-4" @click="cancelFile">Abbrechen</v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: "DragAndDrop",
  props: {
    // validUploadFileTypes: [],
  },

  data() {
    return {
      dragging: false,
      showDropMessage: false,
      file: null,
    };
  },

  methods: {
    cancelFile() {
      this.file = null;
      this.showDropMessage = false;
    },
    selectFile() {
      this.$refs.fileInput.click();
      this.showDropMessage = false;
    },
    handleDrop(event) {
      console.log("event", event.dataTransfer);
      const file = event.dataTransfer.files[0];
      const fileName = file.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf(".") + 1);

      // if (this.validUploadFileTypes.includes(fileExtension)) {
      this.dragging = false;
      this.showDropMessage = false;
      this.file = file;

      this.$emit("fileToUpload", this.file);
      // File extension is valid
      // Do something with the file...
      // } else {
      //   // File extension is not valid
      //   console.log("Invalid file extension!");
      //
      //   this.$swal({
      //     title: "Fehler",
      //     text: "Ungültige Dateiendung!",
      //     icon: "error",
      //     confirmButtonText: "OK",
      //   });
      // }
    },

    handleFileInput(event) {
      const file = this.$refs.fileInput.files[0];
      const fileName = file.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf(".") + 1);

      // this.fileEndingChecker(fileName, fileExtension);

      // if (this.validUploadFileTypes.includes(fileExtension)) {
      this.file = event.target.files[0];
      this.showDropMessage = false;

      this.$emit("fileToUpload", this.file);
      // File extension is valid
      // Do something with the file...
      // } else {
      //   // File extension is not valid
      //   console.log("Invalid file extension!");
      //
      //   this.$swal({
      //     title: "Fehler",
      //     text: "Ungültige Dateiendung!",
      //     icon: "error",
      //     confirmButtonText: "OK",
      //   });
      // }
    },
  },
};
</script>
<style scoped>
.dropzone {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  /* height: 300px; */
  height: 100px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  font-size: 1.2em;
  text-align: center;
}

.dropzone.dragging {
  background-color: #f0f0f0;
  color: #000;
}

.dropzone p {
  margin: 1em;
}

.dropzone button {
  margin: 0.5em;
}
</style>
