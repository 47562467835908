<template>
  <v-app-bar app dark clipped-left elevation="0" flat class="" color="primary">
    <div class="d-flex align-items-center">
      <v-btn class="mr-auto" color="primary" fab small @click.stop="emitToggleSidebar()">
        <v-icon v-if="openSidebar" :class="['rotator', openSidebar ? 'is-rotated' : '']">mdi-menu</v-icon>
        <v-icon v-else color="white">mdi-menu</v-icon>
      </v-btn>

      <transition name="headline-slide">
        <h2 class="ml-5" v-if="headlineIntersectingData && !headlineIntersectingData.isIntersecting">{{ headlineIntersectingData.title }}</h2>
      </transition>
    </div>
    <div class="ml-auto">
      <v-menu v-if="userAccessData != null" v-model="accountInfoMenu" :close-on-content-click="false" :nudge-width="150" max-width="500" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-badge :color="connectionStatus ? 'green accent-4' : 'red accent-4'" avatar bordered dot offset-x="22" offset-y="10" overlap>
            <v-avatar dark class="mr-4" color="amber accent-3" size="30" small v-bind="attrs" v-on="on">
              <v-icon fab small> mdi-account-circle</v-icon>
            </v-avatar>
          </v-badge>
        </template>

        <v-card elevation="0" shaped>
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar color="primary">
                  <v-icon fab dark> mdi-account-circle</v-icon>
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  >Benutzerdaten<span>
                    <span class="small"> {{ userAccessData.data.user_firstName }} </span> #{{ userAccessData.data.user_id }}
                  </span></v-list-item-title
                >
                <v-list-item-subtitle>
                  <v-chip :color="$customFunctions.userActiveBadgeColor(userAccessData.data)" class="" small> {{ $customFunctions.userActiveText(userAccessData.data) }} </v-chip
                  ><v-chip :color="$customFunctions.userRightsColor(userAccessData.data)" class="ml-2 mr-2" small>
                    {{ $customFunctions.userRightsText(userAccessData.data) }}
                  </v-chip>
                </v-list-item-subtitle>

                <v-list-item-title class="mt-4">Dark - Night & Sprachen</v-list-item-title>
                <v-list-item-subtitle>
                  <div class="d-flex">
                    <div class="mr-2">
                      <DayNightSwitch />
                    </div>
                    <div>
                      <Language></Language>
                    </div>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-title class="mt-4">Portal Version</v-list-item-title>
                <v-list-item-subtitle>
                  <div class="">V{{ appVersion }}</div>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action></v-list-item-action>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list-item max-width="400">
            <v-list-item-content>
              <v-list-item-title>Email: {{ userAccessData.data.user_email }}</v-list-item-title>
              <v-list-item-title>Registriert: {{ userAccessData.data.user_registered }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item max-width="400">
            <v-list-item-content>
              <v-list-item-title>Schlüssel: {{ userAccessData.data.key_text }}</v-list-item-title>
              <v-list-item-title>Schlüssel erstellt: {{ userKeyCreated }}</v-list-item-title>
              <v-list-item-title>Schlüssel gültig bis: {{ userKeyValidUntil }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-card-actions>
            <v-btn color="red accent-4 white--text" rounded @click="emitSignOutRequest">
              <v-icon>mdi-door</v-icon>
              Logout
            </v-btn>
            <v-spacer></v-spacer>
            <DevModeButton></DevModeButton>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </v-app-bar>
</template>
<script>
import DevModeButton from "@/components/ui/DevModeButton.vue";
import Language from "@/components/ui/Language.vue";
import { mapGetters } from "vuex";
import DayNightSwitch from "@/components/ui/DayNightSwitch.vue";

export default {
  name: "Topbar",
  components: { DayNightSwitch, Language, DevModeButton },
  props: {
    openSidebar: Boolean,
    headlineIntersectingData: {
      type: Object,
      default: () => {
        return {
          isIntersecting: false,
          title: "",
        };
      },
    },
  },

  data: () => ({
    appVersion: localStorage.getItem("FlowmaticAppVersion") || 0,
    darkMode: JSON.parse(localStorage.getItem("darkModeTF24Portal")) || false,
    accountInfoMenu: false,
  }),

  mounted() {
    if (localStorage.darkMode) {
      this.$vuetify.theme.dark = this.darkMode;
    } else {
      localStorage.setItem("darkModeTF24Portal", JSON.stringify(this.darkMode));
      this.$vuetify.theme.dark = this.darkMode;
    }
  },

  watch: {
    headlineIntersectingData: {
      handler: function (newVal, oldVal) {
        if (newVal) {
          console.log("headlineIntersectingData", newVal);
        }
      },
      deep: true,
    },
  },

  methods: {
    emitToggleSidebar() {
      this.$emit("toggleSidebar");
    },

    emitSignOutRequest() {
      this.$emit("signOutRequest");
    },

    toggleSubmenu(index) {
      if (this.selectedItem === index && this.items[index].childrenExpanded) {
        this.closeSubmenu();
      } else {
        this.selectedItem = index;
        this.items[index].childrenExpanded = true;
      }
    },
    closeSubmenu() {
      this.items[this.selectedItem].childrenExpanded = false;
      this.selectedItem = null;
    },

    handleItemClick(index) {
      if (this.selectedItem === index && this.items[index].children) {
        this.items[index].childrenExpanded = !this.items[index].childrenExpanded;
      } else {
        this.selectedItem = index;
        this.items.forEach((item, i) => {
          if (i !== index) {
            item.childrenExpanded = false;
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "user",
      userAccessData: "userAccessData",
      alertUpdateMessage: "alertUpdateMessage",
      loadCreditBalance: "loadCreditBalance",
    }),

    connectionStatus() {
      return this.$store.getters.getSocketConnectionStatus;
    },

    userKeyCreated() {
      if (this.userAccessData == null) return "";
      return this.$customFunctions.dateFormatUS(this.userAccessData.data.key_created);
    },
    userKeyValidUntil() {
      if (this.userAccessData == null) return "";
      return this.$customFunctions.dateFormatUS(this.userAccessData.data.key_valid_until);
    },
  },
};
</script>

<style scoped>
.headline-slide-enter-active,
.headline-slide-leave-active {
  transition: transform 0.2s;
}

.headline-slide-enter,
.headline-slide-leave-to {
  transform: translateX(100%);
}
</style>
