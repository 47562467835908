<template>
  <v-btn fab x-small @click="darkModeSwitch" :color="darkMode ? 'blue accent-2' : 'primary'" elevation="0">
    <v-icon> {{ darkMode ? "mdi-weather-night" : "mdi-white-balance-sunny" }}</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "DayNightSwitch",
  data: () => ({
    darkMode: JSON.parse(localStorage.getItem("darkModeTF24Portal")) || false,
  }),

  mounted() {
    if (localStorage.darkMode) {
      this.$vuetify.theme.dark = this.darkMode;
    } else {
      localStorage.setItem("darkModeTF24Portal", JSON.stringify(this.darkMode));
      this.$vuetify.theme.dark = this.darkMode;
    }
  },

  methods: {
    darkModeSwitch() {
      this.darkMode = !this.darkMode;
      localStorage.setItem("darkModeTF24Portal", JSON.stringify(this.darkMode));
      this.$vuetify.theme.dark = this.darkMode;
    },
  },
};
</script>
