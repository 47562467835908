import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import "@/scss/overrides.scss"; // Verwende hier den korrekten Pfad zu deiner overrides.scss-Datei

Vue.use(Vuetify);

const vuetify = new Vuetify({
  iconfont: "mdi",
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#003b5b",
        warning: "#cb9d00",
        success: "#46c93a",
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
      dark: {
        primary: "#ffc107",
        warning: "#cb9d00",
        success: "#46c93a",
        accent: colors.shades.black,
        error: colors.red.accent3,
      },
    },
  },
});
export default vuetify;
