<template>
  <v-app :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'">
    <!-- <Loading :active.sync="isLoading" :opacity="0.8" :blur="'2px'" :color="'#fff'" :background-color="'#363d44'"></Loading> -->
    <div class="text-center">
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
    <v-main>
      <SideBar :headlineIntersectingData="headlineIntersectingData"></SideBar>
      <router-view @intersectingData="setIntersectingData"></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import SocketioService from "@/socketServiceClient.js";
import SideBar from "@/components/ui/Sidebar.vue";

export default {
  name: "App",

  components: { SideBar },

  data: () => ({
    DevMode: false,
    key: 1432,
    inputKey: "",
    value: 1,
    loadingTimer: null,
    reconnectTimeout: null,
    headlineIntersectingData: null,
  }),

  mounted() {
    // this.$store.dispatch("showPageLoadingIndicator", true);
    // this.$store.dispatch("showPageLoadingIndicator", false);
    // if (SocketioService.socket.connect) {
    //   this.messageReconnectSuccess();
    //   // clearTimeout(this.reconnectTimeout);
    //   // console.log("Connected to socket server");
    //   this.$store.dispatch("setSocketConnectionStatus", true);
    // }
  },

  // created() {
  // window.addEventListener("beforeunload", this.handleBeforeUnload);
  // if (this.userAccessData != null) {
  //   SocketioService.setupSocketConnection();
  //   SocketioService.joinAppRoom(this.userAccessData.data.user_id);
  //   SocketioService.socket.on("listenForAppRefresh_" + this.userAccessData.data.user_id, (res) => {
  //     console.log("App wird neu geladen");
  //     window.location.reload();
  //   });
  //   if (SocketioService.socket.disconnected) {
  //     this.messageReconnect();
  //     this.$store.dispatch("setSocketConnectionStatus", false);
  //     SocketioService.connect();
  //   }
  //   // }, 2000);
  //   SocketioService.socket.on("connect", () => {
  //     this.messageReconnectSuccess();
  //     clearTimeout(this.reconnectTimeout);
  //     console.log("Connected to socket server");
  //     this.$store.dispatch("setSocketConnectionStatus", true);
  //   });
  //   SocketioService.socket.on("disconnect", (reason) => {
  //     this.messageReconnect();
  //     console.log(`Disconnected from socket server. Reason: ${reason}`);
  //     this.$store.dispatch("setSocketConnectionStatus", false);
  //   });
  // }
  // },

  created() {
    // window.addEventListener("beforeunload", this.handleBeforeUnload);

    if (this.userAccessData != null) {
      SocketioService.setupSocketConnection();
      SocketioService.joinAppRoom(this.userAccessData.data.user_id);

      SocketioService.socket.on("listenForAppRefresh_" + this.userAccessData.data.user_id, (res) => {
        console.log("App wird neu geladen");
        window.location.reload();
      });

      if (SocketioService.socket.disconnected) {
        this.messageReconnect();

        this.$store.dispatch("setSocketConnectionStatus", false);

        SocketioService.connect();
      }
      // }, 2000);

      SocketioService.socket.on("connect", () => {
        this.messageReconnectSuccess();
        clearTimeout(this.reconnectTimeout);
        console.log("Connected to socket server");
        this.$store.dispatch("setSocketConnectionStatus", true);
      });

      SocketioService.socket.on("disconnect", (reason) => {
        this.messageReconnect();
        console.log(`Disconnected from socket server. Reason: ${reason}`);
        this.$store.dispatch("setSocketConnectionStatus", false);
      });
    }
  },

  beforeDestroy() {
    // window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },

  watch: {
    // bei jeder RoutenÄnderung wird geprüft ob eine neue Appversion verfügbar ist
    $route(to, from) {
      clearTimeout(this.loadingTimer);
      this.loadingTimer = setTimeout(() => {
        this.$store.dispatch("showPageLoadingIndicator", true);
      }, 500); // Anzeigen des Ladeindikators nach 500 ms
      if (to) {
        clearTimeout(this.loadingTimer);
        this.$store.dispatch("showPageLoadingIndicator", false);
      }
    },
  },

  methods: {
    setIntersectingData(data) {
      console.log("App.vue: setIntersectingData: ", data);
      this.headlineIntersectingData = data;
    },
    // handleBeforeUnload(event) {
    //   // Warnung anzeigen, dass die Seite neu geladen wird
    //   event.preventDefault();
    //   event.returnValue = "Sind Sie sicher, dass Sie die WebApp neu laden möchten?";
    // },

    messageReconnect() {
      this.$swal({
        toast: true,
        position: "bottom-end",
        icon: "warning",
        title: "Verbindung zum Server wird aufgebaut...",
        showConfirmButton: false,
        timer: 3000,
      });
    },

    messageReconnectSuccess() {
      this.$swal({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: "Verbindung zum Server erfolgreich wiederhergestellt",
        showConfirmButton: false,
        timer: 3000,
      });
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "user",
      userAccessData: "userAccessData",
      alertUpdateMessage: "alertUpdateMessage",
    }),
    isLoading() {
      return this.$store.getters.showPageLoadingIndicator;
    },
  },
};
</script>
