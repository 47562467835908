// Store credits im VUEX Store
import axios from "axios";

export default {
  state: {
    credits: [],
    creditBalance: {},
    creditsAdmin: [],
    creditsCustomer: [],
  },
  mutations: {
    CREDITS_LOAD(state, payload) {
      state.credits = payload;
    },
    CREDIT_BALANCE_LOAD(state, payload) {
      state.creditBalance = payload;
    },
    CREDITS_ADMIN_LOAD(state, payload) {
      state.creditsAdmin = payload;
    },
    CREDITS_CUSTOMER_LOAD(state, payload) {
      state.creditsCustomer = payload;
    },
  },

  //read our informations from the state and show it anywhere in the app
  getters: {
    loadCredits(state) {
      return state.credits;
    },
    loadCreditBalance(state) {
      return state.creditBalance;
    },
    loadCreditsAdmin(state) {
      return state.creditsAdmin;
    },
    loadCustomerCredits(state) {
      return state.creditsCustomer;
    },
  },
  //actions commit mutations
  actions: {
    // ladet alle Aufträge von der Datenbank
    loadCredits({ commit }, payload) {
      commit("SHOW_TABLE_LOADING_INDICATOR", true);
      return new Promise((resolve) => {
        const filter = {
          filterMode: payload.filterMode,
          filterStartDate: payload.filterStartDate,
          filterEndDate: payload.filterEndDate,
        };
        axios
          .get("api/credits", {
            params: filter,
          })
          .then((response) => {
            const credits = [];
            response.data.data.forEach((credit) => {
              credits.push(credit);
            });
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            commit("CREDITS_LOAD", credits);
            resolve();
          })
          .catch((error) => {
            console.log("error loading credits data: " + error);
          });
      });
    },
    loadCreditBalance({ commit }) {
      return new Promise((resolve) => {
        axios
          .get("api/credits/balance")
          .then((response) => {
            if (response?.data?.data?.length === 1) {
              const balance = response.data.data[0].transaction_balance;
              commit("CREDIT_BALANCE_LOAD", balance);
            } else {
              commit("CREDIT_BALANCE_LOAD", response.data);
            }
            resolve();
          })
          .catch((error) => {
            console.log("error loading credits balance data: " + error);
          });
      });
    },

    loadCreditsForAdmin({ commit }, payload) {
      return new Promise((resolve) => {
        const filter = {
          filterMode: payload.filterMode,
          filterStartDate: payload.filterStartDate,
          filterEndDate: payload.filterEndDate,
        };

        axios
          .get("tf24API/getCreditsFromAllCustomers", {
            params: filter,
          })
          .then((response) => {
            commit("CREDITS_ADMIN_LOAD", response.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading credits for admin error : " + error);
          });
      });
    },

    loadCustomerCredits({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .get("tf24API/getCreditsFromUserWithID", {
            params: payload,
          })
          .then((response) => {
            commit("CREDITS_CUSTOMER_LOAD", response.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading credits for customer error : " + error);
          });
      });
    },

    updateCreditsForCustomer({ dispatch }, paymentData) {
      const payload = {
        transaction_type: paymentData.transaction_type,
        credits: paymentData.amount.value,
        comments: paymentData.credits_comment,
        invoiceId: paymentData.invoiceId,
      };

      console.log("Payload: " + JSON.stringify(payload));
      console.log("paymentData: " + JSON.stringify(paymentData));

      return new Promise((resolve) => {
        axios
          .put("api/credits", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(() => {
            dispatch("loadCreditBalance");
            resolve(true);
          })
          .catch((error) => {
            console.log("error update credits customer " + error);
            console.log(error);
          });
      });
    },
    createInvoice(_, payload) {
      return new Promise((resolve) => {
        const dummyResponse = {};
        resolve(dummyResponse);

        axios
          .post("tf24API/createInvoice", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("error createInvoice " + error);
            console.log(error);
          });
      });
    },
    getInvoiceID(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/getInvoiceID", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("error getInvoiceID " + error);
            console.log(error);
          });
      });
    },
    createDeliveryNote(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/createDeliveryNote", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("error createDeliveryNote " + error);
            console.log(error);
          });
      });
    },

    updateCreditsWithDeliveryNoteID(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateCreditsWithDeliveryNoteID", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error updateCreditsWithDeliveryNoteID " + error);
            console.log(error);
          });
      });
    },

    getDeliveryNoteID(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/getDeliveryNoteID", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("error getDeliveryNoteID " + error);
            console.log(error);
          });
      });
    },
  },
};
