// Store jobs im VUEX Store
import axios from "axios";

export default {
  state: {
    jobs: [],
    jobStatus: {},
    jobCreateModal: false,
    jobEditModal: false,
    jobData: {},
    jobDetailsShow: false,
    jobDetailsData: {},
    jobsAdminOpen: [],
    jobsAdminClosed: [],
  },
  mutations: {
    JOBS_CLEAR(state) {
      state.jobs = [];
    },
    JOB_CREATE(state, payload) {
      state.jobs.push(payload);
    },
    JOBS_LOAD(state, payload) {
      state.jobs = payload;
    },
    JOB_STATUS(state, payload) {
      state.jobStatus = payload;
    },
    JOB_CREATE_MODAL(state, payload) {
      state.jobCreateModal = payload;
    },
    JOB_EDIT_MODAL(state, payload) {
      state.jobEditModal = payload;
    },
    JOB_DATA(state, payload) {
      state.jobData = payload;
    },

    // für die einzelne Job ansicht wo speziell nur diese Job Daten angezeigt werden
    JOB_DETAILS_SHOW(state, payload) {
      state.jobDetailsShow = payload;
    },
    JOB_DETAILS_DATA(state, payload) {
      state.jobDetailsData = payload;
    },

    JOB_ADMIN_OPEN(state, payload) {
      state.jobsAdminOpen = payload;
    },
    JOB_ADMIN_CLOSED(state, payload) {
      state.jobsAdminClosed = payload;
    },
  },

  getters: {
    loadJobs(state) {
      return state.jobs;
    },
    jobStatus(state) {
      return state.jobStatus;
    },
    jobCreateModal(state) {
      return state.jobCreateModal;
    },
    jobEditModal(state) {
      return state.jobEditModal;
    },
    jobData(state) {
      return state.jobData;
    },
    jobDetailsShow(state) {
      return state.jobDetailsShow;
    },
    jobDetailsData(state) {
      return state.jobDetailsData;
    },
    loadJobsAdminOpen(state) {
      return state.jobsAdminOpen;
    },
    loadJobsAdminClosed(state) {
      return state.jobsAdminClosed;
    },
  },
  //actions commit mutations
  actions: {
    clearJobs({ commit }) {
      commit("JOBS_CLEAR");
    },
    jobCreateToggleModal({ commit }, payload) {
      commit("JOB_CREATE_MODAL", payload);
    },
    jobEditToggleModal({ commit }, payload) {
      commit("JOB_EDIT_MODAL", payload);
    },
    jobData({ commit }, payload) {
      commit("JOB_DATA", payload);
    },
    jobDetailsData({ commit }, payload) {
      commit("JOB_DETAILS_DATA", payload);
    },
    jobDetailsShow({ commit }, payload) {
      commit("JOB_DETAILS_SHOW", payload);
    },

    // ladet alle Aufträge von der Datenbank
    loadJobs({ commit }, payload) {
      commit("SHOW_TABLE_LOADING_INDICATOR", true);
      return new Promise((resolve) => {
        const filter = {
          filterMode: payload.filterMode,
          filterStartDate: payload.filterStartDate,
          filterEndDate: payload.filterEndDate,
        };
        axios
          .get("tf24API/getJobs", {
            params: filter,
          })
          .then((response) => {
            const jobs = [];
            response.data.data.forEach((job) => {
              jobs.push(job);
            });
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            commit("JOBS_LOAD", jobs);
            resolve();
          })
          .catch((error) => {
            console.log("error loading jobs data: " + error);
          });
      });
    },

    // lade alle JOBS vom User X
    getAllJobsFromUser({ commit }, payload) {
      commit("SHOW_TABLE_LOADING_INDICATOR", true);

      return new Promise((resolve, reject) => {
        const filter = {
          selectedDateRange: payload.selectedDateRange,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        };

        axios
          .get("api/jobs/getAllJobsFromUserWithTaskCount", { params: filter })
          .then((response) => {
            const jobData = response.data.data;

            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            commit("JOBS_LOAD", jobData);
            resolve();
            // const fetchPromises = jobData.map((job) => {
            //   return axios
            //     .get(`api/jobs/${job.job_id}/tasks`)
            //     .then((response) => {
            //       if (response.status === 200) {
            //         job.tasks = response.data.data;
            //       }
            //     })
            //     .catch((error) => {
            //       console.error(`Error fetching tasks for job ${job.job_id}:`, error);
            //     });
            // });

            // Promise.all(fetchPromises)
            //   .then(() => {
            //     commit("SHOW_TABLE_LOADING_INDICATOR", false);
            //     commit("JOBS_LOAD", jobData);
            //     resolve();
            //   })
            //   .catch((error) => {
            //     console.error("An error occurred during data fetching:", error);
            //     reject(error);
            //   });
          })
          .catch((error) => {
            console.error("Error loading JOBS data:", error);
            reject(error);
          });
      });
    },

    // ladet alle jobs für den Admin
    loadJobsForAdmin({ commit }, payload) {
      return new Promise((resolve) => {
        const filter = {
          filterMode: payload.filterMode,
          filterStartDate: payload.filterStartDate,
          filterEndDate: payload.filterEndDate,
        };

        const dataFinished = [];
        const dataOpen = [];

        axios
          .get("tf24API/getJobsFromAllCustomers", {
            params: filter,
          })
          .then((response) => {
            console.dir(response);
            console.dir(response.data);
            response.data.forEach((job) => {
              if (job.job_status == "finished") {
                dataFinished.push(job);
              } else {
                dataOpen.push(job);
              }
            });
            commit("JOB_ADMIN_OPEN", dataOpen);
            commit("JOB_ADMIN_CLOSED", dataFinished);
            resolve();
          })
          .catch((error) => {
            console.log("error loading jobs for admin error : " + error);
          });
      });
    },

    // ladet einen JobStatus mit der Oldjob ID X
    getJobStatusWithJobID({ commit }, payload) {
      return new Promise((resolve) => {
        const data = {
          job_id: payload,
        };
        axios
          .get("api/jobs/getJobStatusWithJobID", {
            params: data,
          })
          .then((response) => {
            commit("JOB_STATUS", response.data[0]);
            resolve(response.data[0]);
          })
          .catch((error) => {
            console.log("error loading Oldjob status: " + error);
          });
      });
    },

    // ladet einen JOB von der Datenbank mit der JOB ID
    loadJobDataWithJobID(_, payload) {
      console.log(payload);
      return new Promise((resolve) => {
        axios
          .get("api/jobs/getJobFromUserWithTaskCount/" + payload)
          .then((response) => {
            resolve(response.data.data[0]);
          })
          .catch((error) => {
            console.log("error loading single Oldjob data: " + error);
          });
      });
    },

    loadVehicleDataWithVehicleID(_, payload) {
      return new Promise((resolve) => {
        axios
          .get("api/vehicles/getVehicleDataWithVehicleID?vehicle_id=" + payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("error loading single vehicle data: " + error);
          });
      });
    },

    // erstellt einen neuen Aufträge in der Datenbank
    createJob({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("api/createJob", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            console.log("response.data.resultCreateJob: ", response.data.resultCreateJob);
            commit("JOB_DATA", response.data.resultCreateJob);
            resolve(response.data);
            const statusObj = {
              toast: true,
              position: "top-start",
              icon: "success",
              title: "Auftrag erfolgreich erstellt 12!",
              showConfirmButton: false,
              timer: 3000,
            };
            commit("ALERT_STATUS", statusObj);
          })
          .catch((error) => {
            console.log(error);
            resolve(false);
            const statusObj = {
              toast: true,
              position: "top-start",
              icon: "error",
              title: "Auftrag konnte nicht erstellt werden! 0x98",
              showConfirmButton: false,
              timer: 5000,
            };
            commit("ALERT_STATUS", statusObj);
          });
      });
    },

    // update den Aufträge mit den Änderungen
    updateJob({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateJob", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                const statusObj = {
                  toast: true,
                  position: "top-start",
                  icon: "success",
                  title: "Auftrag erfolgreich geändert!",
                  showConfirmButton: false,
                  timer: 3000,
                };
                commit("ALERT_STATUS", statusObj);

                resolve(true);
              } else {
                //success false
                const statusObj = {
                  toast: true,
                  position: "top-start",
                  icon: "info",
                  title: "Keine Änderungen für die Speicherung gefunden.",
                  showConfirmButton: false,
                  timer: 5000,
                };
                commit("ALERT_STATUS", statusObj);

                resolve(false);
              }
            } else {
              //anderer statuscode als 200
              //success false
              const statusObj = {
                toast: true,
                position: "top-start",
                icon: "error",
                title: "Fehler Auftrag konnte nicht geändert werden!. Code 0x20032",
                showConfirmButton: false,
                timer: 5000,
              };
              commit("ALERT_STATUS", statusObj);
              console.log("Auftrag Update Error! 0x2003277");

              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with update Auftrag" + error);
          });
      });
    },

    // löscht den Aufträge mit der ID
    updateJobData(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateJobData", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                //success false
                console.log("Job Daten geändert!");
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with Update Job Data" + error);
          });
      });
    },
    // löscht den Aufträge mit der ID
    updateJobAndAllTasks(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateJobAndAllTasks", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                //success false
                console.log("Auftrag Update und Tasks success false 0x990022");
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with Update Job und Tasks " + error);
          });
      });
    },
    // löscht den Aufträge mit der ID
    deleteJob(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/deleteJob", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                //success false
                console.log("Auftrag Löschung success false 0x990022");
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with Löschung Auftrag" + error);
          });
      });
    },
  },
};
