// Store vehicles im VUEX Store
import axios from "axios";
export default {
  // speichert alles objects, strings ect.
  state: {
    vehicleStatus: {},
    vehicles: [],
    vehicleBrands: [],
    vehicleCreateModal: false,
    vehicleEditModal: false,
  },
  mutations: {
    VEHICLES_CLEAR(state) {
      state.vehicles = [];
    },
    VEHICLE_STATUS(state, payload) {
      state.vehicleStatus = payload;
    },
    VEHICLE_CREATE(state, payload) {
      state.vehicles.push(payload);
    },
    VEHICLES_LOAD(state, payload) {
      state.vehicles = payload;
    },
    VEHICLE_BRANDS_LOAD(state, payload) {
      state.vehicleBrands = payload;
    },
    VEHICLE_CREATE_MODAL(state, payload) {
      state.vehicleCreateModal = payload;
    },
    VEHICLE_EDIT_MODAL(state, payload) {
      state.vehicleEditModal = payload;
    },
  },

  //read our informations from the state and show it anywhere in the app
  getters: {
    loadVehicles(state) {
      return state.vehicles;
    },
    loadVehicleBrands(state) {
      return state.vehicleBrands;
    },
    vehicleStatus(state) {
      return state.vehicleStatus;
    },
    vehicleCreateModal(state) {
      return state.vehicleCreateModal;
    },
    vehicleEditModal(state) {
      return state.vehicleEditModal;
    },
  },
  //actions commit mutations
  actions: {
    //dispatch = call other functions ({dispatch})
    //commit = commit mutations ({commit})
    //send user inputs form to API
    // get the Vehicle data from firebase

    getAllVehiclesByCategory({ commit }, payload) {
      console.log("getAllVehiclesByCategory payload ", payload);
      commit("VEHICLES_LOAD", []);
      commit("SHOW_TABLE_LOADING_INDICATOR", true);
      return new Promise((resolve) => {
        let searchCriteria = {
          vehicle_category: payload.vehicle_category,
        };
        axios
          .get("api/getAllVehiclesByCategory", { params: searchCriteria })
          .then((response) => {
            console.log("getAllVehiclesByCategory response ", response);
            if (response.data.vehicleData.length > 0) {
              commit("VEHICLES_LOAD", response.data.vehicleData);
            }
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            resolve();
          })
          .catch((error) => {
            console.log("error loading all Vehicles by Category data: ", error);
          });
      });
    },

    vehicleCreateToggleModal({ commit }, payload) {
      commit("VEHICLE_CREATE_MODAL", payload);
    },
    vehicleEditToggleModal({ commit }, payload) {
      commit("VEHICLE_EDIT_MODAL", payload);
    },

    loadAllVehiclesByCategory({ commit }, payload) {
      commit("SHOW_TABLE_LOADING_INDICATOR", true);
      return new Promise((resolve) => {
        console.log(payload);

        axios
          .get("api/getAllVehiclesFromBrand", {
            params: payload,
          })
          .then((response) => {
            console.log("response: ", response);
            const currentVehicles = [];
            response.data.vehicleData.forEach((vehicle) => {
              var vehicle_data = {
                vehicle_id: vehicle.vehicle_id,
                vehicle_category: vehicle.vehicle_category,
                vehicle_brand: vehicle.vehicle_brand,
                vehicle_model: vehicle.vehicle_model,
                vehicle_ecuBrand: vehicle.vehicle_ecuBrand,
                vehicle_ecuType: vehicle.vehicle_ecuType,
                vehicle_engineCCm: vehicle.vehicle_engineCCm,
                vehicle_enginePS: vehicle.vehicle_enginePS,
                vehicle_emission: vehicle.vehicle_emission,
                vehicle_ecuChecksum: vehicle.vehicle_ecuChecksum,
                vehicle_acm_fileID: vehicle.vehicle_acm_fileID,
              };
              currentVehicles.push(vehicle_data);
            });
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            commit("VEHICLES_LOAD", currentVehicles);
            resolve();
          })
          .catch((error) => {
            console.log("error loading all Vehicles by Category data: " + error);
          });
      });
    },

    loadVehicleBrandsWithCategories({ commit }, payload) {
      commit("SHOW_TABLE_LOADING_INDICATOR", true);

      //löscht alle Arrays damit diese nicht dupliziert werden

      return new Promise((resolve) => {
        axios
          .get("api/getAllBrandsByCategory", {
            params: payload,
          })
          .then((response) => {
            const currentVehicleBrands = [];
            console.log(response.data.vehicleData);
            response.data.vehicleData.forEach((vehicle) => {
              var vehicle_data = {
                vehicle_brand: vehicle.vehicle_brand,
              };
              currentVehicleBrands.push(vehicle_data);
            });
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            commit("VEHICLE_BRANDS_LOAD", currentVehicleBrands);
            resolve();
          })
          .catch((error) => {
            console.log("error loading Vehicle Brands data: " + error);
          });
      });
    },

    loadVehiclesWithSearchCriterials({ commit }, payload) {
      commit("SHOW_TABLE_LOADING_INDICATOR", true);

      return new Promise((resolve) => {
        var searchCriteria = {
          vehicle_category: payload.vehicle_category,
          vehicle_brand: payload.vehicle_brand,
        };

        axios
          .get("tf24API/getVehiclesWithSearchCriterials", {
            params: searchCriteria,
          })
          .then((response) => {
            const currentVehicles = [];
            response.data.forEach((vehicle) => {
              var vehicle_data = {
                vehicle_id: vehicle.vehicle_id,
                vehicle_category: vehicle.vehicle_category,
                vehicle_brand: vehicle.vehicle_brand,
                vehicle_model: vehicle.vehicle_model,
                vehicle_ecuBrand: vehicle.vehicle_ecuBrand,
                vehicle_ecuType: vehicle.vehicle_ecuType,
                vehicle_engineCCm: vehicle.vehicle_engineCCm,
                vehicle_enginePS: vehicle.vehicle_enginePS,
                vehicle_emission: vehicle.vehicle_emission,
                vehicle_ecuChecksum: vehicle.vehicle_ecuChecksum,
                vehicle_acm_fileID: vehicle.vehicle_acm_fileID,
              };
              currentVehicles.push(vehicle_data);
            });
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            commit("VEHICLES_LOAD", currentVehicles);
            resolve();
          })
          .catch((error) => {
            console.log("error loading Vehicles data: " + error);
          });
      });
    },

    updateVehicle(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateVehicle", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error updateVehicle " + error);
            console.log(error);
          });
      });
    },
  },
};
