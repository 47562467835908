<template>
  <div id="dialogTaskList">
    <v-dialog v-model="dialog" max-width="1200px" persistent>
      <TaskTable :tasks="tasks"></TaskTable>
    </v-dialog>
  </div>
</template>

<script>
import TaskTable from "@/components/tasks/TaskTable";

export default {
  name: "dialogTaskList",
  components: { TaskTable },
  data: () => ({}),

  props: { dialog: Boolean, tasks: Array },

  methods: {
    closeDialog() {
      this.$emit("close-dialog-task-list");
    },
  },
};
</script>

<style scoped lang="scss"></style>
