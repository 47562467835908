<template>
  <v-responsive v-if="selectedTask" class="overflow-y-hidden fill-height">
    <v-card flat class="d-flex flex-column fill-height">
      <v-card-title>
        <v-row justify="space-between">
          <v-col cols="12">
            <!--            selectedTask: {{ selectedTask }} messages: {{ messages.length }}-->
            <!--            ticket erstellen wenn messages vom Task X nicht vorhanden sind-->

            <div v-if="messages.length === 0" class="text-center">
              <v-icon x-large class="mb-4" color="amber accent-3">mdi-note-search</v-icon>
              <h3 class="">Ticket erstellen</h3>

              <small>Noch kein Ticket für den Task #{{ selectedTask.task_id }} erstellt. Klicke auf den Button um den Support zu kontaktieren.</small>
              <v-btn color="primary" rounded @click="createTicket(selectedTask)">Support Ticket erstellen für Task {{ selectedTask.task_id }}</v-btn>
            </div>

            <div v-else></div>

            <v-select :items="tasks" v-model="selectedTask" item-text="task_id" item-value="task_id" label="Task auswählen"></v-select>
          </v-col>
          <!--          <v-col cols="12" sm="3">-->
          <!--            <div class="d-flex">-->
          <!--              <div v-for="method in getMethods" :key="method"><img alt="method" :src="getMethodIcons" width="60" /></div>-->
          <!--            </div>-->
          <!--          </v-col>-->

          <!--          <v-col cols="12" sm="5">-->
          <!--            <v-menu offset-y class="mr-4">-->
          <!--              <template v-slot:activator="{ on }">-->
          <!--                <v-btn elevation="1" color="secondary" :outlined="false" dark rounded dense text v-on="on"> Actions </v-btn>-->
          <!--              </template>-->
          <!--              <v-list dense>-->
          <!--                <v-list-item>-->
          <!--                  <v-btn elevation="1" color="secondary" :outlined="false" dark rounded dense text> Upgrade</v-btn>-->
          <!--                </v-list-item>-->
          <!--                <v-list-item>-->
          <!--                  <v-btn elevation="1" color="secondary" :outlined="false" dark rounded dense text> Upgrade</v-btn>-->
          <!--                </v-list-item>-->
          <!--                <v-list-item>-->
          <!--                  <DownloadFile-->
          <!--                    :task-data="{}"-->
          <!--                    :show-text="true"-->
          <!--                    :show-icon="true"-->
          <!--                    icon-size="1.2rem"-->
          <!--                    icon-color="white"-->
          <!--                    :btnShowTextShort="false"-->
          <!--                    btnTextLong="Original"-->
          <!--                    btnTextShort="Ori"-->
          <!--                    :btnOutlined="false"-->
          <!--                    btnColor="primary"-->
          <!--                    btnColorText="white&#45;&#45;text"-->
          <!--                    downloadType="ori" />-->
          <!--                </v-list-item>-->
          <!--              </v-list>-->
          <!--            </v-menu>-->
          <!--            <DownloadFile-->
          <!--              :task-data="{}"-->
          <!--              :show-text="true"-->
          <!--              :show-icon="true"-->
          <!--              icon-size="1.2rem"-->
          <!--              icon-color="white"-->
          <!--              :btnShowTextShort="false"-->
          <!--              btnTextLong="Modified"-->
          <!--              btnTextShort="Mod"-->
          <!--              :btnOutlined="false"-->
          <!--              btnColor="primary"-->
          <!--              btnColorText="white&#45;&#45;text"-->
          <!--              downloadType="mod" />-->
          <!--          </v-col>-->
        </v-row>
      </v-card-title>

      messages {{ messages }}
      <v-card-text class="flex-grow-1 overflow-y-auto" ref="listContainer">
        <template v-for="msg in messages">
          <div :class="{ 'd-flex flex-row-reverse': msg.me }">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-hover v-slot:default="{ hover }">
                  <v-chip :color="getMessageColor(msg)" dark style="height: auto; white-space: normal" class="pa-4 mb-2" v-on="on">
                    <v-row>
                      <v-col cols="12" sm="2">
                        <v-icon v-if="msg.isIcon">{{ msg.avatar }}</v-icon>
                        <v-avatar v-else dark class="mr-4" color="amber accent-3" size="30" small>
                          <span class="white--text">{{ msg.avatar }} </span>
                        </v-avatar>
                      </v-col>
                      <v-col cols="12" sm="10">
                        <div class="d-flex flex-column">
                          {{ msg.text }}
                          <div v-if="msg.hasAttachment">
                            <v-img
                              v-if="msg.attachmentType === 'png' || msg.attachmentType === 'jpg'"
                              :lazy-src="msg.attachmentPath"
                              max-height="150"
                              max-width="250"
                              :src="msg.attachmentPath"></v-img>

                            <DownloadFile
                              v-else-if="msg.attachmentType === '.bin'"
                              :task-data="{}"
                              :show-text="true"
                              :show-icon="true"
                              icon-size="1.2rem"
                              icon-color="white"
                              :btnShowTextShort="false"
                              btnTextLong="Modified"
                              btnTextShort="Mod"
                              :btnOutlined="false"
                              btnColor="primary"
                              btnColorText="white--text"
                              downloadType="mod" />
                          </div>

                          <sub class="mt-3" style="font-size: 0.5rem">{{ msg.message_created }}</sub>
                        </div>
                      </v-col>
                    </v-row>

                    <div v-if=""></div>
                    <v-icon v-if="showMessageAction" small> expand_more</v-icon>
                  </v-chip>
                </v-hover>
              </template>
              <v-list v-if="showMessageAction">
                <v-list-item>
                  <v-list-item-title @click="replayToMessage">Replay</v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title>special action 2</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </v-card-text>
      <v-card-text class="flex-shrink-1">
        <v-text-field
          v-model="newMessageInput"
          label="type_a_message"
          type="text"
          no-details
          outlined
          append-outer-icon="send"
          @keyup.enter="newMessage"
          @click:append-outer="newMessage"
          hide-details />

        <div>
          <span class="text-dark">
            <strong>Attachment</strong>
          </span>
          <!-- TODO: FILEINPUT wird geleert wenn ich einen Schritt zurück gehe -->
          <DragAndDrop ref="refDragDrop" @fileToUpload="setFileUpload"></DragAndDrop>
        </div>
      </v-card-text>
    </v-card>
  </v-responsive>
</template>
<script>
import DownloadFile from "@/components/tasks/tableItems/fileDownload/DownloadFile.vue";
import DragAndDrop from "@/components/ui/DragAndDrop.vue";
import { customFunctions as $customFunctions } from "@/customFunctions";
import { mapGetters } from "vuex";

export default {
  name: "TaskMessages",
  components: { DownloadFile, DragAndDrop },
  props: {
    selectedTask: Object,
    tasks: Array,
  },
  data() {
    return {
      messages: [],
      newMessageInput: "",
    };
  },
  methods: {
    replayToMessage(msg) {
      console.log("replay", msg);

      this.newMessageInput = "Antwort zu '" + msg.text + "'\n";
    },

    getMessageColor(message) {
      if (message.me) {
        return "primary";
      }

      if (message.type === "history") {
        return "pink";
      }

      return "";
    },

    getMethodIcons(method) {
      return $customFunctions.getMethodIcons(method);
    },

    newMessage() {
      if (!this.newMessageInput) {
        return;
      }

      this.postChat(this.newMessageInput).then(async () => {
        this.newMessageInput = null;
        await this.loadEvents(this.selectedTask.task_id);
      });
      this.newMessageInput = "";
    },

    async getChats(task_id) {
      const response = await this.axios.get("/api/tasks/" + task_id + "/chats");
      return response.status === 200 && response.data.code === 120 ? response.data.data : [];
    },

    async getHistory(task_id) {
      const response = await this.axios.get("/api/tasks/" + task_id + "/history");
      return response.status === 200 && response.data.code === 130 ? response.data.data : [];
    },

    async postChat(message) {
      const response = await this.axios.post("/api/tasks/" + this.selectedTask.task_id + "/chat", {
        job_id: this.selectedTask.task_job_id,
        task_id: this.selectedTask.task_id,
        chat_text: message,
        user_type: "tuner",
        file_path: "",
        file_type: "",
        file_name: "",
      });
      return response.status === 200 && response.data.code === 120 ? response.data.data : [];
    },

    async loadEvents(task_id) {
      console.log("loadEvents ", task_id);

      this.messages = []; // Clear the messages array

      // Chats vom backend laden
      const chats = await this.getChats(task_id);
      chats.forEach((x) => {
        const ownMessage = x.chat_user_id === this.userAccessData.data.user_id;
        const avatarName = $customFunctions.avatarName(this.userAccessData);
        const showIcon = avatarName === "" || !ownMessage;
        this.messages.push({
          id: "m" + x.chat_id,
          text: x.chat_text,
          me: ownMessage,
          message_created: this.formatDate(x.chat_create_date),
          message_created_date: x.chat_create_date,
          avatar: ownMessage ? avatarName : "mdi-face-agent",
          isIcon: showIcon,
          color: ownMessage ? "primary" : "pink",
          myMessage: ownMessage,
          type: "message",
          hasAttachment: x.chat_file_path !== "",
          attachmentType: x.chat_file_type,
          attachmentName: x.chat_file_name,
          attachmentPath: x.chat_file_path,
        });
      });
      // this.getChats(task_id).then((chats) => {
      //
      // });

      // History vom backend laden
      console.log("Load history...");
      const histories = await this.getHistory(task_id);
      histories.forEach((x) => {
        const ownMessage = x.chat_user_id === this.userAccessData.data.user_id;

        this.messages.push({
          id: "h" + x.history_id,
          color: ownMessage ? "primary" : "pink",
          text: x.text,
          message_created: this.formatDate(x.created_date_utc),
          message_created_date: x.created_date_utc,
          avatar: "mdi-clipboard-text-clock",
          isIcon: true,
          myMessage: ownMessage,
          type: "history",
        });
      });

      // when messages and history is loaded, i want to reorder the list by message_created_date, so that the youngest
      // date is at the bottom of the list
      this.messages.sort((a, b) => {
        return new Date(a.message_created_date) - new Date(b.message_created_date);
      });
    },

    formatDate(time) {
      return time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
        return ` ${contents
          .split(" ")
          .map((v) => v.charAt(0))
          .join("")}`;
      });
    },

    setFileUpload(file) {},

    showImageControl(message) {
      return message.attachmentType === "png" || message.attachmentType === "jpg" || message.attachmentType === "jpeg";
    },

    showButtonControl(message) {
      return message.attachmentType === "bin" || message.attachmentType === ".bin";
    },

    showMessageAction(message) {
      return !this.showButtonControl(message);
    },

    getMethods(task) {
      return $customFunctions.getMethodsFromTaskModifications(task);
    },

    scrollToBottom() {
      const container = this.$refs.listContainer;
      if (container) {
        const messages = container.getElementsByClassName("message");
        // console.log(messages);
        if (messages.length > 0) {
          const lastMessage = messages[messages.length - 1];
          // console.log(lastMessage);
          lastMessage.scrollIntoView({ behavior: "smooth", block: "end" });
        }
      }
      // console.log("scrollToBottom", container);
      //
      // if (container) {
      //   container.scrollTop = container.scrollHeight;
      // }
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),
  },
  watch: {
    selectedTask(newVal) {
      console.log("selectedTask", newVal);
      if (!this.selectedTask) {
        return;
      }
      this.loadEvents(this.selectedTask.task_id);
      // $refs.taskMessages.loadEvents(this.selectedTask.task_id);
    },
    messages(newVal) {
      if (!newVal) {
        return;
      }
      //ToDo: scroll zum letzten element funktioniert noch nicht
      this.$nextTick(() => {
        this.scrollToBottom();
        // const listContainer = this.$refs.listContainer;
        // listContainer.scrollTop = listContainer.scrollHeight;
        // const listContainer = this.$refs.listContainer;
        // listContainer.scrollIntoView({ behavior: "smooth", block: "end" });
      });
    },
  },
};
</script>
