<template>
  <div v-if="userAccessData != null">
    <v-col cols="12">
      <div class="DevModeSwitch" v-if="userAccessData.data.main_right_id == 1">
        <v-switch inset v-if="keyValid" switch v-model="DevMode" small @change="switchDevMode" class="text-dark"></v-switch>
        <v-text-field dense hide-details :hidden="!passwordInput" type="password" small v-model="inputKey" style="border: none !important; color:transparent"></v-text-field>
        <span @click="showPasswordInput()" style="color:transparent">X</span>
      </div>
    </v-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DevModeButton",

  data: () => ({
    DevMode: false,
    key: 1432,
    inputKey: "",
    passwordInput: false,
  }),

  mounted() {},

  watch: {},

  methods: {
    switchDevMode() {
      this.$store.dispatch("showDevMode", this.DevMode);
    },

    showPasswordInput() {
      this.passwordInput =!this.passwordInput;
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),

    showDevMode() {
      return this.$store.getters.showDevMode;
    },

    keyValid() {
      return this.key == this.inputKey;
    },
  },
};
</script>

<style scoped>

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
  border-color: 1px solid transparent !important;
}

</style>
