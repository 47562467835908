<template>
  <div class="newJob">
    <v-stepper v-model="currentStep" elevation="0" vertical>
      <v-row>
        <v-col cols="12" md="8">
          <!--        TODO: Fehlerchecking für methoden ob der fehler enthalten ist oder nicht-->
          <!--        TODO: Für Stage ein schieberegeler wo geholfen wird entscheidungen zu treffen. -->
          <!--        TODO: Signalwörter wenn MyMethods not listed angehackt wird könnte der Kunde im Textfeld alles reinschrieben und dann analysiert. . -->
          <!--        currentStep: {{ currentStep }}<br />-->
          <!--        this.newJob.selectedVehicle: {{ newJob.selectedVehicle }}<br />-->

          <!-- Step 1: Select Vehicle -->
          <v-stepper-step step="1" :complete="currentStep > 1">
            <StepperVehicleHeader :current-step="currentStep" :newJob="newJob" @emitStep="setStep" />
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-col>
              <StepperVehicleSelection
                :current-step="currentStep"
                :loadingAnimation="isLoading"
                @emitNextStep="setNextStep"
                @emitSelectedVehicle="setSelectedVehicle"
                @emitIsLoading="setIsLoading" />

              <div class="mt-2">
                <v-btn
                  :class="$vuetify.theme.dark ? 'white--text' : 'white--text'"
                  color="primary"
                  dense
                  rounded
                  :disabled="!newJob.selectedVehicle"
                  v-if="currentStep < 3"
                  @click="nextStep()"
                  >Weiter zu den Methoden
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-stepper-content>

          <!-- Step 2: v-data-table -->
          <v-stepper-step step="2" :complete="currentStep > 2">
            <StepperMethodHeader
              :current-step="currentStep"
              :discount-price-quantity="discountPriceQuantity"
              :fileprice="newJob.finalFileprice"
              :switch-my-method-not-listed="switchMyMethodNotListed"
              :newJob="newJob"
              @emitStep="setStep" />
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-col>
              <StepperMethodSelection
                :availableMethods="availableMethods"
                :newJob="newJob"
                @emit-file-price="setFileprice"
                @switch-my-method-not-listed="setSwitchMyMethodNotListed" />

              <div class="mt-5">
                <v-btn color="light" :class="$vuetify.theme.dark ? 'primary--text' : 'primary--text'" dense rounded v-if="currentStep > 1" @click="previousStep">
                  <v-icon>mdi-arrow-left</v-icon>
                  Zurück
                </v-btn>

                <v-btn
                  :class="$vuetify.theme.dark ? 'white--text' : 'white--text'"
                  color="primary"
                  class="ml-2"
                  dense
                  rounded
                  :disabled="!newJob.selectedMethods.length"
                  @click="nextStep"
                  >Original Datei auswählen
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-stepper-content>

          <!-- Step 3: File Upload -->
          <v-stepper-step step="3" :complete="currentStep > 3">Original Datei auswählen</v-stepper-step>
          <v-stepper-content step="3">
            <v-col>
              <StepperFileSelection
                v-if="currentStep > 2"
                :current-step="currentStep"
                :newJob="newJob"
                :valid-chassi-number="validChassiNumber"
                @emitMCMFileToUpload="setFileMCM"
                @emitACMFileToUpload="setFileACM"
                @emitCustomerJobNotes="setCustomerJobNotes"
                @emitChecksumShouldBeDone="setChecksumShouldBeDone"
                @emitAcceptTerms="setAcceptTerms" />

              <div class="mt-2">
                <v-btn color="light" :class="$vuetify.theme.dark ? 'primary--text' : 'primary--text'" dense rounded v-if="currentStep > 1" @click="previousStep">
                  <v-icon>mdi-arrow-left</v-icon>
                  Zurück
                </v-btn>

                <v-btn
                  :class="$vuetify.theme.dark ? 'white--text' : 'white--text'"
                  color="primary"
                  class="ml-2"
                  dense
                  rounded
                  :disabled="!newJob.selectedMethods"
                  @click="createNewJob"
                  >Sende Datei
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-stepper-content>
        </v-col>
      </v-row>
    </v-stepper>
    <TaskProgressArea :dialogProgress="progressStatusData"></TaskProgressArea>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import TaskProgressArea from "@/components/theme/dialogs/DialogTaskProgressArea.vue";
import StepperVehicleSelection from "@/components/jobs/createJob/StepperVehicleSelection.vue";
import { customFunctions as $customFunctions } from "@/customFunctions";
import StepperMethodSelection from "@/components/jobs/createJob/StepperMethodSelection.vue";
import StepperMethodHeader from "@/components/jobs/createJob/StepperMethodHeader.vue";
import StepperVehicleHeader from "@/components/jobs/createJob/StepperVehicleHeader.vue";
import StepperFileSelection from "@/components/jobs/createJob/StepperFileSelection.vue";

export default {
  name: "NewJob",
  components: {
    StepperFileSelection,
    StepperVehicleHeader,
    StepperMethodHeader,
    StepperMethodSelection,
    StepperVehicleSelection,
    TaskProgressArea,
  },
  data() {
    return {
      progressStatusData: {
        value: 0,
        status: "prepare",
        content: ["New order will be placed..."],
        isLoading: false,
      },
      currentStep: 1,
      isLoading: false,

      newJob: {
        vehicle_vin: "",
        customerJobNotes: "",
        selectedVehicle: null,
        selectedMethods: [],
        selectedMethodsACMFile: [], // Optional wenn ACM File hochgeladen werden soll und das Fahrzeugvehicle_acm_fileID > 0 ist
        acceptTerms: false,
        checkCheckSum: false,
        fileEndingType: "master",
        fileIsMaster: false,
        packerEndingExtension: "",
        finalFileprice: 0,
        fileMCM: null,
        fileACM: null,
      },
      availableMethods: [],
      switchMyMethodNotListed: false,

      discountPriceQuantity: 0,
      discountPriceQuantityDifference: 0,

      validUploadFileTypesForAutomatic: ["bin", "dtf", "ori", "mpc", "fpf", "slave", "dec", "BIN", "DTF", "ORI", "MPC", "FPF", "SLAVE", "DEC", "dim", "DIM"],

      validUploadFileTypes: ["bin", "mpc", "dtf", "fpf", "ori", "org", "BIN", "MPC", "DTF", "FPF", "ORI", "ORG", "zip", "rar", "pld", "slave", "dec", "dim", "DIM"],
    };
  },

  methods: {
    setSwitchMyMethodNotListed(value) {
      this.switchMyMethodNotListed = value;
    },

    setFileprice(value) {
      console.log("setFileprice", value);
      this.newJob.finalFileprice = value;
    },

    setFileMCM(value) {
      this.newJob.fileMCM = value;
    },

    setFileACM(value) {
      this.newJob.fileACM = value;
    },

    setCustomerJobNotes(value) {
      this.newJob.customerJobNotes = value;
    },

    setChecksumShouldBeDone(value) {
      this.newJob.checkCheckSum = value;
    },

    setAcceptTerms(value) {
      this.newJob.acceptTerms = value;
    },

    async loadMethodItemsFromServer() {
      this.newJob.selectedMethods = [];

      if (!this.newJob.selectedVehicle) {
        return [];
      }
      try {
        await axios.get(`api/getMethods?isIdFromVehicle=true&id=${this.newJob.selectedVehicle.vehicle_id}`).then((response) => {
          // füge zu den Methoden switch hinzu sodass wir diese im Frontend an und ausschalten können
          let methods = response.data.methodData;

          methods.map((method) => {
            method.selectedMethod = false;
            return method;
          });

          this.availableMethods = methods;
        });
      } catch (error) {
        console.log(error);
      }
    },

    setSelectedVehicle(vehicle) {
      this.newJob.selectedVehicle = vehicle;

      // reset methoden nach fahrzeugwechsel
      this.newJob.selectedMethods = [];

      if (this.newJob.selectedVehicle) {
        this.loadMethodItemsFromServer().then(() => {
          this.nextStep();
        });
      }
    },

    // setAvailableMethodsForVehicle(methods) {
    //   console.log("setAvailableMethodsForVehicle", methods);
    //   console.log("array", Array.isArray(methods));
    //
    //   this.availableMethods = methods.map((method) => {
    //     return { ...method, selectedMethod: false };
    //   });
    //
    //   // return this.availableMethods.map((method) => {
    //   //   return { ...methods, selectedMethod: false };
    //   // });
    // },
    setIsLoading(loading) {
      this.isLoading = loading;
    },

    // kommt vom emit aus dem StepperVehicleSelection
    setNextStep() {
      this.nextStep();
    },

    setStep(value) {
      this.currentStep = value;
    },

    previousStep() {
      this.currentStep--;
    },
    nextStep() {
      if (this.currentStep === 1 && !this.newJob.selectedVehicle) {
        this.showValidationMessage(this.$t("VehicleNotSelected"), this.$t("VehicleNotSelectedText"), this.$t("ok"));
        return;
      }

      if (this.currentStep === 2 && this.newJob.selectedMethods.length === 0) {
        this.showValidationMessage(this.$t("methodNotSelected"), this.$t("methodNotSelectedText"), this.$t("ok"));
        return;
      }
      if (this.currentStep === 2 && this.userCreditBalance < this.newJob.finalFileprice) {
        this.showBuyMoreCreditsMessage();
        return;
      }

      this.currentStep++;
    },
    submitForm() {
      // Handle form submission
    },

    showValidationMessage(title, text, okText) {
      this.$swal({
        toast: false,
        position: "center-center",
        icon: "error",
        title: title,
        text: text,
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: okText,
      });
    },

    showBuyMoreCreditsMessage() {
      this.$swal({
        toast: false,
        position: "center-center",
        icon: "error",
        title: "Not enough Credits",
        showConfirmButton: true,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Buy more Credits",
        denyButtonText: `Back`,
        cancelButtonText: "Cancel",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.$router.replace("/account/buyCredits");
        }
      });
    },

    /**
     * ACMFileHandler
     * Hier wird die Datei hochgeladen, Tasks erstellt. (Job wird vom vorigen Task übernommen)
     * Filepreis wird mit 0 initialisiert, da dieser erst nach dem Upload berechnet wird.
     */

    async ACMFileHandler(acmFileData) {
      //   1. File wird hochgeladen
      //   2. Task wird erstellt
      // Upload das Original ACM File
      await this.uploadOriginalFile(acmFileData.file)
        .then((resUploadFile) => {
          const dataUploadedFile = resUploadFile.data.resultUploadedFile;

          //TODO: this.newJob.fileIsMaster ? "cs_ols" : "nocs", FILE IS MASTER muss noch gemacht werden wenn das file geladen wird

          let taskData = {
            task_job_id: acmFileData.job_id,
            task_info: acmFileData.task_info,
            task_filePrice: acmFileData.task_filePrice,
            method_data: acmFileData.method_data,
            vehicle_data: acmFileData.vehicle_data,
            ecu_id: 0,
            task_fileNameTemp: dataUploadedFile.filenameTemp,
            task_fileName: dataUploadedFile.originalname,
            task_fileTempPath: dataUploadedFile.path,
            task_fileMimetype: dataUploadedFile.mimetype,
            task_fileSize: dataUploadedFile.size,
            selectedChecksumType: this.newJob.fileIsMaster ? "cs_ols" : "nocs",
            singleDTCInput: null,
            singleDTCInputAppendToDTCSInMethods: null,
            singleDTCInputInHex: null,
            editorMode: false,
          };

          // 2. Task wird erstellt
          this.createTask(taskData).then((resultCreateTask) => {
            return resultCreateTask;
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },

    /**
     * Analysiert die ACM-Logik zur Prüfung, ob das deletes gemacht werden dürfen oder nicht.
     * Bewertet basierend auf der Einstellung des Fahrzeugs vehicle_acm_fileID
     * 0 acmID WENN ALLES IN EIMEM FILE OHNE ACM
     * 1 acmID WENN TUNING NUR IM MCM UND DELETES NUR IM ACM
     * 2 acmID WENN TUNING UND DELETES IM MCM SIND UND IM ACM NUR DELTETES
     * wenn 1 enferne alle deletes aus den selectedMethods und füge sie in die selectedMethodsACMFile ein. prüft die Methode auf method_price_addon_id > 0 wenn ja dann ist es ein delete und muss aus der selectedMethods entfernt werden und bei selectedMethodsACMFile hinzugefügt werden.
     */
    analyzeACMLogic(methods) {
      if (this.newJob.selectedVehicle && this.newJob.selectedVehicle.vehicle_acm_fileID === 1) {
        // Entferne alle Deletes aus den selectedMethods und füge sie in selectedMethodsACMFile ein.
        this.newJob.selectedMethodsACMFile = methods.filter((method) => method.method_price_addon_id > 0);
        this.newJob.selectedMethods = methods.filter((method) => method.method_price_addon_id === 0);
      } else if (this.newJob.selectedVehicle && this.newJob.selectedVehicle.vehicle_acm_fileID === 2) {
        // 2 acmID WENN TUNING UND DELETES IM MCM SIND UND IM ACM NUR DELTETES
        // fügt alle deletes in selectedMethodsACMFile ein.
        // im MCM bleiben alle Methoden drin
        this.newJob.selectedMethodsACMFile = methods.filter((method) => method.method_price_addon_id > 0);
      }
    },

    validateInput(condition, message) {
      if (condition) {
        this.showValidationMessage(message, "", "ok");
        return true;
      }
      return false;
    },

    createNewJob() {
      if (this.validateInput(!this.newJob.selectedVehicle, "Please select a vehicle.")) return;
      if (this.validateInput(this.newJob.selectedMethods.length === 0, "Please select at least one method.")) return;
      if (this.validateInput(this.newJob.vehicle_vin.length < 4, "Please enter a valid VIN.")) return;
      if (this.validateInput(!this.newJob.fileMCM, "Please upload a MCM file.")) return;
      if (this.validateInput(!this.newJob.acceptTerms, "Please accept the terms and conditions.")) return;

      if (this.newJob.fileMCM) {
        const extension = this.$customFunctions.getFileExtension(this.newJob.fileMCM);
        this.newJob.packerEndingExtension = extension;

        this.newJob.fileEndingType = this.$customFunctions.fileMasterOrSlaveCheckText(extension);
        this.newJob.fileIsMaster = this.$customFunctions.fileMasterOrSlaveCheck(extension);
      } else if (this.newJob.fileACM != null) {
        const extension = this.$customFunctions.getFileExtension(this.newJob.fileACM);
        this.newJob.packerEndingExtension = extension;
        this.newJob.fileEndingType = this.$customFunctions.fileMasterOrSlaveCheckText(extension);
        this.newJob.fileIsMaster = this.$customFunctions.fileMasterOrSlaveCheck(extension);
      }

      // ToDo: Es soll 3 verschiedene Arten von Uploads geben
      // 1. Upload eines ECU somit .bin Files. Hier sind folgende Typen erlaubt ["bin", "mpc", "dtf", "fpf", "ori", "org", "BIN", "MPC", "DTF", "FPF", "ORI", "ORG", "zip", "rar", "pld", "slave", "dec", "dim", "DIM"]
      // 2. Upload eines Fotos. Hier sind uebliche Foto Typen erlaubt .png, .jepg, .jpg, .gif, .PNG, .JEPG, .JPG, .GIF
      // 3. Upload eines Text Files .txt, .TXT
      // if (!this.checkValidUploadFileTypes(this.newJob.packerEndingExtension)) {
      //   this.showValidationMessage(this.$t("fileTypeNotValid"), this.$t("fileTypeSelectContactSupport"), "OK");
      //   return;
      // }
      // Einstellung für My method is not listed + wenn automatic mode gewählt wurde
      // zusätzlich wenn im automatic mode bin, oder zip files gewählt wurde
      const isAutomaticReady = this.$customFunctions.isFileReadyForAutomatic(this.newJob.packerEndingExtension, this.validUploadFileTypesForAutomatic);
      const modificationMode = this.$customFunctions.automaticOrManually(isAutomaticReady);

      const jobData = {
        vehicleData: this.newJob.selectedVehicle,
        job_vehicle_VIN: this.newJob.vehicle_vin,
        job_additional: this.newJob.customerJobNotes,
        job_modificationMode: modificationMode,
      };

      // 1 Schritt Upload das erste File
      // 2 Schritt Job Erstellen
      // 3 Schritt Original Datei des ersten File verschieben
      // 4 Schritt Logik für das erste File erstellen

      // 5 Schritt wenn ein zweites File hochgeladen wurde, dann das zweite File hochladen
      // 6 Schritt wenn ein zweites File hochgeladen wurde, dann das zweite File verschieben
      // 7 Schritt wenn ein zweites File hochgeladen wurde, dann Logik für das zweite File erstellen

      // ToDo: multiple file upload für MCM UND ACM wenn das ACM ausgefüllt wurde
      console.log("====== start creating job ======");

      this.updateProgressObject(15, "File upload");

      this.uploadOriginalFile(this.newJob.fileMCM)
        .then((resUploadFile) => {
          console.log("====== File upload done ======");

          const dataUploadedFile = resUploadFile.data.resultUploadedFile;
          const task_request = {};

          this.updateProgressObject(27, "File upload done");

          // Preisberechnung / Einstellung für Mengendiscount
          let task_filePrice = this.newJob.finalFileprice;

          // bei Fahrzeugauswahl
          // JOB
          jobData.job_status = "inProgress";
          jobData.job_vehicle_id = this.newJob.selectedVehicle.vehicle_id;
          jobData.job_ecu_id = 0;
          jobData.method_data = this.newJob.selectedMethods;

          // prüft auf acm_id und fügt die deletes in selectedMethodsACMFile ein
          if (this.newJob.selectedVehicle.vehicle_acm_fileID > 0) {
            this.analyzeACMLogic(this.newJob.selectedMethods);
          }

          // TASK 3214 method_id
          // i want to get all method_ids from the selected methods
          // and put them into the task_request
          task_request.selected_methods = this.newJob.selectedMethods.map((x) => x.method_id);
          task_request.vehicle_id = this.newJob.selectedVehicle.id;

          console.log("====== creating job ======", jobData);
          this.updateProgressObject(45, "Building Order with selected methods");

          //** CREATE JOB WENN DAS FAHRZEUG AUSGEWÄHLT WURDE */
          this.createJob(jobData)
            .then((response) => {
              if (!response) {
                console.log("create job response failed", response);
                return;
              }

              console.log("====== Job created ======", response);

              this.updateProgressObject(55, "Searching for valid files");

              //** CREATE TASK */
              task_request.job_id = response.resultCreateJob.job_id;
              task_request.selectedChecksumType = this.newJob.fileIsMaster ? "cs_ols" : "nocs";
              task_request.singleDTCInput = null;
              task_request.singleDTCInputAppendToDTCSInMethods = null;
              task_request.singleDTCInputInHex = null;
              task_request.editorMode = false;
              task_request.task_info = this.newJob.customerJobNotes;
              task_request.task_filePrice = task_filePrice;
              task_request.task_fileNameTemp = dataUploadedFile.filenameTemp;
              task_request.task_fileName = dataUploadedFile.originalname;
              task_request.task_fileTempPath = dataUploadedFile.path;
              task_request.task_fileMimetype = dataUploadedFile.mimetype;
              task_request.task_fileSize = dataUploadedFile.size;

              console.log("====== Creating task ======", task_request);
              this.updateProgressObject(60, "Creating task");

              this.createTask(task_request)
                .then((createTaskResponse) => {
                  console.log("====== Task created ======", createTaskResponse);
                  const paymentData = {
                    transaction_type: "debit",
                    amount: {
                      value: task_filePrice,
                    },
                    invoiceId: createTaskResponse.task_id,
                    credits_comment: "Order: " + createTaskResponse.task_id,
                  };

                  // wenn ein ACM File hochgeladen werden soll und das Fahrzeug ein ACM File hat
                  if (this.newJob.fileACM && this.newJob.selectedVehicle.vehicle_acm_fileID > 0) {
                    console.log("====== ACM File process start ======");
                    const acmFileData = {
                      job_id: job_id,
                      file: this.newJob.fileACM,
                      task_filePrice: 0,
                      task_info: "ACM File",
                      method_data: this.newJob.selectedMethodsACMFile,
                      vehicle_data: this.newJob.selectedVehicle,
                      ecu_id: 0,
                    };
                    // lädt das ACM File hoch und erstellt einen Task
                    try {
                      this.ACMFileHandler(acmFileData);
                      try {
                        this.updateCreditsAndProgressStatusText(paymentData);
                      } catch (error) {
                        console.log("updateCreditsAndProgressStatusText error: ", error);
                      }
                    } catch (error) {
                      console.log("ACMFileHandler error: ", error);
                    }
                  } else {
                    console.log("====== No ACM File ======");
                    try {
                      this.updateCreditsAndProgressStatusText(paymentData);
                    } catch (error) {
                      console.log("updateCreditsAndProgressStatusText error: ", error);
                    }
                  }
                })
                .catch((error) => {
                  console.log("createTask error: ", error);
                });
            })
            .catch((error) => {
              console.log("job create ", error);
            });
        })
        .catch((error) => {
          console.log("error upload original file ", error);
        });
    },

    async updateCreditsAndProgressStatusText(paymentData) {
      //** UPDATE CREDITS FOR CUSTOMER */
      try {
        await this.updateCreditsForCustomer(paymentData);
        this.updateProgressObject(100, "Order has been created", "finished");
        // route to task list
        await this.$router.replace("/tasks");
      } catch (error) {
        console.log("updateCreditsForCustomer error: ", error);
      }
    },

    /**
     * @description UpdateCreditsForCustomer
     * @param {Object} paymentData
     * @returns {Promise}
     */
    async updateCreditsForCustomer(paymentData) {
      return new Promise((resolve) => {
        this.$store
          .dispatch("updateCreditsForCustomer", paymentData)
          .then((response) => {
            console.log("updateCreditsForCustomer response ", response);
            if (response.data.code !== 120) {
              console.log("updateCreditsForCustomer failed! ");
              this.$swal(response.data.message);
              resolve(false);
            } else {
              console.log("updateCreditsForCustomer success! ");
              resolve(true);
            }
          })
          .catch((error) => {
            console.log("updateCreditsForCustomer error ", error);
            resolve(false);
          });
      });
    },

    updateProgressObject(progressValue, progressText, progressStatus = "inProgress") {
      const existingProgressText = this.progressStatusData.content;
      existingProgressText.push(progressText);

      const newProgress = {
        isLoading: progressStatus == "inProgress" ? true : false,
        value: progressValue,
        content: existingProgressText,
        status: progressStatus,
      };

      this.progressStatusData = newProgress;
    },

    //** Erstelle einen Task */
    async createTask(task_request) {
      return new Promise((resolve) => {
        this.$store
          .dispatch("createTask", task_request)
          .then((response) => {
            console.log("resp TaskData ", response);
            if (response.data.code !== 120) {
              console.log("Task konnte nicht erstellt werden! ");
              this.$swal(response.data.message);
              resolve(false);
            } else {
              console.log("Task wurde erfolgreich erstellt.! ");
              resolve(response.data.resultCreateTaskLogic);
            }
          })
          .catch((error) => {
            console.log("task create error ", error);
          });
      });
    },

    //** Erstelle einen Job */
    async createJob(jobData) {
      return new Promise((resolve) => {
        this.$store.dispatch("createJob", jobData).then((response) => {
          if (response.code !== 120) {
            console.log("Job konnte nicht erstellt werden! ");
            this.$swal(response.data.message);
            resolve(false);
          } else {
            resolve(response);
          }
        });
      });
    },

    //** Laded das Original file auf den Server */
    async uploadOriginalFile(originalFile) {
      return new Promise((resolve) => {
        console.log("originalFile ", originalFile);
        console.log("name ", originalFile.name);
        console.log("size ", originalFile.size);

        const formData = new FormData();
        formData.append("file", originalFile);
        formData.append("fileName", originalFile.name);
        formData.append("fileSize", originalFile.size);

        console.log("formData ", formData);
        console.log("form data name", formData.get("fileName"));
        console.log("form data size", formData.get("fileSize"));

        this.axios
          .post("api/file/uploadOriginalFile", formData, {
            headers: {
              "Content-Type": "application/json",
              "Content-Disposition": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log("response uploadOriginalFile ", response);

            if (response.data.code !== 120) {
              console.log("Original File konnte nicht hochgeladen werden. ");
              this.$swal(response.data.message);
              resolve(false);
            } else {
              resolve(response);
            }
          });
      });
    },
  },

  computed: {
    $customFunctions() {
      return $customFunctions;
    },

    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
      userCreditBalance: "loadCreditBalance",
    }),

    taskStatusData() {
      return this.$store.getters.taskStatusData;
    },

    validChassiNumber() {
      return [(value) => !!value || this.$t("required"), (v) => v.length >= 4 || this.$t("fieldRequiredsAtLeast4Characters")];
    },
    validEcuFile() {
      return [(value) => !!value || this.$t("required")];
    },
  },
};
</script>
