<template>
  <div class="" v-if="dialogProgress">
    <v-dialog v-model="progressDialog" persistent width="1000" elevation="0">
      <v-card color="primary" dark shaped>
        <v-card-title> Creating... </v-card-title>
        <v-card-text>
          <v-progress-linear :value="dialogProgress.value" color="white" class="mb-0"></v-progress-linear>

          <!-- gibt hier den ticketLog aus -->

          <div
            :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
            v-if="isScrollTargetReady"
            ref="scrollTarget"
            style="height: 200px; overflow-y: scroll; word-wrap: break-word; white-space: pre-line"
            @scroll="onScroll">
            <span v-for="content in dialogProgress.content" :class="$vuetify.theme.dark ? 'black--text' : 'white--text'">{{ content }} <br /></span>
          </div>
        </v-card-text>

        <v-card-actions>
          <!-- <v-btn color="green accent-4" rounded @click="closeProgressDialog()"> Schließen </v-btn> -->
          <!--          <v-btn color="red accent-4" rounded @click="stopTaskProgress()"> Stop Task </v-btn>-->
          <!-- <v-spacer></v-spacer> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "taskProgressArea",
  components: {
    // Progress
  },
  props: {
    dialogProgress: Object,
  },
  data: () => ({
    progressDialog: false,
    currentTaskLog: "Order will be placed...",
    isScrollTargetReady: false,
    dialogTicketLog: false,
  }),
  watch: {
    // wenn taskStatusData.task_status geändert wird, dann wird die progressbar angezeigt
    dialogProgress(newValue, oldValue) {
      console.log("uploadProgress(newValue, oldValue) {" + newValue + " " + oldValue);

      if (!this.dialogProgress || !this.dialogProgress.status) {
        this.progressDialog = false;
        return;
      }

      if (this.dialogProgress.status === "inProgress") {
        this.progressDialog = true;

        // console.log("taskStatusData.task_status == inProgress");
        // lade den task log
        this.readTicketLog();
      } else if (this.dialogProgress.status === "finished" || this.dialogProgress.status === "failed") {
        this.progressDialog = false;
      }
    },
    // wenn sich der progress ändert, durch einfluss vom user oder der automatic wird dann der Dialog angezeigt oder nicht
    progressDialog(newValue, oldValue) {
      // loading is aktiv
      if (this.progressbarIsLoading) {
        if (newValue === false) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    // Laden Sie den Inhalt des Textfelds hier oder über einen Socket-Event
    // und setzen Sie ihn auf "currentTaskLog".
    // ...

    this.$nextTick(() => {
      this.isScrollTargetReady = true;
    });
  },

  methods: {
    closeDialogTicketLog() {
      this.dialogTicketLog = false;
    },

    handleTicketLogUpdate() {
      // Scrolle zum Ende des Textfeldes
      this.$nextTick(() => {
        this.$refs.scrollTarget.scrollTop = this.$refs.scrollTarget.scrollHeight;
      });
    },

    onScroll() {
      const el = this.$refs.scrollTarget;
      if (el.scrollTop === el.scrollHeight - el.clientHeight) {
        console.log("Bottom reached");
      }
    },
    scrollToEnd() {
      this.$nextTick(() => {
        const el = this.$refs.scrollTarget;
        el.scrollTop = el.scrollHeight;
      });
    },

    readTicketLog() {
      this.handleTicketLogUpdate();
    },

    closeProgressDialog() {
      this.progressDialog = false;
    },
  },

  computed: {
    // getter und setter

    progressbarIsLoading() {
      let loading = false;
      const status = this.dialogProgress.status;

      if (status === "inProgress") {
        loading = true;
      } else if (status === "finished" || status === "failed") {
        loading = false;
      }

      return loading;
    },

    progressValue() {
      return this.dialogProgress.value;
    },
  },
};
</script>
