<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn elevation="1" icon dense class="tooltip-action-btn" v-on="on" @click="openFullTask">
        <v-icon size="1.2rem" class="" color="primary"> mdi-link</v-icon>
      </v-btn>
    </template>
    <span>Ganzen Task Öffnen</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "ItemTaskOpenFullTask",
  props: {
    taskData: {},
  },

  methods: {
    openFullTask() {
      console.log("openFullTask", this.taskData);
      this.$router.push({
        name: "task",
        params: { id: this.taskData.id },
      });
    },
  },
};
</script>
<!--<style scoped lang="scss">-->
<!--.tooltip-action-btn {-->
<!--  margin-left: 5px;-->
<!--}-->
<!--</style>-->
