<template>
  <!--              TODO: Fahrzeugsuche funktioniert nicht mit mehreren text eingaben bzw. wörtern -->
  <v-autocomplete
    v-model="selectedVehicle"
    :items="vehicleItems"
    :loading="loadingAnimation"
    :search-input.sync="vehicleSearchTerm"
    @change="setSelectedVehicle()"
    item-text="VehicleText"
    :filter="customSearch"
    clearable
    label="Fahrzeugsuche"
    placeholder="Suche nach Model, Marke oder Kategorie"
    prepend-icon="mdi-database-search"
    return-object>
    <template v-slot:selection="data">
      <v-list-item-content>
        <v-list-item-title v-html="highlightMatches(data.item.VehicleText)"></v-list-item-title>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "StepperVehicleSelection",
  props: {
    currentStep: {},
    loadingAnimation: Boolean,
  },
  data() {
    return {
      inputTexts: [],
      vehicleSearchTerm: "",
      vehicles: [],
      selectedVehicle: null,
    };
  },

  watch: {
    // Watch for changes in the search term
    vehicleSearchTerm(val) {
      if (!val || val.length < 3) {
        return;
      }

      // Items have already been requested
      if (this.loadingAnimation) return;

      this.emitIsLoading(true);

      // replace whitespace with ;
      val = val.replace(/\s/g, ";");

      axios.get(`api/getVehicles?searchTerm=${val}`).then((response) => {
        const vh = [];

        if (!response.data?.vehicleData) {
          return;
        }

        // check if vehicleData is an array
        let vehicleData = response.data.vehicleData;
        if (!Array.isArray(vehicleData)) {
          vehicleData = [vehicleData];
        }

        vehicleData.forEach((vehicle) => {
          const vehicle_string =
            vehicle.vehicle_model +
            " " +
            vehicle.vehicle_engineCCm +
            "L " +
            vehicle.vehicle_enginePS +
            "HP " +
            vehicle.vehicle_emission +
            " " +
            vehicle.vehicle_ecuBrand +
            " " +
            vehicle.vehicle_ecuType;

          const dat = {
            id: vehicle.vehicle_id,
            vehicle_id: vehicle.vehicle_id,
            name: vehicle.vehicle_id,
            label: vehicle_string,
            vehicle_object: vehicle,
            fullName:
              vehicle.vehicle_brand +
              " " +
              vehicle.vehicle_model +
              " " +
              vehicle.vehicle_engineCCm +
              "L " +
              vehicle.vehicle_enginePS +
              "HP " +
              vehicle.vehicle_emission +
              " " +
              vehicle.vehicle_ecuBrand +
              " " +
              vehicle.vehicle_ecuType,
          };
          vh.push(dat);
        });

        this.vehicles = vh;
        this.emitIsLoading(false);
      });
    },
  },

  methods: {
    emitIsLoading(isLoading) {
      this.$emit("emitIsLoading", isLoading);
    },

    emitSelectedVehicle() {
      this.$emit("emitSelectedVehicle", this.selectedVehicle);
    },

    setSelectedVehicle() {
      console.log("vehicleSelected: ", this.selectedVehicle);
      this.emitSelectedVehicle(this.selectedVehicle);
    },

    customSearch(value, search, item) {
      if (!search) {
        return;
      }

      if (search.length < 3) {
        return "";
      }
      const haystack = Object.values(value.vehicle_object).join().toLowerCase();
      // console.log("haystack " , haystack);
      let s = search.toString().toLowerCase();
      let needles = s.replace("-", " ").split(" ");

      this.inputTexts = needles;

      return needles.filter((needle) => haystack.indexOf(needle) >= 0).length === needles.length;
    },

    highlightMatches(searched) {
      if (searched !== "") {
        let needles = this.inputTexts;
        // ignoriert hier mit (i) case sensitive (Große und Kleinschreibung )
        if (needles.length > 0) {
          let re = new RegExp(needles.join("|"), "gi"); // create a a | b | c regex
          if (searched.match(re) != null) {
            searched.match(re).forEach(function (match) {
              // loop over the matches
              if (match.length > 1) {
                searched = searched.replace(match, function replace(match) {
                  // wrap the found strings
                  return '<span class="v-list-item__mask">' + match + "</span>";
                });
              }
            });
          }
        }
        return searched;
      }
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
      userCreditBalance: "loadCreditBalance",
    }),

    vehicleItems() {
      if (!this.vehicles) {
        return [];
      }

      return this.vehicles.map((entry) => {
        const VehicleText = entry.fullName;
        return Object.assign({}, entry, { VehicleText });
      });
    },
  },
};
</script>
