<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        elevation="0"
        :color="btnColor"
        :outlined="btnOutlined"
        :class="btnColorText"
        :large="large"
        :small="small"
        :disabled="disabled"
        dark
        rounded
        dense
        class="tooltip-action-btn"
        v-on="on"
        @click="downloadFile(downloadType)">
        <template v-if="showText" class="mr-1">{{ btnShowTextShort ? btnTextShort : btnTextLong }}</template>
        <v-icon v-if="showIcon" :size="iconSize" :color="iconColor">mdi-cloud-download</v-icon>
      </v-btn>
    </template>
    <span>Download {{ btnTextLong }}</span>
  </v-tooltip>
</template>
<script>
export default {
  name: "DownloadFile",
  props: {
    taskData: {},
    downloadType: {
      type: String,
      default: "ori",
    },
    btnShowTextShort: {
      type: Boolean,
      default: false,
    },
    btnTextLong: {
      type: String,
      default: "Original",
    },
    btnTextShort: {
      type: String,
      default: "Ori",
    },
    btnOutlined: {
      type: Boolean,
      default: false,
    },
    btnColor: {
      type: String,
      default: "primary",
    },
    btnColorText: {
      type: String,
      default: "white--text",
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    iconSize: {
      type: String,
      default: "1.2rem",
    },
    iconColor: {
      type: String,
      default: "white",
    },
    large: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    downloadFile(downloadType) {
      console.log(`start download ${downloadType} file `);

      if (downloadType === "ini") {
        this.downloadIniFile();
      } else {
        const data = {
          taskData: this.taskData,
          downloadType: downloadType,
        };

        this.$store.dispatch("downloadFile", data);
      }
    },

    downloadIniFile() {
      const taskData = this.taskData;

      const inicontent =
        "[WinOLS]\n" +
        "ClientName=" +
        taskData.user_id +
        "\n" +
        "VehicleVIN=" +
        taskData.job_vehicle_VIN +
        "\n" +
        "VehicleType=" +
        taskData.vehicle_category +
        "\n" +
        "VehicleProducer=" +
        taskData.vehicle_brand +
        "\n" +
        "VehicleModel=" +
        taskData.vehicle_model +
        "\n" +
        "OutputPS=" +
        taskData.vehicle_enginePS +
        "\n";

      const blob = new Blob([inicontent], { type: "ini" });
      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;

      const exportName = this.$customFunctions.fileEndingRemover(taskData.task_fileName);
      const name = exportName[0].replace(",", "");
      fileLink.setAttribute("download", "TASK_" + taskData.task_id + "_READOUT_TF24_" + name + "." + "ini");
      document.body.appendChild(fileLink);

      fileLink.click();
    },
  },
};
</script>
<!--<style scoped lang="scss">-->
<!--.tooltip-action-btn {-->
<!--  margin-left: 5px;-->
<!--}-->
<!--</style>-->
