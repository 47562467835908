//------------------------------------------------------------------------
//	IMPORTS für die Job Routen
//------------------------------------------------------------------------
import store from "@/store";
import NewJobView from "@/views/jobs/NewJobView.vue";
import jobsView from "@/views/jobs/JobsView.vue";
import JobDetailView from "@/views/jobs/JobDetailView.vue";

export default [
  {
    path: "/jobs/new",
    name: "NewJob",
    component: NewJobView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next("/signin");
      } else {
        if (store.getters["userAccessData"].data.user_active != "active") {
          return next("/notActive");
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/jobs/",
    name: "Jobs",
    component: jobsView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next("/signin");
      } else {
        if (store.getters["userAccessData"].data.user_active != "active") {
          return next("/notActive");
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/jobs/:job_id",
    name: "JobDetails",
    component: JobDetailView,
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next("/signin");
      } else {
        if (store.getters["userAccessData"].data.user_active !== "active") {
          return next("/notActive");
        } else {
          next();
        }
      }
    },
  },
];
