// socketConfig.js
const socketConfig = {
  development: {
    apiUrl: "http://localhost:3000",
    socketUrl: "http://localhost:3000",
  },
  production: {
    // apiUrl: "https://apollo.tuningfile24.org",
    // socketUrl: "https://apollo.tuningfile24.org",
    apiUrl: "https://api-flowmatic-sandbox.tuningfile24.org",
    socketUrl: "https://api-flowmatic-sandbox.tuningfile24.org",
  },
};

export default socketConfig;
