<template>
  <div id="jobDetailsHeader">
    <v-card :class="$vuetify.theme.dark ? 'primary black--text' : 'primary--text'" elevation="0" shaped class="mx-auto">
      <v-img src="@/assets/img/bg_agriculture.png" alt="" height="200" class="white--text align-end" gradient="to bottom,  rgba(0,0,0,.5), rgba(0,59,91,0.8)">
        <v-card-title class="text-h3"
          ><strong>{{ jobDetailsData.job?.vehicle_brand }}</strong>
        </v-card-title>
        <v-card-subtitle class="text-h4"
          ><i>{{ jobDetailsData.job?.vehicle_model }}</i> - {{ jobDetailsData.job?.vehicle_category }}</v-card-subtitle
        >
        <v-card-subtitle class="">
          <div class="d-flex">
            <v-btn small rounded @click="backToTasks()" color="amber accent-3" class="mr-2"><v-icon>mdi-arrow-left</v-icon>Back to Tasks</v-btn>
            <div v-for="(item, index) in chipData" :key="index" class="mr-6">
              <v-chip color="primary">
                <v-icon color="amber accent-3" class="mr-2">{{ item.icon }}</v-icon>
                {{ item.value }}
              </v-chip>
            </div>
          </div>
        </v-card-subtitle>
      </v-img>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "JobDetailCard",
  components: {},
  props: {
    jobDetailsData: {
      type: Object,
      required: true,
    },
  },

  data: () => ({}),

  methods: {
    setSendMessage(message) {
      this.$emit("send-message", message);
    },
    getPowerDescription(job) {
      return job ? job.vehicle_engineCCm + "L " + job.vehicle_enginePS + "HP" : "-";
    },

    getEcuDescription(job) {
      return job ? job.vehicle_ecuBrand + " " + job.vehicle_ecuType : "-";
    },
    backToTasks() {
      this.$emit("close-task-option-menu");
      this.$router.replace("/tasks");
    },
  },
  computed: {
    chipData() {
      return [
        {
          icon: "mdi-train-car",
          value: this.jobDetailsData.job?.job_vehicle_VIN,
        },
        {
          icon: "mdi-engine",
          value: this.getPowerDescription(this.jobDetailsData.job),
        },
        {
          icon: "mdi-poll",
          value: this.jobDetailsData.job?.vehicle_emission,
        },
        {
          icon: "mdi-cpu-64-bit",
          value: this.getEcuDescription(this.jobDetailsData.job),
        },
      ];
    },
  },
};
</script>
