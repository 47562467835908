import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);
Vue.config.productionTip = false;
Vue.use(Loading);
Vue.component("Loading", Loading);

//-------------
// DEV MODE
import DevMode from "@/components/ui/DevMode.vue";
Vue.component("DevMode", DevMode);

//-------------
//custom functions
import { customFunctions } from "@/customFunctions.js";
Vue.prototype.$customFunctions = customFunctions;
//AREAS for user rights
import { moduleRights } from "@/rights/moduleRights.js";
Vue.prototype.$moduleRights = moduleRights;
//AREAS for user rights
import { rightsFunctions } from "@/rights/rightsFunctions.js";
Vue.prototype.$rightsFunctions = rightsFunctions;

//-------------
//sweetalert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const options = {
  confirmButtonColor: "#00c853",
  cancelButtonColor: "#f50057",
  // background: "#343a40",
  // customClass: '.text-white',
};
Vue.use(VueSweetalert2, options);

// Language
import i18n from "./i18n";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

// ToDo: wir sollten env files für dev, sandbox und prod anlegen
if (process.env.NODE_ENV === "development") {
  Vue.config.productionTip = true;
  axios.defaults.baseURL = "http://localhost:3000";
}
if (process.env.NODE_ENV === "production") {
  Vue.config.productionTip = false;
  axios.defaults.baseURL = "https://api-sandbox.tuningfile24.org/";
}
// MOMENTS
const moment = require("moment-timezone");
require("moment/locale/de");
Vue.use(require("vue-moment"), {
  moment,
});
// check for any mutations is in the store saved
store.subscribe((mutation) => {
  switch (mutation.type) {
    case "SET_TOKEN":
      //check for if mutation has a payload data. payload token!
      if (mutation.payload) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + mutation.payload;
        localStorage.setItem("tokenTF24Portal", mutation.payload);
      } else {
        axios.defaults.headers.common["Authorization"] = null;
        localStorage.removeItem("tokenTF24Portal");
      }
      break;
  }
});
// check localstorage for saved token and dispatch the attempt function with the token from the local storage.
//pack this in a promise "flickering of page" removed.
// render our app after the token was load
store.dispatch("verifyToken", localStorage.getItem("tokenTF24Portal")).then(() => {
  new Vue({
    router,
    store,
    i18n,
    t: i18n.t,
    render: (h) => h(App),
    vuetify,
    created() {},
  }).$mount("#app");
});
