<template>
  <v-alert v-if="showDevMode" close-text="Close Alert" color="blue" dark outlined dismissible class="mt-1 mb-1" style="font-size: 12px">
    <div v-for="(item, i) in devData" :key="i">
      {{ item }}<br />
      <strong>{{ item[0] }}</strong> {{ item[1] }}
    </div>
  </v-alert>
</template>

<script>
export default {
  props: { title: String, devData: null },
  methods: {},
  computed: {
    showDevMode() {
      return this.$store.getters.showDevMode;
    },
  },
};
</script>
