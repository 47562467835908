<template>
  <div id="taskMessageList">
    <v-container class="fill-height pa-0">
      <v-row class="no-gutters">
        <!--        <v-col cols="12" sm="3" class="flex-grow-1 flex-shrink-0" style="border-right: 1px solid #0000001f">-->
        <!--          <v-responsive class="overflow-y-auto fill-height" height="500">-->
        <!--            <v-list subheader>-->
        <!--              <v-list-item-group v-model="selectedTask">-->
        <!--                <template v-for="(item, index) in tasks">-->
        <!--                  <v-list-item :key="`parent${index}`" :value="item">-->
        <!--                    <v-list-item-avatar color="primary lighten-1 white&#45;&#45;text">-->
        <!--                      <v-icon> mdi-file-document</v-icon>-->
        <!--                    </v-list-item-avatar>-->
        <!--                    <v-list-item-content>-->
        <!--                      <v-list-item-title> Task #{{ item.task_id }}</v-list-item-title>-->
        <!--                      <v-list-item-subtitle> {{ item.task_status }}</v-list-item-subtitle>-->
        <!--                    </v-list-item-content>-->
        <!--                  </v-list-item>-->
        <!--                  &lt;!&ndash;                  <v-divider :key="`chatDivider${index}`" class="my-0" />&ndash;&gt;-->
        <!--                </template>-->
        <!--              </v-list-item-group>-->
        <!--            </v-list>-->
        <!--          </v-responsive>-->
        <!--        </v-col>-->
        <v-col cols="auto" class="flex-grow-1 flex-shrink-0"> </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TaskMessages from "@/components/jobs/jobDetails/TaskMessages.vue";

export default {
  name: "TaskMessageList",
  components: { TaskMessages },
  props: {
    tasks: {},
  },
  data() {
    return {
      selectedTask: {},
    };
  },
  mounted() {
    this.selectedTask = this.tasks[0];
  },
  methods: {},
  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),
  },
  watch: {},
};
</script>
