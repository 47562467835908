<template>
  <div id="jobDetailsTaskTable">
    <v-card elevation="0" shaped>
      <v-data-table
        v-model="selectedTasksFromTableItem"
        :headers="tasksTableField"
        :items="jobDetailsData.tasks"
        :single-select="true"
        item-key="task_id"
        checkbox-color="primary"
        :hide-default-footer="true">
        <template v-slot:item.task_id="{ item }">
          <v-badge
            :content="$customFunctions.getShortText(item.task_type)"
            :value="item.task_type"
            :color="$customFunctions.getTaskTypeColor(item.task_type)"
            overlap
            bordered
            offset-x="30"
            offset-y="3">
            <v-chip small class="" color="primary" outlined> {{ "#" + item.task_id }}</v-chip>
          </v-badge>
        </template>

        <template v-slot:item.task_info_2="{ item }">
          <strong>{{ item.task_modifications }}</strong
          ><br />
          <span>{{ item.task_filePrice }}Cr.</span>
          <v-icon size="1rem" :color="$customFunctions.paymentStatusColor(item.task_paymentStatus)">
            {{ $customFunctions.paymentStatusIcon(item.task_paymentStatus) }}
          </v-icon>
          <br />
          <span>{{ $customFunctions.dateFormatWithTime(item.task_created) }}</span>
        </template>

        <template v-slot:item.options="{ item }">
          <TaskTableOptions :item="item" :showJumpToJob="false" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import SocketioService from "@/socketServiceClient";
import TaskMainOptions from "@/components/tasks/tableItems/ItemTaskMainOptions.vue";
import ItemTaskMessages from "@/components/tasks/tableItems/ItemTaskMessages.vue";
import ItemTaskOpenFullTask from "@/components/tasks/tableItems/ItemTaskOpenFull.vue";
import ItemTaskBindWorker from "@/components/tasks/tableItems/ItemTaskBindWorker.vue";
import DialogTaskMessages from "@/components/theme/dialogs/DialogTaskMessages.vue";
import DownloadFile from "@/components/tasks/tableItems/fileDownload/DownloadFile.vue";
import router from "@/router";
import TaskTableOptions from "@/components/tasks/TaskTableOptions.vue";
import { mapGetters } from "vuex";

export default {
  name: "TasksTable",
  components: {
    TaskTableOptions,
    TaskMainOptions,
    ItemTaskMessages,
    ItemTaskOpenFullTask,
    ItemTaskBindWorker,
    DialogTaskMessages,
    DownloadFile,
  },
  props: {
    jobDetailsData: {
      type: Object,
      default: {},
    },
  },

  data: () => ({
    selectedTaskData: {},
    selectedTasksFromTableItem: [],
    expandedRows: [],
    tasksTableField: [
      { value: "task_id", text: "#" },
      { value: "task_info_2", text: "Info" },
      { value: "options", text: "Optionen" },
    ],
  }),

  mounted() {},

  watch: {
    // überwache die ausgewählten tasks, solbald sich diese ändern, dann müssen wir den alten Raum verlassen und den neuen Raum betreten
    selectedTasksFromTableItem: function (newVal, oldVal) {
      // wenn wir einen neuen task auswählen, dann müssen wir den alten Raum verlassen
      if (oldVal != null) {
        if (oldVal.length > 0) {
          SocketioService.leaveRoom("task_room_" + oldVal[0].task_id);
        }
      }
      // wenn wir einen neuen task auswählen, dann müssen wir den neuen Raum betreten
      if (newVal != null) {
        if (newVal.length > 0) {
          // join task room damit wir die updates bekommen
          // SocketioService
          SocketioService.joinTaskRoom("task_room_" + newVal[0].task_id);
        }
      }
    },
  },

  methods: {
    customFilter(value, search, item) {
      search = search.toLowerCase();
      const searchableProperties = [
        "task_id",
        "task_fileName",
        "task_filePrice",
        "task_fileType",
        "task_created",
        "task_modifications",
        "task_info",
        "vehicle_brand",
        "vehicle_model",
        "vehicle_category",
        "vehicle_engineCCm",
        "vehicle_enginePS",
        "vehicle_emission",
        "vehicle_ecuBrand",
        "vehicle_ecuType",
      ];

      return searchableProperties.some((prop) => String(item[prop]).toLowerCase().includes(search));
    },

    goToJobDetails(item) {
      // schließe zuerst das menu
      this.closeMenu(item);
      router.push("/jobs/" + item.task_job_id);
    },

    closeMenu(item) {
      if (item) {
        item.isMenuOpen = false;
      } else {
        this.jobDetailsData.tasks.map((task) => {
          task.isMenuOpen = false;
        });
      }
    },
    createTicket() {},

    changeModification() {},
  },

  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),
  },
};
</script>
