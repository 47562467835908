<template>
  <div id="jobDetails">
    <JobDetailsMain :job-details-data="jobDetailsData"></JobDetailsMain>
    <!--    <Footerbar></Footerbar>-->
  </div>
</template>

<script>
// import Footerbar from "@/components/ui/Footerbar.vue";
import JobDetailsMain from "@/components/jobs/jobDetails/JobDetailsMain.vue";

export default {
  name: "jobDetails",
  components: {
    JobDetailsMain,
    // Footerbar,
  },

  data: () => ({}),

  mounted() {
    console.log(this.$route.params.job_id); // { productId: "123" }
    const jobDetailsData = {
      job: {},
      tasks: [],
      vehicle: {},
    };
    this.$store
      .dispatch("loadJobDataWithJobID", this.$route.params.job_id)
      .then((response) => {
        jobDetailsData.job = response;

        // load tasks
        this.$store.dispatch("loadTasksForJob", jobDetailsData.job.job_id).then((response) => {
          jobDetailsData.tasks = response;

          console.log(jobDetailsData);

          this.$store.dispatch("jobDetailsData", jobDetailsData);
          this.$store.dispatch("showPageLoadingIndicator", false);
        });
      })
      .catch((error) => {
        this.$store.dispatch("showPageLoadingIndicator", false);
        console.log(error);
      });
  },

  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },

  computed: {
    jobDetailsData() {
      return this.$store.getters.jobDetailsData;
    },
  },
};
</script>
