<template>
  <div>
    <TaskTicketDialog :task="item" ref="refTaskTicketDialog" :open-dialog="openTicketDialog" @closeDialog="setCloseDialog" />

    <template v-if="item.task_status === 'finished'">
      <DownloadFile
        :task-data="item"
        :show-text="false"
        :show-icon="true"
        :btnShowTextShort="true"
        :btnOutlined="false"
        icon-size="1.2rem"
        icon-color="white"
        btnTextLong="Modified"
        btnTextShort="Mod 123"
        btnColor="primary"
        btnColorText="primary--text"
        downloadType="mod" />
    </template>

    <template v-else>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon class="mr-2" color="primary" :class="{ 'spin-animation': $customFunctions.task_status_icon(item).animated }" v-on="on">
            {{ $customFunctions.task_status_icon(item).icon }}
          </v-icon>
        </template>
        <span>{{ $customFunctions.task_status_text_customer(item) }}</span>
      </v-tooltip>
    </template>

    <!--            {{ item }}-->

    <v-menu offset-y v-model="item.isMenuOpen" v-if="item.task_status !== 'inProgress'">
      <template v-slot:activator="{ on: menu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn color="primary" elevation="1" outlined icon dense small class="ml-2 tooltip-action-btn" v-on="{ ...menu, ...tooltip }">
              <v-icon small v-if="item.isMenuOpen">mdi-close</v-icon>
              <v-icon small v-else>mdi-pencil</v-icon>
              <TaskTicketBadgeCounter :item="item" />
            </v-btn>
          </template>
          <span>Task Optionen</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-subheader>Task Optionen</v-subheader>

        <v-list-item>
          <v-list-item-avatar color="primary">
            <v-icon color="white">mdi-cloud-download</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <DownloadFile
              :task-data="item"
              :show-text="true"
              :show-icon="false"
              :large="false"
              :small="true"
              :btnShowTextShort="false"
              :btnOutlined="true"
              icon-size="1.2rem"
              icon-color="primary"
              btnTextLong="Original File"
              btnTextShort="Ori"
              btnColor="primary"
              btnColorText="white--text"
              downloadType="ori" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showJumpToJob">
          <v-list-item-avatar color="primary">
            <v-icon color="white">mdi-open-in-new</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-btn rounded dense outlined small color="primary" @click="goToJobDetails(item)"> Fahrzeug öffnen</v-btn>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Hilfe und Support</v-list-item-title>
        </v-list-item>
        <v-list-item dense>
          <v-list-item-avatar :color="item.task_type !== 'new' ? 'green accent-4' : 'red accent-4'">
            <v-icon color="white">mdi-face-agent</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-btn rounded outlined small color="green accent-4" class="white--text" @click="openTicket()">
              Support Ticket <TaskTicketBadgeCounter :item="item" :offsetX="-5" :offsetY="-1" />
            </v-btn>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense>
          <v-list-item-avatar color="blue accent-3">
            <v-icon color="white">mdi-rotate-left</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-btn rounded dense outlined small color="blue accent-3" class="white--text" @click="changeModification(item)"> Modifizierung Ändern </v-btn>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import DownloadFile from "@/components/tasks/tableItems/fileDownload/DownloadFile.vue";
import router from "@/router";
import TaskTicketDialog from "@/components/tasks/TaskTicketDialog.vue";
import { customFunctions as $customFunctions } from "@/customFunctions";
import { mapGetters } from "vuex";
import TaskTicketBadgeCounter from "@/components/tasks/TaskTicketBadgeCounter.vue";

export default {
  name: "TaskTableOptions",
  components: { TaskTicketBadgeCounter, TaskTicketDialog, DownloadFile },
  props: {
    item: {},
    showJumpToJob: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    openTicketDialog: false,
  }),

  methods: {
    goToJobDetails(item) {
      item.isMenuOpen = false;
      router.push("/jobs/" + item.task_job_id);
    },

    createTicket() {},

    openTicket(item) {
      this.openTicketDialog = true;
      this.$refs.refTaskTicketDialog.loadChats();
    },
    setCloseDialog() {
      this.openTicketDialog = false;
    },

    changeModification() {},
  },

  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),

    $customFunctions() {
      return $customFunctions;
    },
  },
};
</script>
