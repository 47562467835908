import Vue from "vue";
import Vuex from "vuex";
import userAuth from "./userAuth";
import job from "./job";
import task from "./task";
import vehicle from "./vehicle";
import logic from "./logic";
import chat from "./chat";
import prices from "./prices";
import pricesCustomer from "./pricesCustomer";
import tools from "./tools";
import customers from "./customers";
import file from "./file";
import credits from "./credits";
import countries from "./countries";
import globalOptions from "./globalOptions";

Vue.use(Vuex);

export default new Vuex.Store({
  // speichert alles objects, strings ect.
  state: {
    showPageLoadingIndicator: false,
    tableLoadingIndicator: false,
    tableLoadingIndicatorMethod: false,
    alertStatus: {},
    showDevMode: false,
    skeletonisLoading: false,
  },
  mutations: {
    SHOW_TABLE_LOADING_INDICATOR(state, payload) {
      state.tableLoadingIndicator = payload;
    },

    SHOW_PAGE_LOADING_INDICATOR(state, payload) {
      state.showPageLoadingIndicator = payload;
    },
    SHOW_TABLE_LOADING_INDICATOR_METHOD(state, payload) {
      state.tableLoadingIndicatorMethod = payload;
    },
    SHOW_SKELETON_LOADING_INDICATOR(state, payload) {
      state.skeletonisLoading = payload;
    },
    ALERT_STATUS(state, payload) {
      state.alertStatus = payload;
    },
    SHOW_DEV_MODE(state, payload) {
      state.showDevMode = payload;
    },
  },
  //read our informations from the state and show it anywhere in the app
  getters: {
    showTableLoadingIndicator(state) {
      return state.tableLoadingIndicator;
    },
    showTableLoadingIndicatorMethod(state) {
      return state.tableLoadingIndicatorMethod;
    },
    showPageLoadingIndicator(state) {
      return state.showPageLoadingIndicator;
    },
    skeletonisLoading(state) {
      return state.skeletonisLoading;
    },
    alertStatus(state) {
      return state.alertStatus;
    },
    showDevMode(state) {
      return state.showDevMode;
    },
  },
  //actions commit mutations
  //dispatch = call other functions ({dispatch})
  //commit = commit mutations ({commit})
  //send user inputs form to API
  actions: {
    showPageLoadingIndicator({ commit }, payload) {
      commit("SHOW_PAGE_LOADING_INDICATOR", payload);
    },
    skeletonisLoading({ commit }, payload) {
      commit("SHOW_SKELETON_LOADING_INDICATOR", payload);
    },

    showAlert({ commit }, payload) {
      commit("ALERT_STATUS", payload);
    },
    showDevMode({ commit }, payload) {
      commit("SHOW_DEV_MODE", payload);
    },
  },
  modules: {
    userAuth,
    job,
    task,
    vehicle,
    logic,
    chat,
    prices,
    pricesCustomer,
    tools,
    customers,
    file,
    credits,
    countries,
    globalOptions,
  },
});
