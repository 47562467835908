<template>
  <div>
    <span class="text-dark">
      <strong>Engine File MCM (ECU)</strong>
    </span>
    <div>
      <DragAndDrop ref="refDragDrop" @fileToUpload="setMCMFileToUpload" />
    </div>

    <div class="mt-1" v-if="newJob.selectedVehicle.vehicle_ecuChecksum !== 'nocs' && newJob.fileIsMaster">
      <v-checkbox
        id="checkbox-checksumCalculation"
        hide-details
        v-model="checksumShouldBeDone"
        name="checkbox-checksumCalculation"
        class="mt-3"
        :label="$t('checksumCalculation')"></v-checkbox>
    </div>
    <!-- ACM FILE UPLOAD WENN VERFÜGBAR -->

    <div class="mt-2" v-if="newJob.selectedVehicle.vehicle_acm_fileID > 0 && showACMFileInput">
      <span class="text-dark">
        <strong>Module File ACM (Adblue, DEF) </strong>
      </span>
      <!--        TODO: FILEINPUT wird geleert wenn ich einen schritt zurück gehe-->
      <DragAndDrop ref="refDragDrop" @fileToUpload="setACMFileToUpload"></DragAndDrop>
    </div>

    <div class="mt-2">
      <span class="text-dark">
        <strong>Support File (optional Information .txt, .zip, .rar)</strong>
      </span>
      <!--        TODO: FILEINPUT wird geleert wenn ich einen schritt zurück gehe-->
      <DragAndDrop ref="refDragDrop" @fileToUpload="setSupportFileToUpload"></DragAndDrop>
    </div>

    <div class="mt-3">
      <v-text-field
        hide-details
        ref="chassisNumberRef"
        dense
        :label="$t('chassisNumber')"
        v-model="newJob.vehicle_vin"
        :rules="validChassiNumber"
        type="text"
        required
        placeholder=""
        outlined></v-text-field>
    </div>

    <div class="mt-3">
      <!--        TODO: TEXT Area wird geleert wenn ich einen schritt zurück gehe-->
      <v-textarea
        v-model="customerJobNotes"
        placeholder="..."
        :label="'Additional Information: ' + formValidInfoTextSizeCounter + '/ ' + maxCharactersForTextArea + ' characters'"
        outlined
        rows="3"
        :maxlength="maxCharactersForTextArea"></v-textarea>

      <v-checkbox id="checkbox-1" v-model="acceptTerms" name="checkbox-1" class="mt-2" :label="$t('termsConditions')"></v-checkbox>
    </div>
  </div>
</template>
<script>
import DragAndDrop from "@/components/ui/DragAndDrop.vue";

export default {
  name: "StepperFileSelection",
  components: { DragAndDrop },
  props: {
    currentStep: {},
    newJob: {},
    validChassiNumber: {},
  },

  data() {
    return {
      selectedFile: null,
      showMCMFileInput: false,
      showACMFileInput: false,
      originalMCMFile: null,
      originalACMFile: null,
      originalSupportFile: null,
      validSupportUploadFileTypes: ["txt", "zip", "rar"],
      customerJobNotes: "",
      checksumShouldBeDone: false,
      acceptTerms: false,
      maxCharactersForTextArea: 200,
    };
  },

  watch: {
    currentStep: function (val) {
      if (val === 1) {
        this.emitStep(1);
      }
    },

    customerJobNotes: function (val) {
      this.emitCustomerJobNotes(val);
    },

    checksumShouldBeDone: function (val) {
      this.emitChecksumShouldBeDone(val);
    },

    acceptTerms: function (val) {
      this.emitAcceptTerms(val);
    },
  },

  methods: {
    emitStep(value) {
      this.$emit("emitStep", value);
    },

    emitCustomerJobNotes(value) {
      this.$emit("emitCustomerJobNotes", value);
    },

    emitChecksumShouldBeDone(value) {
      this.$emit("emitChecksumShouldBeDone", value);
    },

    emitAcceptTerms(value) {
      this.$emit("emitAcceptTerms", value);
    },

    getFileName(file) {
      if (file) {
        const fileName = file.name;
        return fileName ? fileName : "";
      }
      return "";
    },

    /**
     * Set MCM file to upload
     * @param file
     */
    setMCMFileToUpload(file) {
      const fileName = file.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf(".") + 1);

      this.fileEndingChecker(fileName, fileExtension);
      this.originalMCMFile = file;

      this.$emit("emitMCMFileToUpload", file);
    },

    /**
     * Set ACM file to upload
     * @param file
     */

    setACMFileToUpload(file) {
      const fileName = file.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf(".") + 1);

      this.fileEndingChecker(fileName, fileExtension);
      this.originalACMFile = file;

      this.$emit("emitACMFileToUpload", file);
    },

    /**
     * Set support file to upload
     * @param file
     */
    setSupportFileToUpload(file) {
      const fileName = file.name;
      const fileExtension = fileName.slice(fileName.lastIndexOf(".") + 1);

      if (!this.validSupportUploadFileTypes.includes(fileExtension)) {
        this.$swal({
          title: "Warning",
          text: "Bitte wählen Sie eine gültige Dateiendung aus: " + this.validSupportUploadFileTypes.join(", "),
          icon: "warning",
          confirmButtonText: "OK",
        });

        //reset file input
        this.$refs.refDragDrop.cancelFile();

        return;
      }

      this.fileEndingChecker(fileName, fileExtension);
      this.originalSupportFile = file;
    },

    fileEndingChecker(fileName, fileNameEnding) {
      if (!fileName || !fileNameEnding) {
        return;
      }

      this.newJob.packerEndingExtension = fileNameEnding;
      this.newJob.fileEndingType = this.$customFunctions.fileMasterOrSlaveCheckText(fileNameEnding);
      this.newJob.fileIsMaster = this.$customFunctions.fileMasterOrSlaveCheck(fileNameEnding);
    },
  },
  computed: {
    formValidInfoTextSizeCounter() {
      return this.customerJobNotes.length;
    },
  },
};
</script>
