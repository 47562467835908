//**
//SOCKET SERVICE FÜR DEN CLIENT
// **/
import { io } from "socket.io-client";

import socketConfig from "./socketConfig.js";
import Swal from "sweetalert2";

class SocketioService {
  socket;
  constructor() {
    const env = process.env.NODE_ENV || "development";
    console.log("env: ", env);
    const url = socketConfig[env].socketUrl;
    this.socket = io(url);
  }

  setupSocketConnection() {
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 10;
    const reconnectDelay = 3000;
    let reconnectTimeout;

    // const showReconnectNotification = (seconds) => {
    //   Swal.fire({
    //     toast: true,
    //     position: "top-start",
    //     icon: "warning",
    //     title: `Verbindung wird in ${seconds} Sekunden erneut zum Server hergestellt`,
    //     showConfirmButton: false,
    //   });
    // };

    // const clearReconnectNotification = () => {
    //   Swal.close();
    // };

    // const showReconnectedNotification = () => {
    //   Swal.fire({
    //     toast: true,
    //     position: "top-start",
    //     icon: "success",
    //     title: "Verbindung zum Server erfolgreich wiederhergestellt",
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // };

    this.socket.on("connect", () => {
      console.log("Connected to socket server");
      clearTimeout(reconnectTimeout);
      // clearReconnectNotification();
      reconnectAttempts = 0;
    });

    this.socket.on("disconnect", (reason) => {
      console.log(`Disconnected from socket server. Reason: ${reason}`);
      if (reconnectAttempts < maxReconnectAttempts) {
        // const delayInSeconds = Math.ceil(reconnectDelay / 1000);
        // showReconnectNotification(delayInSeconds);
        reconnectTimeout = setTimeout(() => {
          console.log("Trying to reconnect...");
          // clearReconnectNotification();
          this.socket.connect();
        }, reconnectDelay);
        reconnectAttempts++;
      } else {
        console.log("Maximum number of reconnect attempts reached");
        // clearReconnectNotification();
        Swal.fire({
          icon: "error",
          title: "Verbindung zum Server konnte nicht wiederhergestellt werden",
          text: "Bitte überprüfen Sie Ihre Internetverbindung",
        });
        // Do something else (e.g. show error message)
      }
    });

    // this.socket.on("reconnect", (attemptNumber) => {
    //   console.log(`Reconnected to socket server after ${attemptNumber} attempts`);
    //   showReconnectedNotification();
    //   clearReconnectNotification();
    // });

    // this.socket.on("reconnect_attempt", (attemptNumber) => {
    //   console.log(`Trying to reconnect... (${attemptNumber})`);
    // });

    // this.socket.on("reconnect_error", (error) => {
    //   console.log(`Reconnect error: ${error}`);
    //   clearReconnectNotification();
    //   Swal.fire({
    //     icon: "error",
    //     title: "Verbindung zum Server konnte nicht wiederhergestellt werden",
    //     text: "Bitte überprüfen Sie Ihre Internetverbindung",
    //   });
    //   // Do something else (e.g. show error message)
    // });

    // this.socket.on("reconnect_failed", () => {
    //   console.log("Reconnect failed");
    //   clearReconnectNotification();
    //   Swal.fire({
    //     icon: "error",
    //     title: "Verbindung zum Server konnte nicht wiederhergestellt werden",
    //     text: "Bitte überprüfen Sie Ihre Internetverbindung",
    //   });
    //   // Do something else (e.g. show error message)
    // });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  connect() {
    this.socket.connect();
  }

  // hier connected sich der User auf den App Raum jeder User hat seinen eigene Raum
  joinAppRoom(userID) {
    this.socket.emit("joinAppRoom", userID);
  }

  // hier connected sich der User auf den Task Raum jeder Task hat seinen eigene Raum
  joinTaskRoom(taskRoom) {
    this.socket.emit("joinTaskRoom", taskRoom);
  }

  // hier disconnected sich der User von dem Task Raum
  leaveRoom(taskRoom) {
    this.socket.emit("leaveRoom", taskRoom);
  }

  listenOnTaskTicket(data) {
    console.log("listen: ", data);
    // this.socket.emit("listenOnTaskTicket", data);
  }
  ticketLogUpdated(data) {
    console.log("listen: ", data);
   this.socket.emit("ticketLogUpdated", data);
  }
}

export default new SocketioService();
