<template>
  <v-menu v-model="taskData.menuBindWorker" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on: menu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            color="primary"
            v-bind="attrs"
            v-on="{ ...tooltip, ...menu }"
            :disabled="taskData.task_status === 'inProgress'"
            elevation="1"
            icon
            dense
            class="tooltip-action-btn">
            <v-badge :content="'#2'" :value="'#2'" color="primary" overlap offset-x="5" offset-y="10">
              <v-icon size="1.2rem" color="primary">mdi-account-hard-hat</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>Worker zuweisen</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-autocomplete v-model="selectedWorker" :items="users" label="Benutzer auswählen" outlined dense @focus="menu = true"></v-autocomplete>
            <v-textarea v-model="comment" label="Kommentar" outlined rows="3" auto-grow dense></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="bindWorker(taskData)">Zuweisen</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: "ItemTaskBindWorker",
  props: {
    taskData: {},
  },
  data() {
    return {
      menu: false,
      selectedWorker: null,
      comment: "",
      users: ["Benutzer 1", "Benutzer 2", "Benutzer 3"], // Beispielbenutzerdaten
    };
  },
  methods: {
    openDialogBindWorkerToTask() {
      this.taskData.menuBindWorker = true; // Öffnet das Menü, wenn der Button geklickt wird
    },
    bindWorker(taskData) {
      // Füge hier die Logik hinzu, um den ausgewählten Benutzer und Kommentar dem Task zuzuweisen
      console.log("Ausgewählter Benutzer:", this.selectedWorker);

      const commentText = "Dir wurde Task #" + taskData.task_id + " zugewiesen. Kommentar: " + this.comment;
      console.log("Kommentar:", commentText);

      this.taskData.menuBindWorker = false; // Schließe das Menü nach dem Zuweisen
    },
  },
};
</script>

<!--<style scoped lang="scss">-->
<!--.tooltip-action-btn {-->
<!--  margin-left: 5px;-->
<!--}-->
<!--</style>-->
