<template>
  <div>
    <v-row class="align-center">
      <v-col cols="auto">
        <h2>Methodenauswahl</h2>
      </v-col>
      <v-col cols="auto">
        <v-chip color="primary" outlined :class="'white--text'" class="" v-if="currentStep > 2"> {{ fileprice }} {{ $t("credits") }} </v-chip>
      </v-col>
    </v-row>

    <h3>{{ switchMyMethodNotListed ? "Custom Method" : selectedMethodsString }}</h3>
    <div v-if="currentStep > 2" class="mt-2">
      <div class=""><i>Schritt #2: Abgeschlossen</i></div>
      <v-btn color="amber accent-3" class="mt-2" rounded dense @click="emitStep(3)">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </div>
    <div v-else>
      Wähle eine Methode welche auf das Steuergerät angewendet werden soll
      <div class="mt-2"><i>Schritt #2: Offen</i></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepperMethodHeader",
  props: {
    currentStep: Number,
    discountPriceQuantity: Number,
    fileprice: Number,
    switchMyMethodNotListed: Boolean,
    newJob: Object,
  },

  methods: {
    emitStep(value) {
      this.$emit("emitStep", value);
    },
  },

  computed: {
    selectedMethodsString() {
      if (!this.newJob.selectedMethods) {
        return "zuerst Fahrzeug auswählen";
      }

      if (this.newJob.selectedMethods.length === 0 && this.newJob.selectedVehicle) {
        return "Wähle eine Methode aus";
      } else {
        // Verwenden Sie .map(), um ein Array mit den Werten von `method_name_customer` zu erstellen
        const methodNames = this.newJob.selectedMethods.map((method) => method.method_name_customer);
        return methodNames.join(", ");
      }
    },
  },
};
</script>
