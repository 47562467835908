//Custom Functions for globally useage
import task from "@/store/task";

export const customFunctions = {
  /*
  Berechnet den Reduzierten Finalen Filepreis
  */
  calcReducedFinalFilePrice(currentFilePrice, methodsCount, userMaxFlatPrice) {
    let reducedFinalPrice = 0;
    // wenn ein User keinen FlatPreis Max hat
    if (userMaxFlatPrice === 0) {
      reducedFinalPrice = this.discountPriceQuantity(currentFilePrice, methodsCount, userMaxFlatPrice);
    }
    return Math.round(reducedFinalPrice);
  },

  /*
  Berechnet den Discount in Summe für den Filepreis
  */

  discountPriceQuantity(currentFilePrice, methodsCount, userMaxFlatPrice) {
    let discount = 0;
    let curFilePrice = currentFilePrice;
    // wenn ein User keinen FlatPreis Max hat
    if (userMaxFlatPrice === 0) {
      if (currentFilePrice > 0) {
        if (methodsCount === 3) {
          curFilePrice = curFilePrice - (curFilePrice / 100) * 10;
          discount = Math.round(curFilePrice);
        } else if (methodsCount > 3) {
          curFilePrice = curFilePrice - (curFilePrice / 100) * 20;
          discount = Math.round(curFilePrice);
        }
      }
    }
    return discount;
  },
  /*
  Berechnet den Discount in Differenz für den Filepreis
  */
  discountPriceQuantityDifference(currentFilePrice, methodsCount, userMaxFlatPrice) {
    let sum = 0;
    // wenn ein User keinen FlatPreis Max hat
    if (userMaxFlatPrice === 0) {
      const curFilePrice = currentFilePrice;
      if (methodsCount === 3) {
        sum = (curFilePrice / 100) * 10;
      } else if (methodsCount > 3) {
        sum = (curFilePrice / 100) * 20;
      }
    }
    return Math.round(sum);
  },

  /*
  Prüft die Fileendung und gibt 
  */
  getFileExtension(fileNameWithEnding) {
    if (fileNameWithEnding != null) {
      // console.log(fileNameWithEnding);
      // Next, the pop() method will remove the last element of an array and return that as a value. You can assign the result of calling the pop() method into a variable as follows:

      console.log(fileNameWithEnding);
      const splitArray = fileNameWithEnding.name.split(".");
      const extension = splitArray.pop();

      return extension;
    }
  },

  /*
  Master oder Slave File
  Return True oder False
  */
  fileMasterOrSlaveCheck(extension) {
    let fileisMaster = true;
    if (extension === "fpf" || extension === "FPF" || extension === "slave") {
      fileisMaster = false;
    }
    return fileisMaster;
  },

  /*
  Master oder Slave File 
  Return den Text Master oder Slave
  */
  fileMasterOrSlaveCheckText(extension) {
    let text = "master";
    if (extension === "fpf" || extension === "FPF" || extension === "slave") {
      text = "slave";
    }
    return text;
  },

  convertTextToBooleanForMasterSlave(text) {
    if (text === "isMaster") {
      return true;
    } else {
      return false;
    }
  },

  /*
 Entfernt hier das .xx am Dateinamen
  */
  fileEndingRemover(fileNameWithEnding) {
    if (fileNameWithEnding != "") {
      var nameArray = fileNameWithEnding.split(".");
      // const extension = nameArray.pop(); // enferne die Endung
      return nameArray;
    }
  },

  /*
  Entfernt sonderzeichen im Filenamen und erstetzt diese durch unterstriche 
  */
  removeSpezialCharactersInString(str) {
    if (str != "") {
      // console.log(str);
      // selbst ausgewählte:
      // var removedChar = string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');

      // alles ausser nummern und Buchstaben
      var string = str.replace(/[^a-zA-Z0-9]/g, "_");
      return string;
    }
  },

  paymentStatus(status) {
    if (status === "open") {
      return "text-danger";
    } else if (status === "payed") {
      return "text-success";
    } else {
      return "-";
    }
  },
  paymentStatus_icon(status) {
    if (status === "open") {
      return "bag-x";
    } else if (status === "payed") {
      return "bag-check";
    } else {
      return "patch-question";
    }
  },
  paymentStatusBadgeColor(status) {
    if (status === "open") {
      return "bg-danger";
    } else if (status === "payed") {
      return "bg-success";
    } else {
      return "bg-warning";
    }
  },

  paymentStatusText(status) {
    if (status === "open") {
      return "Unbezahlt!";
    } else if (status === "payed") {
      return "Bezahlt!";
    } else {
      return "--";
    }
  },

  job_checksum_icon(jobDetailsData) {
    if (jobDetailsData.vehicle_ecuChecksum !== "nocs") {
      return "badgecolorActive";
    } else {
      return "badgecolorInactive";
    }
  },
  job_checksum_text(jobDetailsData) {
    if (jobDetailsData.vehicle_ecuChecksum !== "nocs") {
      return "Automatic";
    } else {
      return "Support";
    }
  },

  task_fileStatus_icon(task_data) {
    if (task_data.task_status === "inProgress") {
      return { icon: "gear-wide-connected", animated: "spin", size: "1.2" };
    } else if (task_data.task_status === "finished") {
      return { icon: "dot", animated: "", size: "3" };
    } else {
      return { icon: "dot", animated: "", size: "3" };
    }
  },
  task_fileStatus_text(task_data) {
    if (task_data.task_status === "finished") {
      return "finished";
    } else if (task_data.task_status === "inWait") {
      return "waiting";
    } else if (task_data.task_status === "inProgress") {
      return "in progress";
    } else {
      return "inWork";
    }
  },
  // job_status_text(jobDetailsData) {
  //   if (jobDetailsData.job_status === "inProgress") {
  //     return "work";
  //   } else if (jobDetailsData.job_status === "finished") {
  //     return "ready";
  //   } else if (jobDetailsData.job_status === "failed") {
  //     return "inWork";
  //   } else {
  //     return "support";
  //   }
  // },

  task_fileStatus_badgeColor(task_data) {
    if (task_data.task_status === "finished") {
      return "badgecolorActive";
    } else if (task_data.task_status === "inWait") {
      return "badgecolorWait";
    } else if (task_data.task_status === "inProgress") {
      return "badgecolorProgress";
    } else {
      return "badgecolorInactive";
    }
  },
  task_fileStatus_bg(task_data) {
    if (task_data.task_status === "inProgress") {
      return "bg-primary";
    } else if (task_data.task_status === "finished") {
      return "bg-success";
    } else if (task_data.task_status === "failed") {
      return "bg-danger";
    } else {
      return "bg-warning";
    }
  },
  task_done_by(task_data) {
    if (task_data.task_done_by === "Automatic") {
      return " 🤖 ";
    } else if (task_data.task_done_by === "support") {
      return " 👨‍💻 ";
    } else {
      return "";
    }
  },
  task_type(task_data) {
    if (task_data.task_type === "new") {
      return "new task";
    } else if (task_data.task_type === "updateUpgrade") {
      return "update / upgrade";
    } else if (task_data.task_type === "inReview") {
      return "in review";
    } else if (task_data.task_type === "reviewDone") {
      return "review done";
    } else {
      return "-";
    }
  },
  task_type_color(task_data) {
    if (task_data.task_type === "new") {
      return "badgecolorActive";
    } else if (task_data.task_type === "updateUpgrade") {
      return "badgecolorProgress";
    } else if (task_data.task_type === "inReview") {
      return "badgecolorWait";
    } else if (task_data.task_type === "reviewDone") {
      return "badgecolorActive";
    } else {
      return "-";
    }
  },

  checksumBadgeText(task_data) {
    if (task_data.task_automaticChecksum === "nocs") {
      return "Keine Checksumme hinterlegt!";
    } else if (task_data.task_automaticChecksum === "cs_ols") {
      return "WIN OLS";
    } else if (task_data.task_automaticChecksum === "cs_ds") {
      return "Dimsport";
    } else {
      return "Kein Gültiger Parameter";
    }
  },
  checksumShouldBeDoneText(task_data) {
    if (task_data.task_checksumShouldBeDone === "1") {
      return "Bitte rechnen";
    } else if (task_data.task_checksumShouldBeDone === "0") {
      return "Keine Checksumme zu benötigt";
    } else {
      return "Kein Gültiger Parameter für Checksumme zu rechnen";
    }
  },

  job_status_badge(jobDetailsData) {
    if (jobDetailsData.job_status === "finished" || jobDetailsData.job_status === "reviewDone") {
      return "badgecolorActive";
    } else if (jobDetailsData.job_status === "inWait" || jobDetailsData.job_status === "inReview") {
      return "badgecolorWait";
    } else if (jobDetailsData.job_status === "inProgress") {
      return "badgecolorProgress";
    } else {
      return "badgecolorInactive";
    }
  },

  job_status_icon(jobData) {
    if (jobData.job_status === "inProgress") {
      return { icon: "mdi-cog-outline", animated: true };
    } else if (jobData.job_status === "finished") {
      return { icon: "mdi-cancel", animated: false };
    } else {
      return { icon: "mdi-timer-outline", animated: false };
    }
  },

  job_status_text(jobDetailsData) {
    if (jobDetailsData.job_status === "finished") {
      return "finished";
    } else if (jobDetailsData.job_status === "inWait") {
      return "waiting";
    } else if (jobDetailsData.job_status === "inReview") {
      return "in review";
    } else if (jobDetailsData.job_status === "reviewDone") {
      return "review done";
    } else if (jobDetailsData.job_status === "inProgress") {
      return "in progress";
    } else {
      return "in Work";
    }
  },

  //formatiert Datum zu EUROPA
  dateFormat(date, separated) {
    var formatedDate = "";
    if (date != null) {
      const formatDate = new Date(date);
      const yyyy = formatDate.getFullYear();
      let mm = ("0" + (formatDate.getMonth() + 1)).slice(-2);
      let dd = ("0" + formatDate.getDate()).slice(-2);

      formatedDate = dd + "" + separated + "" + mm + "" + separated + "" + yyyy;
    }
    return formatedDate;
  },

  //formatiert Datum zu USA
  dateFormatUS(date) {
    var formatedDate = "";
    if (date != null) {
      const formatDate = new Date(date);
      const yyyy = formatDate.getFullYear();
      let mm = ("0" + (formatDate.getMonth() + 1)).slice(-2);
      let dd = ("0" + formatDate.getDate()).slice(-2);
      formatedDate = yyyy + "-" + mm + "-" + dd;
    }
    return formatedDate;
  },

  //formatiert Datum zu USA
  dateFormatUSWithTime(date) {
    let formatedDate = "";
    if (date != null) {
      const formatDate = new Date(date);
      const yyyy = formatDate.getFullYear();
      let mm = ("0" + (formatDate.getMonth() + 1)).slice(-2);
      let dd = ("0" + formatDate.getDate()).slice(-2);
      formatedDate = yyyy + "-" + mm + "-" + dd + " " + formatDate.getHours() + ":" + formatDate.getMinutes();
    }
    return formatedDate;
  },
  //formatiert Datum zu EU
  dateFormatEU(date) {
    var formatedDate = "";
    if (date != null) {
      const formatDate = new Date(date);
      const yyyy = formatDate.getFullYear();
      let mm = ("0" + (formatDate.getMonth() + 1)).slice(-2);
      let dd = ("0" + formatDate.getDate()).slice(-2);
      formatedDate = dd + "-" + mm + "-" + yyyy;
    }
    return formatedDate;
  },

  //formatiert das Datum zu EUROPA
  dateFormatWithTime(date) {
    var formatedDate = "";
    if (date != null) {
      const formatDate = new Date(date);
      const yyyy = formatDate.getFullYear();
      let mm = ("0" + (formatDate.getMonth() + 1)).slice(-2);
      let dd = ("0" + formatDate.getDate()).slice(-2);
      let hh = formatDate.getHours();
      let min = formatDate.getMinutes();
      let ss = formatDate.getSeconds();

      formatedDate = dd + "." + mm + "." + yyyy + " " + hh + ":" + min + ":" + ss;
    }
    return formatedDate;
  },
  //formatiert das Datum zu EUROPA
  dateFormatHourMinSec(date) {
    var formatedDate = "";
    if (date != null) {
      const formatDate = new Date(date);
      let hh = formatDate.getHours();
      let min = formatDate.getMinutes();
      let ss = formatDate.getSeconds();

      formatedDate = hh + ":" + min + ":" + ss;
    }
    return formatedDate;
  },

  //formatiert Zeit + Datum zu USA
  dateFormatWithTimeUS(date) {
    var formatedDate = "";
    if (date != null) {
      const formatDate = new Date(date);
      const yyyy = formatDate.getFullYear();
      let mm = ("0" + (formatDate.getMonth() + 1)).slice(-2);
      let dd = ("0" + formatDate.getDate()).slice(-2);
      let hh = formatDate.getHours();
      let min = formatDate.getMinutes();
      let ss = formatDate.getSeconds();

      formatedDate = yyyy + "." + mm + "." + dd + " " + hh + ":" + min + ":" + ss;
    }
    return formatedDate;
  },

  // gibt Farb css Klassen zurück
  userActiveBadgeColor(data) {
    if (data.user_active === "active") {
      return "green accent-4";
    } else if (data.user_active === "inactive") {
      return "red accent-4";
    } else {
      return "light";
    }
  },
  // gibt konvertiereten Text zurück
  userActiveText(data) {
    if (data.user_active === "active") {
      return "Freigeschalten";
    } else if (data.user_active === "inactive") {
      return "Gesperrt";
    } else {
      return "---";
    }
  },
  // gibt konvertiereten Text zurück
  userRightsText(data) {
    if (data.main_right_id === 1) {
      return "Super Admin";
    } else if (data.main_right_id === 2) {
      return "Admin";
    } else if (data.main_right_id === 3) {
      return "Techniker";
    } else if (data.main_right_id === 4) {
      return "Verwaltung";
    } else if (data.main_right_id === 5) {
      return "Kunde";
    } else {
      return "Keine Rechte";
    }
  },
  // gibt Farb css Klassen zurück
  userRightsColor(data) {
    if (data.main_right_id === 1) {
      return "green accent-4";
    } else if (data.main_right_id === 2) {
      return "amber accent-3";
    } else if (data.main_right_id === 3) {
      return "primary";
    } else {
      return "light";
    }
  },
  /*
Gibt Farb css Klassen zurück
  */
  messageAndRoomsBadgeColor(data) {
    if (data === "open") {
      return "danger";
    } else if (data === "read") {
      return "success";
    } else {
      return "light";
    }
  },
  /*
Gibt den Richtigen Namen für die Nachricht zurück. 
  */
  messageAndRoomsText(data) {
    if (data === "open") {
      return "ungelesen";
    } else if (data === "read") {
      return "gelesen";
    } else {
      return "--";
    }
  },
  /*
Gibt den Richtigen Namen für den Benutzer zurück. 
  */
  messageAndRoomsWriterText(data) {
    if (data === "admin") {
      return "Admin";
    } else if (data === "technic") {
      return "Techniker";
    } else if (data === "customer") {
      return "Kunde";
    } else {
      return "--";
    }
  },
  /*
Gibt die Farbe für den Status der Methode zurück 
  */
  getActiveMethodTypeColor(activeType) {
    var modiColor = "";

    if (activeType > 0) {
      modiColor = "green accent-4";
    } else {
      modiColor = "primary";
    }
    return modiColor;
  },
  /*
Gibt die Farbe für den Status Copymatic oder Flowmatic zurück 
  */
  getMethodModficationTypeColor(modficationType) {
    var modiColor = "";
    if (modficationType === "copymatic") {
      modiColor = "warning";
    } else {
      modiColor = "primary";
    }
    return modiColor;
  },
  /*
Gibt den Text für Flowmatic oder Copymatic zurück
  */
  getMethodModficationTypeText(modficationType) {
    var modi = "";

    if (modficationType === "copymatic") {
      modi = "Copymatic";
    } else {
      modi = "Flowmatic";
    }
    return modi;
  },

  /*
Gibt die Farbe für die Freischaltung der Methode zurück 
  */
  getActiveMethodChipTypeColor(status) {
    var modiColor = "";

    if (status === "active") {
      modiColor = "green accent-4";
    } else {
      modiColor = "red accent-4";
    }
    return modiColor;
  },
  /*
Gibt die Farbe für die Freischaltung der Methode zurück 
  */
  getActiveMethodModficationTypeText(status) {
    var modiColor = "";

    if (status === "active") {
      modiColor = "Aktiv";
    } else {
      modiColor = "inaktiv";
    }
    return modiColor;
  },

  task_status_icon(taskData) {
    if (taskData.task_status === "inProgress") {
      return { icon: "mdi-cog-outline", animated: true };
    } else if (taskData.task_status === "finished") {
      return { icon: "mdi-check", animated: false };
    } else {
      return { icon: "mdi-timer-outline", animated: false };
    }
  },

  task_status_text_customer(taskData) {
    if (taskData.task_status === "inProgress") {
      return "inWork";
    } else if (taskData.task_status === "finished") {
      return "Done";
    } else {
      return "waiting";
    }
  },

  /*
Gibt die Farbe den ProgressKreis zurück
  */
  taskStatusColorProgressCircle(taskStatus) {
    if (taskStatus === "finished") {
      return "#00e676";
    } else if (taskStatus === "failed") {
      return "#f50057";
    } else if (taskStatus === "prepare") {
      return "#FFD158";
    } else if (taskStatus === "searching") {
      return "#ffd740";
    } else {
      return "#6c757d";
    }
  },
  /*
Gibt das icon für den MainTicket zurück
  */
  statusIconMainTicket(taskStatus) {
    if (taskStatus === "finished") {
      return "mdi-check-circle";
    } else if (taskStatus === "failed") {
      return "mdi-cancel";
    } else if (taskStatus === "inWait") {
      return "mdi-timer";
    } else if (taskStatus === "inProgress") {
      return "mdi-timer";
    } else {
      return "mdi-help-circle";
    }
  },
  /*
Gibt das icon für den ticketstatus zurück
  */
  statusIconSubTicket(taskStatus) {
    if (taskStatus === "true") {
      return "mdi-check-circle";
    } else if (taskStatus === "false") {
      return "mdi-cancel";
    } else if (taskStatus === "notUsed") {
      return "mdi-debug-step-over";
    } else {
      return "mdi-help-circle";
    }
  },
  /*
Gibt die Farbe für den MainticketColor zurück
  */
  statusMainTicketColor(taskStatus) {
    if (taskStatus === "finished") {
      return "green accent-4";
    } else if (taskStatus === "failed") {
      return "red accent-4";
    } else if (taskStatus === "inWait") {
      return "amber accent-3";
    } else if (taskStatus === "inProgress") {
      return "primary";
    } else {
      return "primary";
    }
  },
  /*
Gibt die Farbe für den statusTicketColor zurück
  */
  statusSubticketTicketColor(taskStatus) {
    if (taskStatus === "true") {
      return "green accent-4";
    } else if (taskStatus === "false") {
      return "red accent-4";
    } else if (taskStatus === "inWait") {
      return "amber accent-3";
    } else {
      return "primary";
    }
  },
  /*
Wandelt den Namen in die richtige Bit tiefen Namen um
  */
  getRightModeName(mode_name) {
    var name = "";
    if (mode_name != "" && mode_name != null) {
      if (mode_name === "eByte") {
        name = "8Bit (" + mode_name + ")";
      } else if (mode_name === "eHiLo" || mode_name === "eLoHi") {
        name = "16Bit (" + mode_name + ")";
      } else if (mode_name === "eLoHiLoHi" || mode_name === "eHiLoHiLo") {
        name = "32Bit (" + mode_name + ")";
      }
    }
    return name;
  },

  /*
Splittet den Text mit kommas
  */
  splitText(str, splitter) {
    var hexText = "";
    var data = [];
    // ersetzt alle leerzeichen durch eine gesamte Linie
    var inlineText = str.replace(/\s/g, "");

    // var inlineText = str.replace(/^[ ]+|[ ]+$/g, "");
    if (splitter === 2) {
      // jeder 2 character
      data = inlineText.match(/.{1,2}/g);
    } else if (splitter === 4) {
      // jeder 4 character
      data = inlineText.match(/.{1,4}/g);
    }
    // jeder 2 oder 4 character wird ein Komma gesetzt
    if (data != null) {
      if (data.length > 0) {
        data.forEach((data) => {
          hexText += data + ",";
        });
      }
    }
    return hexText;
  },

  /*
Entfernt alles zeichen \n \r aus dem String und ersetzt es durch leerzeichen
  */
  handleNewLineSearchReplace(str) {
    return str.replace(/[\n\r\t]/g, " ");
  },

  /*
Entfernt alles zeichen \n \r aus dem String und ersetzt es durch kommas
  */
  handleNewLine(str) {
    // return str.replace(/(?:\r\n|\r|\n)/g, " ");
    //https://stackoverflow.com/questions/48087905/remove-the-tab-space-or-line-breaks-in-a-javascript-string
    //entferne alle semicolons
    var te = str.replace(/,/g, "");
    return te.replace(/[\n\r\t]/g, " ,");
    // return str.replace(/[ \t]+$/g, " ");
  },

  getChecksumColor(checksumMode) {
    if (checksumMode === "nocs") {
      return "red accent-4";
    } else if (checksumMode === "cs_ols") {
      return "green accent-4";
    } else if (checksumMode === "cs_ds") {
      return "primary";
    }
  },
  getChecksumText(checksumMode) {
    if (checksumMode === "nocs") {
      return "Keine Checksumme";
    } else if (checksumMode === "cs_ols") {
      return "Win OLS";
    } else if (checksumMode === "cs_ds") {
      return "Dimsport";
    }
  },

  /*
Gibt die Farbe für die AppUpdateTypen zurück 
  */
  getAppUpdateTypeColor(type) {
    var typeColor = "";
    if (type === "feature") {
      typeColor = "green accent-4";
    } else if (type === "bugfix") {
      typeColor = "red accent-4";
    } else if (type === "optimization") {
      typeColor = "amber accent-3";
    }
    return typeColor;
  },

  /*
Gibt das icon für die BlueprintType zurück
  */
  getBlueprintIcon(blueprint_type) {
    if (blueprint_type === "vehicle") {
      return "mdi-train-car";
    } else if (blueprint_type === "ecu") {
      return "mdi-cpu-64-bit";
    } else if (blueprint_type === "method") {
      return "mdi-function-variant";
    } else {
      return "mdi-help-circle";
    }
  },

  /*
Gibt das icon für die BlueprintType zurück
  */
  getBlueprintTypeColor(blueprint_type) {
    if (blueprint_type === "vehicle") {
      return "green accent-4";
    } else if (blueprint_type === "ecu") {
      return "amber accent-3";
    } else if (blueprint_type === "method") {
      return "purple accent-3";
    } else {
      return "primary";
    }
  },

  /*
konvertiert die MethodDataList in ein Array
*/
  methodDataListToArray(item) {
    var result = Object.keys(item).map((key) => {
      const data = { keyword: key, text: item[key] };
      return data;
    });
    return result;
  },

  getShortText(taskType) {
    if (taskType === "new") {
      return "new";
    } else if (taskType === "updateUpgrade") {
      return "update";
    } else if (taskType === "upgrade") {
      return "upgrade";
    } else if (taskType === "reviewDone") {
      return "revDone";
    } else if (taskType === "inReview") {
      return "inRev";
    } else if (taskType === "inReview") {
      return "---";
    }
    if (taskType === "inProgress") {
      return "inProg";
    }

    return taskType;
  },
  /*
    gibt die Farbe für die TaskType zurück
     */
  getTaskTypeColor(taskType) {
    if (taskType === "new") {
      return "green accent-4";
    } else if (taskType === "updateUpgrade") {
      return "amber accent-3";
    } else if (taskType === "reviewDone") {
      return "blue accent-3";
    } else if (taskType === "inReview") {
      return "orange accent-3";
    }
    return "green accent-4";
  },

  paymentStatusIcon(status) {
    if (status === "open") {
      return "mdi-cancel";
    } else if (status === "payed") {
      return "mdi-check";
    } else {
      return "mdi-help";
    }
  },
  paymentStatusColor(status) {
    if (status === "open") {
      return "red accent-4";
    } else if (status === "payed") {
      return "green accent-4";
    } else {
      return "amber accent-3";
    }
  },

  /**
   * Prüft ob es sich um eine Master oder Slave Datei handelt
   * @param fileName
   * @returns {string}
   */
  masterSlaveFileCheckText(fileName) {
    if (fileName) {
      const splitArray = String(fileName).split(".");
      const ending = splitArray.pop();
      if (ending !== "fpf" || ending !== "FPF" || ending !== "slave" || ending !== "SLAVE") {
        return "Master";
      } else {
        return "Slave";
      }
    } else {
      return "---";
    }
  },
  /**
   * Gibt die Icons für die Methoden zurück
   * Holt sich die files aus der json Datei
   * @returns {JSON}
   */
  getIconMethodList() {
    return require("@/assets/icons/method_icons.json");
  },

  automaticOrManually(automaticType) {
    let type = "automatic";
    if (!automaticType) {
      type = "manually";
    }
    return type;
  },

  isFileReadyForAutomatic(extension, validUploadFileTypesForAutomatic) {
    return !!(extension && validUploadFileTypesForAutomatic.includes(extension));
  },
  checkValidUploadFileTypes(fileType) {
    const types = this.validUploadFileTypes;
    let isIncl = false;
    isIncl = types.includes(fileType);
    return isIncl;
  },

  getMethodsFromTaskModifications(modifications) {
    if (!modifications) {
      return [];
    }

    // remove trailing comma from string
    const trimmedMethods = modifications.replace(/,\s*$/, "");

    // split string into array
    const methods = trimmedMethods.split(",").map((item) => item.toLowerCase());
    return methods;
  },

  getMethodIcons(method) {
    if (!method) {
      return;
    }

    const iconMappings = {
      stage_1: {
        active: "icon_stage_1_on.svg",
        inactive: "icon_stage_1.svg",
      },
      stage_2: {
        active: "icon_stage_2_on.svg",
        inactive: "icon_stage_2.svg",
      },
      stage_3: {
        active: "icon_stage_3_on.svg",
        inactive: "icon_stage_3.svg",
      },
      stage_4: {
        active: "icon_stage_4_on.svg",
        inactive: "icon_stage_4.svg",
      },
      stage_5: {
        active: "icon_stage_5_on.svg",
        inactive: "icon_stage_5.svg",
      },
      egr: {
        active: "icon_egr_on.svg",
        inactive: "icon_egr.svg",
      },
      dpf: {
        active: "icon_dpf_on.svg",
        inactive: "icon_dpf.svg",
      },
      def: {
        active: "icon_def_on.svg",
        inactive: "icon_def.svg",
      },
      dtc: {
        active: "icon_dtc_on.svg",
        inactive: "icon_dtc.svg",
      },
      cat: {
        active: "icon_cat_on.svg",
        inactive: "icon_cat.svg",
      },
      vmax: {
        active: "icon_vmax_on.svg",
        inactive: "icon_vmax.svg",
      },
      notlisted: {
        active: "icon_notlisted_on.svg",
        inactive: "icon_notlisted.svg",
      },
    };

    const iconName = iconMappings[method]?.active;
    return require(`@/assets/icons/${iconName}`);
  },

  avatarName(userAccessData) {
    if (!userAccessData) {
      return "";
    }
    const firstNameFirstChar = userAccessData.data.user_firstName.charAt(0);
    const secondNameFirstChar = userAccessData.data.user_secondName.charAt(0);

    return firstNameFirstChar + secondNameFirstChar;
  },
};
