<template>
  <div id="jobsView">
    <v-container fluid>
      <v-row>
        <v-col cols="12" lg="9" xl="10" class="">
          <v-card elevation="0" shaped>
            <IntersectToolbar @intersectingData="($event) => $emit('intersectingData', $event)" :title="`Fahrzeuge ${jobs.length}`" />
            <v-card-text>
              <v-row>
                <v-col lg="4">
                  <v-select v-model="selectedDateRange" :items="dateRanges" @change="handleDatesRange()" style="cursor: pointer" label="Zeitraum" prepend-icon="mdi-train-car">
                  </v-select>
                </v-col>

                <v-col lg="4">
                  <v-text-field v-model="searchQuery" placeholder="Suche Fahrzeug, Model, Motor, ECU" clearable color="primary" prepend-icon="mdi-magnify"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col lg="8" v-if="selectedDateRange === 'custom'">
                  <v-row>
                    <v-col>
                      <v-menu ref="menuDateStart" v-model="menuCalendarDateStart" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dateStart" label="Date Start" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateStart"
                          scrollable
                          :events="jobEvents"
                          event-color="green lighten-1"
                          color="primary"
                          :landscape="false"
                          @change="handleDatesRangeCustom()">
                          <!--                    <v-spacer></v-spacer>-->
                          <v-btn text color="primary" @click="menuCalendarDateStart = false"> Close</v-btn>
                          <!--                    <v-btn text color="primary" @click="$refs.menuDateStart.save(dateStart)"> OK </v-btn>-->
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu ref="menuDateEnd" v-model="menuCalendarDateEnd" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dateEnd" label="Date End" prepend-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateEnd"
                          scrollable
                          :events="jobEvents"
                          event-color="green lighten-1"
                          color="primary"
                          :landscape="false"
                          @change="handleDatesRangeCustom()">
                          <!--                    <v-spacer></v-spacer>-->
                          <v-btn text color="primary" @click="menuCalendarDateEnd = false"> Close</v-btn>
                          <!--                    <v-btn text color="primary" @click="$refs.menuDateEnd.save(dateStart)"> OK </v-btn>-->
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <JobsTable :jobs="jobs" :searchQuery="searchQuery"></JobsTable>
            </v-card-text>
          </v-card>
          <DevMode :title="''" :devData="[{}]"></DevMode>
        </v-col>

        <v-col cols="12" lg="3" xl="2" :class="$vuetify.breakpoint.lgAndUp ? 'sidebarRight' : ''">
          <SideBarRight></SideBarRight>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import JobsTable from "@/components/jobs/JobsTable.vue";
import SideBarRight from "@/components/ui/SidebarRight.vue";
import IntersectToolbar from "@/components/ui/IntersectToolbar.vue";

export default {
  name: "jobsView",

  components: {
    IntersectToolbar,
    SideBarRight,
    JobsTable,
  },

  data: () => ({
    dateStart: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
    dateEnd: new Date().toISOString().substr(0, 10),

    searchQuery: "",
    menuCalendarDateStart: false,
    menuCalendarDateEnd: false,
    jobEvents: null,
    selectedDateRange: "all",
    dateRanges: [
      {
        text: "Alle Fahrzeuge",
        value: "all",
      },
      {
        text: "Fahrzeuge der letzten 7 Tage",
        value: "past7Days",
      },
      {
        text: "Fahrzeuge des letztes Monats",
        value: "pastMonth",
      },
      {
        text: "Fahrzeuge des letztes Jahres",
        value: "pastYear",
      },
      {
        text: "Benutzerdefiniert",
        value: "custom",
      },
    ],
    allJobs: [], // nur als Zwischenspeicher
  }),

  mounted() {
    this.loadJobs(true);
    this.allJobs = this.jobs; // nur als Zwischenspeicher für die Kalenderpunkte
  },

  methods: {
    // lädt alle Tasks des Users
    loadJobs(reloadAllTasks) {
      const dateRange = {
        fromDate: this.dateStart,
        toDate: this.dateEnd,
      };

      const loadDataParameters = {
        reloadAllTasks: reloadAllTasks,
        selectedDateRange: this.selectedDateRange,
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
        $vuetify: this.$vuetify,
      };
      this.$store.dispatch("getAllJobsFromUser", loadDataParameters).then(() => {
        // fügt dem Kalender Punkte hinzu für die Tage, an denen jobs erstellt wurden
        this.jobEvents = this.allJobs.map((data) => {
          // const day = Math.floor(Math.random() * 30);
          const d = new Date(data.job_created);
          return d.toISOString().substr(0, 10);
        });
      });
    },

    // reagiert auf Änderungen des Datumsbereichs
    handleDatesRange() {
      if (this.selectedDateRange === "past7Days") {
        this.dateStart = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
        this.dateEnd = new Date().toISOString().substr(0, 10);
      } else if (this.selectedDateRange === "pastMonth") {
        this.dateStart = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
        this.dateEnd = new Date().toISOString().substr(0, 10);
      } else if (this.selectedDateRange === "pastYear") {
        this.dateStart = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
        this.dateEnd = new Date().toISOString().substr(0, 10);
      }

      this.loadJobs(true);
    },
    handleDatesRangeCustom() {
      this.selectedDateRange = "custom";
      this.loadJobs(true);
    },
  },

  computed: {
    jobs() {
      return this.$store.getters.loadJobs;
    },
    showDevMode() {
      return this.$store.getters.showDevMode;
    },
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),
  },
};
</script>
