//------------------------------------------------------------------------
//	IMPORT für die Account Routen
//------------------------------------------------------------------------
import store from "@/store";

const accountRoutes = [
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/Account/AccountView.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next("/signin");
      } else {
        if (store.getters["userAccessData"].data.user_active !== "active") {
          return next("/notActive");
        } else {
          next();
        }
      }
    },
    children: [
      {
        path: "", // hier greift /account als leerstring und root wird aber weitergeleitet
        redirect: "dashboard", // Weiterleitung auf die Dashboard Seite
      },
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/Account/AccountDashboard.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.getters["authenticated"]) {
            return next("/signin");
          } else {
            if (store.getters["userAccessData"].data.user_active !== "active") {
              return next("/notActive");
            } else {
              next();
            }
          }
        },
      },

      {
        path: "invoices",
        name: "Invoices",
        component: () => import("@/views/Account/AccountInvoices.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.getters["authenticated"]) {
            return next("/signin");
          } else {
            if (store.getters["userAccessData"].data.user_active !== "active") {
              return next("/notActive");
            } else {
              next();
            }
          }
        },
      },
      {
        path: "buyCredits",
        name: "BuyCredits",
        component: () => import("@/views/Account/BuyCreditsView.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.getters["authenticated"]) {
            return next("/signin");
          } else {
            if (store.getters["userAccessData"].data.user_active !== "active") {
              return next("/notActive");
            } else {
              next();
            }
          }
        },
      },

      {
        path: "accountBuyCreditsCheckout",
        name: "Checkout",
        props: true,
        component: () => import("@/views/Account/AccountBuyCreditsCheckout.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.getters["authenticated"]) {
            return next("/signin");
          } else {
            if (store.getters["userAccessData"].data.user_active !== "active") {
              return next("/notActive");
            } else {
              next();
            }
          }
        },
      },
      {
        path: "creditsoverview",
        name: "Creditsoverview",
        component: () => import("@/views/Account/AccountCreditsoverview.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.getters["authenticated"]) {
            return next("/signin");
          } else {
            if (store.getters["userAccessData"].data.user_active !== "active") {
              return next("/notActive");
            } else {
              next();
            }
          }
        },
      },
      {
        path: "edit",
        name: "AccountEdit",
        component: () => import("@/views/Account/AccountEdit.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.getters["authenticated"]) {
            return next("/signin");
          } else {
            if (store.getters["userAccessData"].data.user_active !== "active") {
              return next("/notActive");
            } else {
              next();
            }
          }
        },
      },
      {
        path: "myfileprices",
        name: "AccountMyPrices",
        component: () => import("@/views/Account/AccountMyPrices.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.getters["authenticated"]) {
            return next("/signin");
          } else {
            if (store.getters["userAccessData"].data.user_active !== "active") {
              return next("/notActive");
            } else {
              next();
            }
          }
        },
      },
      // Weitere Unter-Routen
    ],
  },
  // Weitere Routen
];

export default accountRoutes;
