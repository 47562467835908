<template>
  <div id="tasksView">
    <v-container fluid class="">
      <v-row>
        <v-col cols="12" lg="9" xl="10" class="">
          <v-card elevation="0" shaped>
            <IntersectToolbar @intersectingData="($event) => $emit('intersectingData', $event)" :title="`Tasks ${tasks.length}`" />
            <v-card-text>
              <v-row>
                <v-col lg="4">
                  <v-select
                    v-model="selectedDateRange"
                    :items="dateRanges"
                    @change="handleDatesRange()"
                    style="cursor: pointer"
                    label="Zeitraum"
                    prepend-icon="mdi-train-car"></v-select>
                </v-col>

                <v-col lg="4">
                  <v-text-field v-model="searchQuery" placeholder="Suche Model, Motor, ECU" clearable color="primary" prepend-icon="mdi-magnify"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col lg="8" v-if="selectedDateRange === 'custom'">
                  <v-row>
                    <v-col>
                      <v-menu ref="menuDateStart" v-model="menuCalendarDateStart" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dateStart" label="Date Start" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateStart"
                          scrollable
                          :events="tasksEvents"
                          event-color="green lighten-1"
                          color="primary"
                          :landscape="false"
                          @change="handleDatesRangeCustom()">
                          <!--                    <v-spacer></v-spacer>-->
                          <v-btn text color="primary" @click="menuCalendarDateStart = false"> Close</v-btn>
                          <!--                    <v-btn text color="primary" @click="$refs.menuDateStart.save(dateStart)"> OK </v-btn>-->
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col>
                      <v-menu ref="menuDateEnd" v-model="menuCalendarDateEnd" :close-on-content-click="true" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="dateEnd" label="Date End" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="dateEnd"
                          scrollable
                          :events="tasksEvents"
                          event-color="green lighten-1"
                          color="primary"
                          :landscape="false"
                          @change="handleDatesRangeCustom()">
                          <!--                    <v-spacer></v-spacer>-->
                          <v-btn text color="primary" @click="menuCalendarDateEnd = false"> Close</v-btn>
                          <!--                    <v-btn text color="primary" @click="$refs.menuDateEnd.save(dateStart)"> OK </v-btn>-->
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <TaskTable :tasks="tasks" :searchQuery="searchQuery"></TaskTable>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" lg="3" xl="2" :class="$vuetify.breakpoint.lgAndUp ? 'sidebarRight' : ''">
          <SideBarRight></SideBarRight>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import TaskTable from "@/components/tasks/TaskTable";
import SideBarRight from "@/components/ui/SidebarRight.vue";
import IntersectToolbar from "@/components/ui/IntersectToolbar.vue";

export default {
  name: "tasksView",

  components: {
    IntersectToolbar,
    SideBarRight,
    TaskTable,
  },
  data: () => ({
    dateStart: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10),
    dateEnd: new Date().toISOString().substr(0, 10),

    searchQuery: "",
    menuCalendarDateStart: false,
    menuCalendarDateEnd: false,
    tasksEvents: null,
    selectedDateRange: "all",
    dateRanges: [
      {
        text: "Alle Aufträge",
        value: "all",
      },
      {
        text: "Letzte 7 Tage",
        value: "past7Days",
      },
      {
        text: "Letztes Monat",
        value: "pastMonth",
      },
      {
        text: "Letztes Jahr",
        value: "pastYear",
      },
      {
        text: "Benutzerdefiniert",
        value: "custom",
      },
    ],
    allTasks: [], // nur als Zwischenspeicher
  }),

  mounted() {
    this.loadTasks(true);
    this.allTasks = this.tasks; // nur als Zwischenspeicher für die Kalenderpunkte
  },

  methods: {
    // lädt alle Tasks des Users
    loadTasks(reloadAllTasks) {
      const dateRange = {
        fromDate: this.dateStart,
        toDate: this.dateEnd,
      };

      const loadDataParameters = {
        reloadAllTasks: reloadAllTasks,
        selectedDateRange: this.selectedDateRange,
        fromDate: dateRange.fromDate,
        toDate: dateRange.toDate,
        $vuetify: this.$vuetify,
      };

      console.log("loadDataParameters", loadDataParameters);

      this.$store.dispatch("getAllTasksFromUser", loadDataParameters).then(() => {
        // fügt dem Kalender Punkte hinzu für die Tage, an denen jobs erstellt wurden
        this.tasksEvents = this.allTasks.map((data) => {
          // const day = Math.floor(Math.random() * 30);
          const d = new Date(data.task_created);
          return d.toISOString().substr(0, 10);
        });
      });
    },

    // reagiert auf Änderungen des Datumsbereichs
    handleDatesRange() {
      if (this.selectedDateRange === "past7Days") {
        this.dateStart = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
        this.dateEnd = new Date().toISOString().substr(0, 10);
      } else if (this.selectedDateRange === "pastMonth") {
        this.dateStart = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
        this.dateEnd = new Date().toISOString().substr(0, 10);
      } else if (this.selectedDateRange === "pastYear") {
        this.dateStart = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10);
        this.dateEnd = new Date().toISOString().substr(0, 10);
      }
      this.loadTasks(true);
    },

    handleDatesRangeCustom() {
      this.selectedDateRange = "custom";
      this.loadTasks(true);
    },
  },

  computed: {
    tasks() {
      return this.$store.getters.loadTasks;
    },
    showDevMode() {
      return this.$store.getters.showDevMode;
    },
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),
  },
};
</script>
