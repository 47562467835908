// Store User Authentifizierung im VUEX Store
import axios from "axios";
import store from "../store";
import router from "@/router";

export default {
  // speichert alles objects, strings ect.
  state: {
    token: null,
    user: null,
    userStatus: null,
    error: null,
    userAccessData: null,
    userCreate: null,
    organizations: [],
    // rightsData: null,
  },
  mutations: {
    SET_TOKEN(state, payload) {
      state.token = payload;
    },
    SET_USER(state, payload) {
      state.user = payload;
    },
    REMOVE_USER(state) {
      state.user = null;
    },
    USER_STATUS(state, payload) {
      state.userStatus = payload;
    },
    SET_ERROR(state, payload) {
      state.error = payload;
    },
    CLEAR_ERROR(state) {
      state.error = null;
    },
    USER_ACCESS_DATA(state, payload) {
      state.userAccessData = payload;
    },
    USER_ACCESS_DATA_CLEAR(state) {
      state.userAccessData = null;
    },

    USER_CREATE(state, payload) {
      state.userCreate = payload;
    },
    LOAD_ORGANIZATIONS(state, payload) {
      state.organizations = payload;
    },
    // USER_RIGHTS_DATA(state, payload) {
    //   state.rightsData = payload;
    // },
  },

  //read our informations from the state and show it anywhere in the app
  getters: {
    user(state) {
      return state.user;
    },
    status(state) {
      return state.userStatus;
    },
    error(state) {
      return state.error;
    },
    authenticated(state) {
      // console.log("authenticated");
      // console.log(state);
      return state.token && state.user;
    },
    userAccessData(state) {
      // console.log("GetUserAccessData");
      // console.log(state);
      if (state.userAccessData == null) {
        router.replace({ name: "Signin" });
        return null;
      }
      console.log(state.userAccessData);
      return state.userAccessData;
    },
    loadOrganizations(state) {
      return state.organizations;
    },
  },
  //actions commit mutations
  actions: {
    clearError({ commit }) {
      commit("CLEAR_ERROR");
    },

    // autoSignIn Action if like localstorage saved
    autoSignIn({ commit }, payload) {
      commit("SHOW_PAGE_LOADING_INDICATOR", true);
      commit("SET_USER", payload.uid);
      commit("USER_STATUS", "Success");
      commit("SET_ERROR", null);
      commit("SHOW_PAGE_LOADING_INDICATOR", false);
    },

    //signin the user
    signIn({ commit }, payload) {
      return new Promise((resolve) => {
        // console.log("Login Data: ");
        //commit("SHOW_PAGE_LOADING_INDICATOR", true);
        var loginData = {
          email: String(payload.username),
          password: String(payload.password),
        };

        axios
          // .post("api/jwt-auth/v1/token", loginData)
          .post("api/loginCheckPassword", loginData)
          .then((response) => {
            store.dispatch("verifyToken", response.data.token).then((result) => {
              if (result) {
                commit("SET_ERROR", null);
                store.dispatch("loadCreditBalance");
                resolve(true);
              } else {
                router.replace({ name: "Signin" });
                resolve(false);
              }
            });
          })
          .catch((error) => {
            if (error.response.status === 403) {
              console.log("error_ :" + error.response.headers);
              console.log("MSG: " + error.response.data.message);
              commit("SHOW_PAGE_LOADING_INDICATOR", false);
              const statusObj = {
                alertType: "error",
                text: error.response.data.message,
              };

              commit("ALERT_STATUS", statusObj);
              commit("USER_STATUS", null);
              resolve(false);
            }
          });
      });
    },

    verifyToken({ commit, dispatch, state }, token) {
      if (token) {
        //POST MUTATION TOKEN ect. with Commit
        commit("SET_TOKEN", token);
      }
      // if not checked for state.token the browser gives error in console.
      if (!state.token) {
        return;
      }
      return new Promise((resolve) => {
        // other plugin! the standard API included in the Core files.
        // get the userdata with /users/me // Headers comes from other main file
        const tokenData = {
          token: token,
        };
        axios
          .post("api/verifyToken", tokenData)
          .then(() => {
            // LADE HIER DEN USER SEINE ACCESS DATEN
            this.dispatch("getUserAccessData", tokenData)
              .then((responseAccessData) => {
                commit("SET_USER", responseAccessData);
                commit("USER_STATUS", "Success");
                commit("CLEAR_ERROR");
                commit("SHOW_PAGE_LOADING_INDICATOR", false);
                resolve(true);

                dispatch("loadCreditBalance", tokenData);
              })
              .catch((error) => {
                console.log("error getUserAccessData " + error);
                commit("SET_TOKEN", null);
                commit("SET_USER", null);
                commit("SHOW_PAGE_LOADING_INDICATOR", false);
                resolve(false);
              });
          })
          .catch((error) => {
            console.log("error verifyToken " + error);
            //commit both to null
            commit("SET_TOKEN", null);
            commit("SET_USER", null);
            commit("SHOW_PAGE_LOADING_INDICATOR", false);
            resolve(error);
          });
      });
    },

    getUserAccessData({ commit }, payload) {
      commit("USER_ACCESS_DATA_CLEAR");
      return new Promise((resolve) => {
        axios
          .get("api/getUserAccessData", { params: payload })
          .then((response) => {
            if (response.status === 200) {
              if (response.data === null) {
                commit("SET_TOKEN", null);
                commit("SET_USER", null);
                commit("SHOW_PAGE_LOADING_INDICATOR", false);
                resolve(false);
              }
              if (response.data.code === 120) {
                let userData = response.data;
                console.log(userData);
                commit("USER_ACCESS_DATA", userData);
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            localStorage.setItem("tokenTF24Portal", "");
            console.log("error loading USER_ACCESS_DATA: " + error);
          });
      });
    },

    signOut({ commit, state }) {
      commit("SHOW_PAGE_LOADING_INDICATOR", true);
      const data = {
        token: state.token,
      };
      axios
        .post("api/signOut", data)
        .then(() => {
          commit("SET_TOKEN", null);
          commit("SET_USER", null);
          commit("REMOVE_USER");
          commit("SET_ERROR", null);
          commit("CLEAR_ERROR");
          commit("SHOW_PAGE_LOADING_INDICATOR", false);
          location.reload();
        })
        .catch((error) => {
          console.log("error with signOut" + error);
          commit("SHOW_PAGE_LOADING_INDICATOR", false);
        });
    },

    createNewUser({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("api/createUser", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 109) {
                commit("USER_CREATE", response);
              } else if (response.data.code === 106) {
                console.log("code 106 error register. Email ist vorhanden");
              }
              resolve(response);
            } else {
              console.log("status error register. not 200");
            }
          })
          .catch((error) => {
            console.log("error createNewUser ", error);
          });
      });
    },

    createNewUserWithNewCompany({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("api/registerUserWithNewCompany", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 109) {
                commit("USER_CREATE", response);
              } else if (response.data.code === 106) {
                console.log("code 106 error register. Email ist vorhanden");
              }
              resolve(response);
            } else {
              console.log("status error register. not 200");
            }
          })
          .catch((error) => {
            console.log("error createNewUser ", error);
          });
      });
    },

    createNewUserWithCompanyId({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("api/registerUserWithCompanyId", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 109) {
                commit("USER_CREATE", response);
              } else if (response.data.code === 106) {
                console.log("code 106 error register. Email ist vorhanden");
              }
              resolve(response);
            } else {
              console.log("status error register. not 200");
            }
          })
          .catch((error) => {
            console.log("error createNewUser ", error);
          });
      });
    },

    //FORDERE EIN NEUES PASSWORT VIA EMAIL AN
    resetUserPassword(_, payload) {
      return new Promise((resolve) => {
        axios
          .put("api/resetUserPassword", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
            } else {
              console.log("status error resetUserPassword. not 200");
            }
          })
          .catch((error) => {
            console.log("error resetUserPassword ", error);
          });
      });
    },

    // SETZT DAS NEUE PASSWORT FÜR DEN USER
    setNewPasswordForUser({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("api/setNewPasswordForUser", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              commit("ALERT_STATUS", response.data.message);
              if (response.data.code === 120) {
                resolve(response);
              } else {
                resolve(false);
              }
            } else {
              console.log("status error setNewPasswordForUser. not 200");
            }
          })
          .catch((error) => {
            console.log("error setNewPasswordForUser ", error);
          });
      });
    },

    //UPDATE USER KEY DATA
    updateUserKey({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .put("api/updateUserKey", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
              commit("ALERT_STATUS", response.data.message);
            } else {
              console.log("status error updateUserKey. not 200");
            }
          })
          .catch((error) => {
            console.log("error updateUserKey ", error);
          });
      });
    },

    loadOrganizations({ commit }) {
      return new Promise((resolve) => {
        axios
          .get("api/loadOrganizations")
          .then((response) => {
            if (response.status === 200) {
              if (response.data.code === 120) {
                console.log("loadOrganizations data loaded", response.data.organizations);
                commit("LOAD_ORGANIZATIONS", response.data.organizations);
              }
            }
            resolve();
          })
          .catch((error) => {
            console.log("error loading loadOrganizations data: " + error);
          });
      });
    },

    //UPDATE USER updateOrganization DATA
    updateOrganization({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .put("api/updateOrganization", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
              commit("ALERT_STATUS", response.data.message);
            } else {
              console.log("status error updateOrganization. not 200");
            }
          })
          .catch((error) => {
            console.log("error updateOrganization ", error);
          });
      });
    },

    //Ändert die Verknüpfung zwischen User und Organization
    userChangeOrganizationConnection({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .put("api/userChangeOrganizationConnection", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              resolve(response);
              commit("ALERT_STATUS", response.data.message);
            } else {
              console.log("status error userChangeOrganizationConnection. not 200");
            }
          })
          .catch((error) => {
            console.log("error userChangeOrganizationConnection ", error);
          });
      });
    },
  },
};
