import axios from "axios";
export default {
  state: {
    filePricesCustomerMain: [],
    filePricesCustomerAddon: [],
    filePricesCustomerSpezial: [],
  },
  mutations: {
    FILE_PRICES_CUSTOMER_MAIN(state, payload) {
      state.filePricesCustomerMain = payload;
    },

    FILE_PRICES_CUSTOMER_ADDON(state, payload) {
      state.filePricesCustomerAddon = payload;
    },
    FILE_PRICES_CUSTOMER_SPEZIAL(state, payload) {
      state.filePricesCustomerSpezial = payload;
    },
  },

  //read our informations from the state and show it anywhere in the app
  getters: {
    loadCustomerPricesMain(state) {
      return state.filePricesCustomerMain;
    },

    loadCustomerPricesAddon(state) {
      return state.filePricesCustomerAddon;
    },
    loadCustomerPricesSpezial(state) {
      return state.filePricesCustomerSpezial;
    },
  },
  //actions commit mutations
  actions: {
    getCustomerPricesMain({ commit }) {
      return new Promise(resolve => {
        axios
          .get("tf24API/getCustomerPricesMain")
          .then(response => {
            if (response.status === 200) {
              // success true
              commit("FILE_PRICES_CUSTOMER_MAIN", response.data);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(error => {
            console.log("error loading FILE_PRICES_CUSTOMER_MAIN data " + error);
          });
      });
    },
    getCustomerPricesAddon({ commit }) {
      return new Promise(resolve => {
        axios
          .get("tf24API/getCustomerPricesAddon")
          .then(response => {
            if (response.status === 200) {
              // success true
              commit("FILE_PRICES_CUSTOMER_ADDON", response.data);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(error => {
            console.log("error loading FILE_PRICES_CUSTOMER_ADDON data " + error);
          });
      });
    },
    getCustomerPricesSpezial({ commit }, payload) {
      return new Promise(resolve => {
        const dat = 0;
        if (payload > 0) {
          dat = payload;
        }
        const data = {
          user_id: dat,
        };
        axios
          .post("tf24API/getCustomerPricesSpezial", data, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then(response => {
            if (response.status === 200) {
              // success true
              commit("FILE_PRICES_CUSTOMER_SPEZIAL", response.data);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch(error => {
            console.log("error loading FILE_PRICES_CUSTOMER_SPEZIAL data " + error);
          });
      });
    },
  },
};
