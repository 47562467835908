import axios from "axios";
export default {
  state: {
    globalOptions: null,
    alertUpdateMessage: {},
  },
  mutations: {
    GLOBAL_OPTIONS_LOAD(state, payload) {
      state.globalOptions = payload;
    },
    ALERT_UPDATE_MESSAGE(state, payload) {
      state.alertUpdateMessage = payload;
    },
  },
  getters: {
    loadGlobalOptions(state) {
      return state.globalOptions;
    },
    alertUpdateMessage(state) {
      return state.alertUpdateMessage;
    },
  },

  actions: {
    loadGlobalOptions({ commit }) {
      return new Promise(resolve => {
        axios
          .get("tf24API/loadGlobalOptions")
          .then(response => {
            commit("GLOBAL_OPTIONS_LOAD", response.data);
            resolve();
          })
          .catch(error => {
            console.log("error loadingloading global option: " + error);
          });
      });
    },

    alertUpdateMessage({
      commit
    }, payload) {
      commit('ALERT_UPDATE_MESSAGE', payload)
    },

    appVersionChecker({commit}) {
        var currentLocalSavedAppversion = localStorage.getItem("tf24AppVersion");
        let globalOption = this.getters.loadGlobalOptions
        let data = JSON.parse(globalOption[0].globalOption_json)
        if (currentLocalSavedAppversion != data.appversion) {
          //UPDATE MESSAGE
          const statusObj = {
            toast: true,
            position: "top",
            icon: "warning",
            title: "New App version " + data.appversion + " available! Please update for new features! ",
          }

          commit('ALERT_UPDATE_MESSAGE',statusObj)
          
        } else {
          commit('ALERT_UPDATE_MESSAGE',null)
          if (currentLocalSavedAppversion == null) {
            localStorage.setItem("tf24AppVersion", data.appversion);
          }
          console.log("app version: " + currentLocalSavedAppversion);
        }
      // });
    },



    updateGlobalOptions(_, payload) {
      return new Promise(resolve => {
        axios
          .post("tf24API/updateGlobalOptions", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(response => {
            resolve(true);
          })
          .catch(error => {
            console.log("error update updateGlobalOptions  " + error);
            console.log(error);
          });
      });
    },
  },
};
