<template>
  <div v-if="userAccessData" class="sidebar">
    <!-- <nav class="mb-4"> -->
    <Topbar :headlineIntersectingData="headlineIntersectingData" :openSidebar="openSidebar" @toggleSidebar="setToggleSidebar" @signOutRequest="setSignOutRequest" />

    <!-- <v-sheet class="overflow-hidden" style="position: relative"> -->
    <!-- <v-navigation-drawer app v-model="openSidebar" mobile-breakpoint="0" permanent expand-on-hover  rail overlay clipped mini-variant-width="100"> -->
    <v-navigation-drawer v-model="openSidebar" app class="menu" clipped expand-on-hover mobile-breakpoint="700" rail width="250px">
      <div class="text-center mt-5 shrink">
        <router-link to="/account">
          <div class="d-flex align-center" style="max-width: 80px">
            <v-img :src="$vuetify.theme.dark ? require('@/assets/logo_tf24_white.svg') : require('@/assets/logo_tf24_dark.svg')" alt="" transition="scale-transition" />
          </div>
        </router-link>
      </div>

      <v-divider></v-divider>

      <v-list shaped>
        <template v-for="(item, i) in items">
          <v-list-item v-if="!item.children" :key="i" :to="item.linkTo" exact-active-class="primary white--text">
            <template v-slot:prepend>
              <v-icon :icon="item.icon"></v-icon>
            </template>
            <v-list-item-icon>
              <v-icon exact-active-class="primary white--text">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>

          <v-list-group v-if="item.children" :key="i" :value="item.childrenExpanded" class="" no-action>
            <v-subheader v-if="item.children">{{ $t(item.title) }}</v-subheader>
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon exact-active-class="primary white--text">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
              </v-list-item>
            </template>

            <v-list-item v-for="(child, j) in item.children" :key="j" :to="child.linkTo" exact-active-class="primary white--text">
              <template v-slot:prepend>
                <v-icon :icon="item.icon"></v-icon>
              </template>
              <v-list-item-icon>
                <v-icon exact-active-class="primary white--text">{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ child.title }}</v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>

      <template v-slot:append>
        <div>
          <v-list-item ripple @click="signOutRequest()">
            <v-list-item-icon>
              <v-icon color="red accent-4">mdi-door</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-navigation-drawer>

    <!-- </v-sheet> -->
    <!-- </nav> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Topbar from "@/components/ui/Topbar.vue";
import SideBarRight from "@/components/ui/SidebarRight.vue";
// import SocketioService from "@/socketServiceClient.js";

export default {
  name: "SideBar",
  props: {
    headlineIntersectingData: Object,
  },
  components: {
    SideBarRight,
    Topbar,
  },

  data: () => ({
    selectedItem: null,
    items: [
      {
        title: "menuJobCreate",
        linkTo: "/jobs/new",
        icon: "mdi-note-plus-outline",
      },
      {
        title: "menuTasks",
        linkTo: "/tasks",
        icon: "mdi-clipboard-file",
      },
      {
        title: "menuJobs",
        linkTo: "/jobs",
        icon: "mdi-clipboard-list",
      },
      {
        title: "Account",
        linkTo: "#",
        icon: "mdi-account-circle",
        children: [
          { title: "Dashboard", linkTo: "/account/dashboard", icon: "mdi-view-dashboard" },
          { title: "My File Prices", linkTo: "/account/myFileprices", icon: "mdi-currency-usd" },
          { title: "Credits List", linkTo: "/account/creditsoverview", icon: "mdi-credit-card-clock" },
          { title: "Buy Credits", linkTo: "/account/buyCredits", icon: "mdi-cart" },
          { title: "Invoices", linkTo: "/account/invoices", icon: "mdi-file-document" },
          { title: "Account Edit", linkTo: "/account/edit", icon: "mdi-pencil" },
        ],
        childrenExpanded: false,
      },
    ],

    openSidebar: true,

    // connectionStatus: false,
  }),

  beforeRouteLeave(to, from, next) {
    // Set selectedItem to the index of the current route's menu item
    const currentRoute = this.items.find((item) => item.linkTo === to.path);
    this.selectedItem = currentRoute ? this.items.indexOf(currentRoute) : null;
    next();
  },

  created() {},

  methods: {
    setToggleSidebar() {
      this.toggleSidebar();
    },

    toggleSidebar() {
      this.openSidebar = !this.openSidebar;
    },

    setSignOutRequest() {
      this.signOutRequest();
    },

    signOutRequest() {
      this.$swal({
        toast: false,
        position: "center-center",
        icon: "warning",
        title: "Logout?",
        text: "Are you sure you want to log out?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Please log me out!",
        denyButtonText: `Cancel`,
        cancelButtonText: "Cancel",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.signOut();
          this.$router.push("/login");
          this.$swal("Logged out!", "You have been logged out.", "success");
        } else if (result.isDenied) {
          // this.$swal("You are still logged in!", "", "info");
        }
      });
    },

    // check for signOut mapActions!
    ...mapActions({ signOutAction: "signOut" }),

    signOut() {
      this.signOutAction().then(() => {
        console.log("Sign out... ");
        //this.$router.go() // reload page
        this.$router.replace("/");
        this.$store.commit("CLEAR_ERROR");
      });
    },
  },

  watch: {},

  computed: {
    isSidebarExpanded() {
      const sidebarWidth = parseInt(getComputedStyle(this.$refs.sidebar).width);
      return sidebarWidth === 250; // change the value to your desired width
    },

    ...mapGetters({
      authenticated: "user",
      userAccessData: "userAccessData",
      alertUpdateMessage: "alertUpdateMessage",
      loadCreditBalance: "loadCreditBalance",
    }),
  },
};
</script>

<style scoped>
.menu {
  /* z-index: 9999; */
  /* position: absolute;  */
}

/* .v-list-group__header.v-list-item.v-list-item--link{
  padding-left: 0px !important;
} */
.border {
  border: none !important;
  /* background: #fcfcfc; */
  /* border-top-left-radius: 40px; */
  /* border-bottom-left-radius: 40px; */

  text-decoration: none;
}

/* 

.border b:nth-child(1) {
  position: absolute;
  top: -15px;
  height: 15px;
  width: 100%;
  background: #fcfcfc;

  display: none;
}
.border b:nth-child(1)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 30px;
  background: #ffd740;
}

.border b:nth-child(2) {
  position: absolute;

  bottom: -15px;
  height: 15px;
  width: 100%;
  background: #fcfcfc;
  display: none;
}
.border b:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 30px;
  background: #ffd740;
}
.border b:nth-child(1),
.border b:nth-child(2) {
  display: block;
}

.v-list-item__icon:last-of-type:not(:only-child) {
  margin-left: 2px;
} */
</style>
