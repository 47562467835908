<template>
  <div class="home">
    <!-- <v-parallax dark src="@/assets/bg_image.svg" > -->
    <!-- <v-row align="center" justify="center"> -->
    <v-container class="mt-10" d-flex justify-center>
      <v-card elevation="0" max-width="800" class="d-flex align-center justify-center mx-auto text-center" shaped>
        <v-card-text>
          <div class="d-flex align-center justify-center">
            <v-img alt="Vuetify Logo" width="150" height="200" class="align-center justify-center" contain src="@/assets/logo_tf24_dark.svg" transition="scale-transition" />
          </div>
          <div class="text-center">
            <h2 class="headline_1">{{ $t("homeText_3") }}</h2>
            <p class="text-dark-light font-weight-bold">{{ $t("homeText_5") }}</p>

            <Language></Language>

            <div v-if="!authenticated">
              <v-row class="mt-2">
                <v-col cols="12" md="6">
                  <p>{{ $t("login_2") }}</p>
                  <v-btn rounded :to="'/signin'" color="primary accent-3" class="" :class="$vuetify.theme.dark ? 'black--text' : 'white--text'">{{ $t("login_1") }}</v-btn>
                </v-col>

                <v-col cols="12" md="6">
                  <p>{{ $t("login_3") }}</p>
                  <v-btn rounded :to="'/signUp'" color="primary accent-3" class="" :class="$vuetify.theme.dark ? 'black--text' : 'white--text'">{{ $t("createAccount") }}</v-btn>
                </v-col>
              </v-row>
            </div>

            <div v-else>
              <h2 class="">{{ $t("homeText_1") }}</h2>
              <p class="">{{ $t("homeText_2") }}</p>
              <p>{{ $t("userStillloggedInText") }}</p>
              <v-btn :to="'/account'" color="green accent-4" :class="$vuetify.theme.dark ? 'black--text' : 'black--text'" rounded>{{ $t("menuAccount") }}</v-btn>
              <v-btn :to="'/logout'" class="ml-2" color="primary" rounded>{{ $t("logout_3") }}</v-btn>
            </div>
          </div>
        </v-card-text>
        <!-- <v-card-actions center> </v-card-actions> -->
      </v-card>
    </v-container>
    <!-- </v-row> -->
  </div>
</template>

<script>
import Language from "@/components/ui/Language.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Language,
  },
  name: "HomeView",
  //data: () => ({});

  methods: {},

  computed: {
    ...mapGetters(["authenticated"]),
  },
};
</script>

<style scoped>
.logo-container {
  width: 150px;
  height: 150px;
}
</style>
