<template>
  <div class="jobDetailsMain">
    <v-container fluid>
      <v-row>
        <v-col cols="12" lg="9" xl="10" class="">
          <v-container fluid>
            <JobDetailsHeader class="" :jobDetailsData="jobDetailsData" :tasks="jobDetailsData.tasks" @close-task-option-menu="setCloseTaskOptionMenu"></JobDetailsHeader>
          </v-container>

          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <JobDetailsTaskTable :jobDetailsData="jobDetailsData" ref="jobDetailsTaskTable"></JobDetailsTaskTable>
              </v-col>

              <!--              <v-divider vertical></v-divider>-->

              <!--              <v-col cols="12" md="6">-->
              <!--                &lt;!&ndash;                {{ jobDetailsData }}&ndash;&gt;-->

              <!--                &lt;!&ndash;                jobDetailsData: {{ jobDetailsData }}<br />&ndash;&gt;-->
              <!--&lt;!&ndash;                <TaskTicketDialog :jobDetailsData="jobDetailsData" :selectedTask="selectedTask" ref="taskTicket" />&ndash;&gt;-->
              <!--              </v-col>-->
            </v-row>
          </v-container>
        </v-col>

        <v-col cols="12" lg="3" xl="2" :class="$vuetify.breakpoint.lgAndUp ? 'sidebarRight' : ''">
          <SideBarRight></SideBarRight>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import JobDetailsHeader from "@/components/jobs/jobDetails/JobDetailsHeader.vue";
import { mapGetters } from "vuex";
import TaskMessageList from "@/components/jobs/jobDetails/TaskMessageList.vue";
import DownloadFile from "@/components/tasks/tableItems/fileDownload/DownloadFile.vue";
import JobDetailsTaskTable from "@/components/jobs/jobDetails/JobDetailsTaskTable.vue";
import SideBarRight from "@/components/ui/SidebarRight.vue";
import TaskTicketDialog from "@/components/tasks/TaskTicketDialog.vue";

export default {
  name: "JobDetailsMain",
  components: { TaskTicketDialog, SideBarRight, DownloadFile, TaskMessageList, JobDetailsHeader, JobDetailsTaskTable },

  props: {
    jobDetailsData: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    events: [],
    input: null,
    nonce: 0,
  }),

  mounted() {},

  watch: {},

  methods: {
    openTaskMessagesDialog(task) {},

    getPowerDescription(job) {
      return job ? job.vehicle_engineCCm + "L " + job.vehicle_enginePS + "HP" : "-";
    },

    getEcuDescription(job) {
      return job ? job.vehicle_ecuBrand + " " + job.vehicle_ecuType : "-";
    },
    setCloseTaskOptionMenu() {
      this.$refs.jobDetailsTaskTable.closeMenu();
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),

    selectedTask() {
      return this.jobDetailsData.tasks?.[0] ?? {};
    },

    tasks() {
      const dummyTasks = [
        {
          task_id: 22446,
          task_job_id: 4857,
          task_status: "inProgress",
          task_type: "new",
          task_vehicle_id: 6269,
          task_ecu_id: 0,
          task_fileName: "6140R.bin",
          task_fileNameTemp: "file-1686580240294-271772205",
          task_fileSize: 393216,
          task_fileType: "bin",
          task_filePrice: 288,
          task_paymentStatus: "open",
          task_fileLocation: "file_uploads/TASK_22446/Ori_Files/6140R.bin",
          task_created: "2023-06-12T14:30:40.000Z",
          task_changed: "2023-06-12T14:30:37.000Z",
          task_userID: 6,
          task_modifications: "Stage_1,EGR",
          task_info: "--",
          task_automaticChecksum: "cs_ols",
          task_fileNameModFile: "",
          task_fileTypeModFile: "",
          task_fileLocationModFile: "",
          task_downloadUserID: 0,
          task_downloadDate: "2020-01-01T00:01:01.000Z",
          old_id: null,
          tenant_id: 1,
        },
        {
          task_id: 22447,
          task_job_id: 4857,
          task_status: "completed",
          task_type: "old",
          task_vehicle_id: 6270,
          task_ecu_id: 1,
          task_fileName: "6141R.bin",
          task_fileNameTemp: "file-1686580240295-271772206",
          task_fileSize: 393217,
          task_fileType: "bin",
          task_filePrice: 289,
          task_paymentStatus: "closed",
          task_fileLocation: "file_uploads/TASK_22447/Ori_Files/6141R.bin",
          task_created: "2023-06-13T14:30:40.000Z",
          task_changed: "2023-06-13T14:30:37.000Z",
          task_userID: 7,
          task_modifications: "Stage_2,EGR",
          task_info: "--",
          task_automaticChecksum: "cs_ols",
          task_fileNameModFile: "",
          task_fileTypeModFile: "",
          task_fileLocationModFile: "",
          task_downloadUserID: 0,
          task_downloadDate: "2020-01-02T00:01:01.000Z",
          old_id: null,
          tenant_id: 2,
        },
        {
          task_id: 22448,
          task_job_id: 4857,
          task_status: "inProgress",
          task_type: "new",
          task_vehicle_id: 6271,
          task_ecu_id: 2,
          task_fileName: "6142R.bin",
          task_fileNameTemp: "file-1686580240296-271772207",
          task_fileSize: 393218,
          task_fileType: "bin",
          task_filePrice: 290,
          task_paymentStatus: "open",
          task_fileLocation: "file_uploads/TASK_22448/Ori_Files/6142R.bin",
          task_created: "2023-06-14T14:30:40.000Z",
          task_changed: "2023-06-14T14:30:37.000Z",
          task_userID: 8,
          task_modifications: "Stage_3,EGR",
          task_info: "Please make it strong. look for no errors please",
          task_automaticChecksum: "cs_ols",
          task_fileNameModFile: "",
          task_fileTypeModFile: "",
          task_fileLocationModFile: "",
          task_downloadUserID: 0,
          task_downloadDate: "2020-01-03T00:01:01.000Z",
          old_id: null,
          tenant_id: 3,
        },
      ];

      return dummyTasks;
    },

    // tasks() {
    //   // get tasks
    //   const tasks = this.jobDetailsData.tasks;
    //
    //   if (!tasks) {
    //     return [];
    //   }
    //
    //   // order tasks by task_created desc
    //   tasks.sort((a, b) => {
    //     return new Date(b.task_created) - new Date(a.task_created);
    //   });
    //   return tasks;
    // },
  },
};
</script>

<style scoped lang="scss"></style>
