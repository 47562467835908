<template>
  <div v-if="newJob.selectedVehicle">
    <v-switch
      inset
      v-for="(method, index) in availableMethods"
      :key="method.method_id"
      :value="method"
      class="mb-3"
      :label="method.method_name_customer"
      :color="method.method_url_link !== '' ? 'warning' : null"
      v-model="newJob.selectedMethods"
      hide-details
      @change="selectedCheckboxMethods(method)">
      <template v-slot:label>
        <div class="d-flex align-center">
          <div class="mr-2">
            <img :src="getIconForMethod(method)" :alt="method.method_name_customer" :width="getIconForMethodWidth(method.method_name)" />
          </div>
          <div>
            <!--            <b>{{ method.method_name_customer }}</b-->
            <br />
            <small>{{ method.method_comment_customer }}</small>
          </div>
        </div>
      </template>
    </v-switch>

    <v-switch
      inset
      v-model="switchMyMethodNotListed"
      class="mt-4"
      size="lg"
      label="Meine Methode ist nicht gelistet"
      :hint="switchMyMethodNotListed ? 'file price is set by support' : ''"
      @change="switchMethods"
      hide-details>
      <template v-slot:label>
        <div class="d-flex align-center">
          <div class="mr-2">
            <img
              :src="$vuetify.theme.dark ? require(`@/assets/icons/icon_notlisted_darkmode.svg`) : require(`@/assets/icons/icon_notlisted.svg`)"
              :alt="'notlisted_icon'"
              :width="20" />
          </div>
          <div>
            <b>Meine Methode ist nicht gelistet</b><br />
            <small>(Längere Wartezeit) Wird vom Support bearbeitet</small>
          </div>
        </div>
      </template>
    </v-switch>

    <v-divider class="mt-2 mb-2"></v-divider>

    <template>
      <v-alert v-if="switchMyMethodNotListed" :class="'amber accent-3 black--text'" icon="mdi-help-circle-outline" prominent>
        Bist du sicher, dass deine Methode nicht gelistet ist? <br />
        Die Dauer der Bearbeitung kann sich dadurch verlängern.<br />
        <strong>Der Filepreis wird vom Support festgelegt.</strong>
      </v-alert>
    </template>

    <FilePriceList
      :discount-price-quantity="discountPriceQuantity"
      :discount-price-quantity-difference="discountPriceQuantityDifference"
      :file-price-current="fileprice"
      :new-job="newJob"
      :switch-my-method-not-listed="switchMyMethodNotListed" />
  </div>
</template>
<script>
import { customFunctions as $customFunctions } from "@/customFunctions";
import FilePriceList from "@/components/jobs/createJob/FilePriceList.vue";
import { mapGetters } from "vuex";

export default {
  name: "StepperMethodSelection",
  components: { FilePriceList },
  props: {
    availableMethods: {},
    newJob: {},
  },

  data() {
    return {
      discountPriceQuantity: 0,
      discountPriceQuantityDifference: 0,
      fileprice: 0,
      switchMyMethodNotListed: false,
    };
  },

  watch: {
    // prüft hier ob die Methode "method_not_listed" ausgewählt wurde
    // je nachdem wird die Variable "switchMyMethodNotListed" auf true oder false gesetzt
    // wenn eine andere Methode ausgewählt wurde, wird die Variable "switchMyMethodNotListed" auf false gesetzt
    "newJob.selectedMethods"(newSelectedMethods) {
      // wenn method_not_listed ausgewählt wurde, wird die Variable "switchMyMethodNotListed" auf true gesetzt
      if (newSelectedMethods.some((method) => method.method_name !== "method_not_listed")) {
        this.emitSwitchMyMethodNotListed(false);
      }

      if (newSelectedMethods.length === 0) {
        this.emitFileprice(this.fileprice);
      }
    },
  },

  methods: {
    emitSwitchMyMethodNotListed(value) {
      this.switchMyMethodNotListed = value;
      this.$emit("switch-my-method-not-listed", value);
    },

    emitFileprice(value) {
      this.fileprice = value;
      this.$emit("emit-file-price", value);
    },

    selectedCheckboxMethods(method) {
      method.method_name = method.method_name.toLowerCase();

      // Check if the selected method is a Stage method
      if (method.method_name.includes("stage_")) {
        // Deselect all other selected Stage methods
        const indexToRemove = this.newJob.selectedMethods.findIndex(
          (selectedMethod) => selectedMethod?.method_name && selectedMethod.method_name.includes("stage_") && selectedMethod !== method
        );
        if (indexToRemove >= 0) {
          this.newJob.selectedMethods.splice(indexToRemove, 1);
        }
      }
      // Toggle the selected state of the clicked method
      method.selectedMethod = !method.selectedMethod;

      //entferne method_not_listed aus dem array
      this.newJob.selectedMethods = this.newJob.selectedMethods.filter(function (item) {
        return item.method_name !== "method_not_listed";
      });
      this.methodSelectionLogic();
    },

    switchMethods() {
      this.emitSwitchMyMethodNotListed(this.switchMyMethodNotListed);

      if (this.switchMyMethodNotListed) {
        this.newJob.selectedMethods = [
          {
            method_id: "0",
            method_vehicle_id: "0",
            method_name: "method_not_listed",
            method_name_customer: "",
            method_status: "active",
            method_comment: "",
            method_comment_customer: "",
            method_price_id: "0",
            method_price_addon_id: "0",
            method_url_link: "",
            ecu_method_id: "0",
            ecu_id: "0",
            method_isUniversal: "0",
            method_universalDTCS: "0",
            dtc_method_id: "0",
            method_hasDTCLogic: "0",
            method_type: "method_not_listed",
            selectedMethod: false,
          },
        ];
        this.emitFileprice(0);
      } else {
        //entfernt hier die Methode "method_not_listed" aus dem Array
        this.newJob.selectedMethods = this.newJob.selectedMethods.filter(function (item) {
          return item.method_name !== "method_not_listed";
        });

        this.newJob.selectedMethods = [];
      }
    },

    methodSelectionLogic() {
      let methods = this.newJob.selectedMethods;
      let length = methods.length;

      if (length > 0) {
        this.loadCurrentFilePrice(methods);

        const acmFileID = this.newJob.selectedVehicle.vehicle_acm_fileID;

        console.log("acmFileID: ", acmFileID);
        // NUR FÜR DEN RESET DER INPUT FELDER ABHÄNGIG VOM ACM ID FILEID
        if (acmFileID === 0) {
          this.showMCMFileInput = true;
        } else if (acmFileID > 0) {
          this.showMCMFileInput = false;
          this.showACMFileInput = false;
        }

        if (acmFileID === 1) {
          // ACM INPUT FELD WENN EIN DELETE ANGEKLICKT WIRD
          methods.forEach((item) => {
            if (item.method_price_addon_id > 0) {
              this.showACMFileInput = true;
            }
            if (item.method_price_addon_id === 0) {
              this.showMCMFileInput = true;
            }
          });
        } else if (acmFileID === 2) {
          // ACM INPUT FELD WENN EIN DELETE ANGEKLICKT WIRD
          // ZUSÄTZLICH CHECK OB DELTE ODER TUNING

          this.showMCMFileInput = true;

          methods.forEach((item) => {
            // ZEIGE NUR WENN AUCH EIN DELETE DABEI IST
            if (item.method_price_addon_id > 0) {
              this.showACMFileInput = true;
            }
          });
        } else {
          //this.showMCMFileInput = true;
        }
      } else {
        //  this.fileprice = 0;
        // this.calculateDiscountPriceQuantity();
        this.showACMFileInput = false;
        this.showMCMFileInput = false;
        // this.$refs.methodDataTable.clearSelected();
        this.newJob.selectedMethods = [];
      }
    },

    getIconForMethod(methodData) {
      if (!methodData) {
        return "";
      }

      let selected = this.newJob.selectedMethods;
      let methodName = methodData.method_name.toLowerCase();

      // holt sich die icons
      let iconMappings = this.$customFunctions.getIconMethodList();

      if (!iconMappings[methodName]) {
      }

      const isActive = selected.some((selectedMethod) => selectedMethod.method_id === methodData.method_id);

      // wenn die Method beispielsweise als egr delete abgespeichert ist, dann wird hier nur egr benoetigt, um
      // das icon zu finden
      if (!iconMappings[methodName]) {
        const firstNameOfMethod = methodName.split(" ")[0];

        if (!firstNameOfMethod || !iconMappings[firstNameOfMethod]) {
          return "";
        }
        methodName = firstNameOfMethod;
      }

      const iconName =
        isActive && this.$vuetify.theme.dark
          ? iconMappings[methodName].active_dark
          : isActive && !this.$vuetify.theme.dark
          ? iconMappings[methodName].active_light
          : !isActive && this.$vuetify.theme.dark
          ? iconMappings[methodName].inactive_dark
          : iconMappings[methodName].inactive_light;
      return require(`@/assets/icons/${iconName}`);
    },

    /**
     * Hier wird die Breite erstellt für die Methoden
     * @param name
     * @return {string}
     */
    getIconForMethodWidth(name) {
      if (!name) {
        return "";
      }

      const methodName = name.toLowerCase();

      const iconMappings = {
        stage_1: "110",
        stage_2: "110",
        stage_3: "110",
        stage_4: "110",
        stage_5: "110",
        egr: "110",
        dpf: "110",
        def: "110",
        dtc: "110",
        cat: "110",
        vmax: "110",
        notlisted: "20",
      };

      const iconName = iconMappings[methodName] || "110";
      return `${iconName}`;
    },

    loadCurrentFilePrice(methods) {
      const data = {
        methods: methods,
        user_id: this.user.id,
      };

      this.$store
        .dispatch("loadCurrentFilePrice", data)
        .then((priceData) => {
          if (priceData.status === 200) {
            if (priceData.data.success === false) {
              // sendet hier den Preis 0 zurück
              this.emitFileprice(0);
            } else {
              // Optional kann hier noch der Preis berechnet werden für die Anzahl der Methoden
              this.emitFileprice(this.calculateDiscountPriceQuantity(priceData.data.data));
            }
          } else {
            console.debug("error load file price status");
          }
        })
        .catch((error) => {
          console.debug("loadCurrentFilePrice error: " + error);
        });
    },

    calculateDiscountPriceQuantity(filepriceServer) {
      const reducedFinalPrice = this.$customFunctions.calcReducedFinalFilePrice(filepriceServer, this.newJob.selectedMethods.length, 0);
      const reducedFinalPriceDifference = this.$customFunctions.discountPriceQuantityDifference(filepriceServer, this.newJob.selectedMethods.length, 0);
      this.discountPriceQuantity = reducedFinalPrice;
      this.discountPriceQuantityDifference = reducedFinalPriceDifference;

      console.log("reducedFinalPrice: ", reducedFinalPrice);
      console.log("reducedFinalPriceDifference: ", reducedFinalPriceDifference);

      // wenn der Preis kleiner als 0 ist dann wird der Preis auf 0 gesetzt, andernfalls wird der Preis vom server zurückgegeben
      return reducedFinalPrice > 0 ? reducedFinalPrice : filepriceServer;
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
      userCreditBalance: "loadCreditBalance",
    }),
    $customFunctions() {
      return $customFunctions;
    },
  },
};
</script>
