<template>
  <v-toolbar flat v-intersect="onIntersect">
    <v-toolbar-title>
      <h2>{{ title }}</h2>
    </v-toolbar-title>

    <template v-if="toolbarHasLink">
      <v-spacer></v-spacer>
      <v-btn :to="linkTo" rounded :color="linkColor" dense @click="linkEvent(linkFunc)">
        <v-icon> {{ linkIcon }}</v-icon
        >{{ linkIconText }}
      </v-btn>
    </template>
  </v-toolbar>
</template>
<script>
export default {
  name: "IntersectToolbar",
  props: {
    title: {
      type: String,
      required: true,
      default: "",
    },

    toolbarHasLink: {
      type: Boolean,
      required: false,
      default: false,
    },

    linkIcon: {
      type: String,
      required: false,
      default: "",
    },

    linkIconText: {
      type: String,
      required: false,
      default: "",
    },

    linkTo: {
      type: String,
      required: false,
      default: "",
    },
    linkColor: {
      type: String,
      required: false,
      default: "green accent-4",
    },
    linkFunc: {
      type: function (value) {
        return value;
      },
    },
  },

  data: () => ({
    isIntersecting: false,
  }),

  // wenn die komponente verlassen wird, wird der event emitet damit die topbar wieder zurückgesetzt wird
  beforeDestroy() {
    this.$emit("intersectingData", { isIntersecting: true, title: "" });
  },

  methods: {
    onIntersect(entries) {
      const entry = entries[0];
      this.isIntersecting = entry.isIntersecting;
      this.$emit("intersectingData", { isIntersecting: this.isIntersecting, title: this.title });
    },

    linkEvent() {
      if (!this.linkFunc) return;
      this.$emit("linkFuncEmit", this.linkFunc);
    },
  },
};
</script>
