<template>
  <div>
    <h2>Fahrzeugauswahl</h2>
    <div v-if="newJob.selectedVehicle">
      <h3>{{ newJob.selectedVehicle?.fullName }}</h3>
      <div v-if="currentStep > 1" class="mt-2">
        <div class=""><i>Schritt #1: Abgeschlossen</i></div>
        <v-btn color="amber accent-3" class="mt-2" rounded dense @click="emitStep(1)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </div>
    </div>
    <div v-else>
      Wähle dein Fahrzeug aus um mit der Optimierung zu beginnen
      <div class="mt-2"><i>Schritt #1: Offen</i></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StepperVehicleHeader",
  props: {
    currentStep: {},
    newJob: {},
  },

  methods: {
    emitStep(value) {
      this.$emit("emitStep", value);
    },
  },
};
</script>
