// Store chats im VUEX Store
import axios from "axios";

export default {
  state: {
    chats: [],
    chatData: {},
    chatsOpen: [],
    allChats: [],
    allChatsAdmin: [],
  },
  mutations: {
    CHAT_CREATE(state, payload) {
      state.chats.push(payload);
    },
    CHATS_LOAD(state, payload) {
      state.chats = payload;
    },
    CHATS_OPEN_LOAD(state, payload) {
      state.chatsOpen = payload;
    },
    CHATS_ALL_LOAD(state, payload) {
      state.allChats = payload;
    },
    CHATS_ALL_ADMIN_LOAD(state, payload) {
      state.allChatsAdmin = payload;
    },
  },

  //read our informations from the state and show it anywhere in the app
  getters: {
    loadChats(state) {
      return state.chats;
    },
    loadOpenChats(state) {
      return state.chatsOpen;
    },
    loadAllChats(state) {
      return state.allChats;
    },
    loadAllChatsAdmin(state) {
      return state.allChatsAdmin;
    },
  },
  //actions commit mutations
  actions: {
    // ladet alle Chats vom jeweiligen Job von der Datenbank
    getChatMessagesFromJob({ commit }, payload) {
      // commit("CHATS_LOAD", []);
      return new Promise((resolve) => {
        axios
          .get("api/chats/getChatsByTaskId", {
            params: { task_Id: payload },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.length > 0) {
                const chats = [];
                response.data.forEach((chatData) => {
                  chats.push(chatData);
                });
                commit("CHATS_LOAD", chats);

                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error loading chats data: " + error);
          });
      });
    },
    getAllChatMessagesUnread({ commit }) {
      // commit("CHATS_LOAD", []);
      return new Promise((resolve) => {
        axios
          .get("tf24API/getAllChatMessagesUnread")
          .then((response) => {
            if (response.status === 200) {
              if (response.data.length > 0) {
                const chats = [];
                response.data.forEach((chatData) => {
                  chats.push(chatData);
                });
                commit("CHATS_OPEN_LOAD", chats);

                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error loading chats open data: " + error);
          });
      });
    },
    getAllChatMessagesFromUser({ commit }) {
      return new Promise((resolve) => {
        axios
          .get("tf24API/getAllChatMessagesFromUser")
          .then((response) => {
            if (response.status === 200) {
              if (response.data.length > 0) {
                const chats = [];
                response.data.forEach((chatData) => {
                  chats.push(chatData);
                });
                commit("CHATS_ALL_LOAD", chats);

                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error loading all chats from user data: " + error);
          });
      });
    },
    getAllChatMessagesForAdmin({ commit }) {
      return new Promise((resolve) => {
        axios
          .get("tf24API/getAllChatMessagesForAdmin")
          .then((response) => {
            if (response.status === 200) {
              if (response.data.length > 0) {
                const chats = [];
                response.data.forEach((chatData) => {
                  chats.push(chatData);
                });
                commit("CHATS_ALL_ADMIN_LOAD", chats);

                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error loading all chats Admin from user data: " + error);
          });
      });
    },

    // erstellt einen neuen Chat Eintrag in der Datenbank
    createChatMessage({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/createChatMessage", payload, {
            headers: {
              "Content-Type": "application/json",
              "Content-Disposition": "multipart/form-data",
            },
          })
          .then((response) => {
            commit("CHAT_CREATE", response);
            resolve(true);
          })
          .catch((error) => {
            console.log("error create chat message: " + error);
            console.log(error);
          });
      });
    },
    // schreibt den Status für den Chat um
    updateChatStatus({ _ }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateChatStatus", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error update status chat message: " + error);
            console.log(error);
          });
      });
    },
  },
};
