import axios from "axios";
export default {
  state: {
    tools: [],
    customersTools: [],
  },
  mutations: {
    TOOLS_CREATE(state, payload) {
      state.tools.push(payload);
    },
    TOOLS_LOAD(state, payload) {
      state.tools = payload;
    },
    CUSTOMERS_TOOLS_LOAD(state, payload) {
      state.customersTools = payload;
    },
  },

  //read our informations from the state and show it anywhere in the app
  getters: {
    loadTools(state) {
      return state.tools;
    },
    loadCustomersTools(state) {
      return state.customersTools;
    },
  },
  //actions commit mutations
  actions: {
    getTools({ commit }) {
      return new Promise(resolve => {
        axios
          .get("tf24API/getTools")
          .then(response => {
            commit("TOOLS_LOAD", response.data);
            resolve(true);
          })
          .catch(error => {
            console.log("error loading tools data: " + error);
          });
      });
    },

    createTool({ commit }, payload) {
      return new Promise(resolve => {
        axios
          .post("tf24API/createTool", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(response => {
            commit("TOOLS_CREATE", response);
            resolve(true);
          })
          .catch(error => {
            console.log("error create tool " + error);
            console.log(error);
          });
      });
    },

    updateTools(_, payload) {
      return new Promise(resolve => {
        axios
          .post("tf24API/updateTools", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(response => {
            resolve(true);
          })
          .catch(error => {
            console.log("error update tool " + error);
            console.log(error);
          });
      });
    },

    getToolsForCustomer({ commit }, payload) {
      commit("CUSTOMERS_TOOLS_LOAD", []);

      return new Promise(resolve => {
        var searchCriteria = {
          customer_id: payload.customer_id,
        };
        axios
          .get("tf24API/getToolsForCustomer", {
            params: searchCriteria,
          })
          .then(response => {
            const tools = [];
            response.data.forEach(userTool => {
              tools.push(userTool);
            });
            commit("CUSTOMERS_TOOLS_LOAD", tools);
            resolve();
          })
          .catch(error => {
            console.log("error loading getToolsForCustomer data: " + error);
          });
      });
    },

    createToolForCustomer(_, payload) {
      return new Promise(resolve => {
        axios
          .post("tf24API/createToolForCustomer", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            }
          })
          .catch(error => {
            console.log("error createToolForCustomer tool " + error);
            console.log(error);
          });
      });
    },

    updateToolForCustomer(_, payload) {
      return new Promise(resolve => {
        axios
          .post("tf24API/updateToolForCustomer", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(response => {
            resolve(true);
          })
          .catch(error => {
            console.log("error updateToolForCustomer " + error);
            console.log(error);
          });
      });
    },

    deleteToolFromCustomerWithID(_, payload) {
      return new Promise(resolve => {
        axios
          .post("tf24API/deleteToolFromCustomerWithID", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            }
          })
          .catch(error => {
            console.log("error deleteToolFromCustomer" + error);
            console.log(error);
          });
      });
    },
  },
};
