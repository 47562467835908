<template>
  <div v-if="userAccessData">
    <v-card color="" outlined shaped class="">
      <!--      <v-card elevation="0" ref="sidebarRight" width="auto">-->
      <!--      <v-toolbar flat dark :class="$vuetify.theme.dark ? 'primary primary&#45;&#45;text' : 'white primary&#45;&#45;text'">-->
      <!--        <v-toolbar-title>-->
      <!--          <v-chip color="primary"-->
      <!--            ><h2 class="">Credits: {{ loadCreditBalance }}Cr.</h2></v-chip-->
      <!--          >-->
      <!--        </v-toolbar-title>-->
      <!--      </v-toolbar>-->

      <v-alert :to="generatePath('account/buyCredits')" color="primary" prominent dense text shaped class="mt-3 mb-2 ml-2 mr-2" dark icon="mdi-information-outline">
        <router-link :to="generatePath('account/buyCredits')" replace>
          <h3 class="">Credits: {{ loadCreditBalance }}</h3>
        </router-link>
      </v-alert>
      <!--      </router-link>-->
      <v-card-text>
        <div class="">
          <v-row>
            <v-col cols="12" sm="6">
              <router-link :to="generatePath('account/dashboard')" replace>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn rounded outlined color="primary" class="" small v-on="on">
                      Dashboard
                      <v-icon color="red accent-4" class="ml-1">mdi-view-dashboard-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Dashboard</span>
                </v-tooltip>
              </router-link>
              <router-link :to="generatePath('jobs/new')" replace>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn rounded outlined color="primary" class="mt-2" small v-on="on">
                      {{ $t("jobCreateText_1") }}
                      <v-icon color="green accent-4" class="ml-1">mdi-movie-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("jobCreateText_1") }}</span>
                </v-tooltip>
              </router-link>

              <router-link :to="generatePath('account/buyCredits')" replace>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn rounded outlined color="primary" class="mt-2" small v-on="on">
                      {{ $t("creditsBuy") }}
                      <v-icon color="amber accent-4" class="ml-1">mdi-credit-card-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("creditsBuy") }}</span>
                </v-tooltip>
              </router-link>
            </v-col>
          </v-row>
        </div>

        <v-divider class="mt-3 mb-2"></v-divider>

        <div>
          <div class="">
            <strong>Serverzeit (UTC): <br /></strong>{{ serverTime }}<br />
          </div>
          <div class="mt-2">
            <strong>Deine Zeit: <br /></strong>{{ browserTime }}
          </div>
        </div>
        <v-divider class="mt-3 mb-4"></v-divider>

        <v-tooltip v-for="item in listItems" :key="item.id" bottom max-width="300">
          <template v-slot:activator="{ on }">
            <div v-on="on" class="mt-1">
              <v-icon :color="getStatusColor(item.status)" class="">{{ item.icon }}</v-icon>

              <strong class="ml-1">({{ item.status }}) - {{ item.title }}</strong>
            </div>
          </template>
          <span>{{ item.informationText }}</span>
        </v-tooltip>

        <v-divider class="mt-3 mb-4"></v-divider>
        <h5>News</h5>
        <p>Version 4 ist Online</p>
        <strong>Neue Entwicklungen in der Softwarebranche</strong>
        <p>Informationen werden geladen...</p>
        <div class="d-flex">
          <div class="mr-2">
            <DayNightSwitch />
          </div>
          <div>
            <Language></Language>
          </div>
        </div>
      </v-card-text>
      <!--      </v-card>-->
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import Language from "@/components/ui/Language.vue";
import DayNightSwitch from "@/components/ui/DayNightSwitch.vue";

export default {
  name: "SideBarRight",
  components: { DayNightSwitch, Language },

  data: () => ({
    openSidebarRight: true,
    selectedItem: null,
    sidebarScrolled: false,
    serverTime: "",
    browserTime: "",
    listItems: [
      {
        id: 1,
        title: "AI Automatic",
        animation: true,
        icon: "mdi-cog-clockwise",
        status: "online",
        informationText: "Status of the AI automatic. Runs 24/7. if red, please contact the portal admin for further informations",
      },
      {
        id: 2,
        title: "Support",
        animation: false,
        icon: "mdi-headphones-settings",
        status: "offline",
        informationText: "Please check the current time of your timezone and from the portal if available",
      },
    ],
    iconRefs: {}, // Neues Objekt zum Speichern der Referenzen
  }),

  mounted() {
    this.getServerTime();
    this.getBrowserTime();

    // Starte die Aktualisierung im Intervall von 1 Sekunde
    setInterval(() => {
      this.getServerTime();
      this.getBrowserTime();
    }, 1000);
  },
  beforeRouteLeave(to, from, next) {},

  created() {},

  methods: {
    generatePath(path) {
      const baseUrl = "/";
      return `${baseUrl}${path}`;
    },
    getServerTime() {
      // Hier kannst du die UTC-Zeitzone des Servers festlegen
      const serverTimezone = "UTC";
      this.serverTime = moment().tz(serverTimezone).format("YYYY-MM-DD HH:mm:ss");
    },
    getBrowserTime() {
      // Hier wird die Zeitzone des Browsers verwendet
      this.browserTime = moment().format("YYYY-MM-DD HH:mm:ss");
    },
    getStatusColor(status) {
      return status === "online" ? "green accent-4" : "red accent-4";
    },
  },

  watch: {},

  computed: {
    ...mapGetters({
      authenticated: "user",
      userAccessData: "userAccessData",
      alertUpdateMessage: "alertUpdateMessage",
      loadCreditBalance: "loadCreditBalance",
    }),
  },
};
</script>
