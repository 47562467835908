// Rechteverwaltung für die Bereiche der Anwendung
import store from "@/store";

export const rightsFunctions = {
  /** 
    prüft ob der user die benötigte rechte hat um auf das modul zuzugreifen
    * @param {number} module - the module to check
    * @returns {boolean} - true or false
    */
  moduleAccessChecker(module) {
    const userAccessData = store.getters.userAccessData;
    if (module === undefined || userAccessData === undefined) {
      return false;
    }

    const config = JSON.parse(userAccessData.data.module_config);

    // console.log("moduleAccessChecker", config);
    if (config === undefined || config.length === 0) {
      return false;
    }

    return config.some((data) => {
      if (data === null) {
        return false;
      }

      // console.log("data", data);

      return data.module_id === module && data.value === true;
    });
  },
};
