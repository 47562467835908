import axios from "axios";
export default {
  state: {
    filePricesStandardMain: [],
    filePricesStandardAddon: [],
    filePricesCustomer: [],
    // priceData: {},
  },
  mutations: {
    FILE_PRICES_STANDARD_MAIN_CREATE(state, payload) {
      state.filePricesStandardMain.push(payload);
    },
    FILE_PRICES_STANDARD_MAIN_LOAD(state, payload) {
      state.filePricesStandardMain = payload;
    },
    FILE_PRICES_STANDARD_ADDON_CREATE(state, payload) {
      state.filePricesStandardAddon.push(payload);
    },
    FILE_PRICES_STANDARD_ADDON_LOAD(state, payload) {
      state.filePricesStandardAddon = payload;
    },
    FILE_PRICES_CUSTOMER_LOAD(state, payload) {
      state.filePricesCustomer = payload;
    },
    FILEPRICES_MAIN_LOAD(state, payload) {
      state.filePricesMain = payload;
    },
    FILEPRICES_ADDON_LOAD(state, payload) {
      state.filePricesAddon = payload;
    },
  },

  //read our informations from the state and show it anywhere in the app
  getters: {
    loadFilePricesStandardMain(state) {
      return state.filePricesStandardMain;
    },
    loadFilePricesStandardAddon(state) {
      return state.filePricesStandardAddon;
    },
    loadFilePricesCustomer(state) {
      return state.filePricesCustomer;
    },
    loadFilePricesMain(state) {
      return state.filePricesMain;
    },
    loadFilePricesAddon(state) {
      return state.filePricesAddon;
    },
  },
  //actions commit mutations
  actions: {
    getFilePricesStandardMain({ commit }) {
      return new Promise((resolve) => {
        axios
          .get("tf24API/getFilePricesStandardMain")
          .then((response) => {
            commit("FILE_PRICES_STANDARD_MAIN_LOAD", response.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading filePricesStandardMain data: " + error);
          });
      });
    },

    // erstellt einen neuen StandardFilePrice Eintrag in der Datenbank
    createFilePriceStandardMain({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/createFilePriceStandardMain", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            commit("FILE_PRICES_STANDARD_MAIN_CREATE", response);
            resolve(true);
          })
          .catch((error) => {
            console.log("error create price " + error);
            console.log(error);
          });
      });
    },

    updateFilePricesStandardMain(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateFilePricesStandardMain", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error update price " + error);
            console.log(error);
          });
      });
    },
    //**************************************************************** */
    // ADDON FILE PREISE
    //**************************************************************** */
    getFilePricesStandardAddon({ commit }) {
      return new Promise((resolve) => {
        axios
          .get("tf24API/getFilePricesStandardAddon")
          .then((response) => {
            commit("FILE_PRICES_STANDARD_ADDON_LOAD", response.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading addon price data: " + error);
          });
      });
    },

    // erstellt einen neuen AddonFilePrice Eintrag in der Datenbank
    createFilePriceStandardAddon({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/createFilePriceStandardAddon", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            commit("FILE_PRICES_STANDARD_ADDON_CREATE", response);
            resolve(true);
          })
          .catch((error) => {
            console.log("error create addon price " + error);
            console.log(error);
          });
      });
    },

    updateFilePricesStandardAddon(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateFilePricesStandardAddon", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error update addon price " + error);
            console.log(error);
          });
      });
    },

    createCustomMainFilePrice(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/createCustomMainFilePrice", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error create main custom price " + error);
            console.log(error);
          });
      });
    },

    createCustomAddonFilePrice(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/createCustomAddonFilePrice", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error create addon custom price " + error);
            console.log(error);
          });
      });
    },

    // GIBT DIE CUSTOM FILEPREISE FÜR EINEN KUNDEN ZURÜCK
    getFilePricesCustomForCustomer({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .get("tf24API/getFilePricesCustomForCustomer", {
            params: { userID: payload },
          })
          .then((response) => {
            commit("FILE_PRICES_CUSTOMER_LOAD", response.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading custom fileprices for customer: " + error);
          });
      });
    },

    updateCustomerFlatPriceSetting(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateCustomerFlatPriceSetting", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error update flatprice price " + error);
            console.log(error);
          });
      });
    },

    // UPDATED DIE MAIN KUNDENPREISE
    updateCustomerMainPrices(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateCustomerMainPrices", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error update addon price customer" + error);
            console.log(error);
          });
      });
    },

    // UPDATED DIE ADDON KUNDENPREISE
    updateCustomerAddonPrices(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateCustomerAddonPrices", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error update addon price customer" + error);
            console.log(error);
          });
      });
    },
    deleteCustomPrice(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/deleteCustomPrice", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error delete custom price for customer" + error);
            console.log(error);
          });
      });
    },
    loadFilePricesMain({ commit }) {
      return new Promise((resolve) => {
        axios
          .get("tf24API/loadFilePricesMain", {})
          .then((response) => {
            commit("FILEPRICES_MAIN_LOAD", response.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading prices: " + error);
          });
      });
    },
    loadFilePricesAddon({ commit }) {
      return new Promise((resolve) => {
        axios
          .get("tf24API/loadFilePricesAddon", {})
          .then((response) => {
            commit("FILEPRICES_ADDON_LOAD", response.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading prices: " + error);
          });
      });
    },

    loadCurrentFilePrice(_, payload) {
      return new Promise((resolve) => {
        const data = {
          selectedMethodIds: payload.methods.map((item) => item.method_id),
        };
        console.log("loadCurrentFilePrice", payload)
        console.log(data);

        axios
          .post("api/fileprices/calculate", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("error loading CurrentFilePrice : " + error);
          });
      });
    },
  },
};
