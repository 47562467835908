import axios from "axios";
export default {
  state: {
    methods: [],
    filePricesMain: [],
    filePricesAddon: [],
  },
  mutations: {
    METHODS_LOAD(state, payload) {
      state.methods = payload;
    },
  },
  getters: {
    loadMethods(state) {
      return state.methods;
    },
  },

  actions: {
    // lade alle Methoden die zum Fahrzeug passen (uid id vom Fahrzeug)
    loadMethods({ commit }, payload) {
      commit("SHOW_TABLE_LOADING_INDICATOR_METHOD", true);
      return new Promise(resolve => {
        var searchCriteria = {
          vehicle_id: payload,
        };
        axios
          .get("api/getMethods", {
            params: searchCriteria,
          })
          .then(response => {
            commit("SHOW_TABLE_LOADING_INDICATOR_METHOD", false);
            commit("METHODS_LOAD", response.data);
            resolve();
          })
          .catch(error => {
            commit("SHOW_TABLE_LOADING_INDICATOR_METHOD", false);
            console.log("error loading methods logic data: " + error);
          });
      });
    },
  },
};
