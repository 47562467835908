// Rechteverwaltung für die Bereiche der Anwendung
// UNTERTEILT IN AREAS:
export const moduleRights = [
  { module_id: 0, EDITOR_MODE: 0, value: false, text: "Editor Modus" },
  { module_id: 1, METHOD_SHOW: 1, value: false, text: "Methoden anzeigen" },
  { module_id: 2, RULES_SHOW: 2, value: false, text: "Regeln anzeigen" },
  { module_id: 3, SUBRULES_SHOW: 3, value: false, text: "Sub Regeln anzeigen" },
  { module_id: 4, TUNE_VEHICLES_MODE: 4, value: false, text: "Fahrzeuge Tunen" },
  { module_id: 5, TUNE_ECUS_MODE: 5, value: false, text: "ECUs Tunen" },
  { module_id: 6, VEHICLE_MANAGEMENT: 6, value: false, text: "Fahrzeuge verwalten" },
  { module_id: 7, ECUM_ANAGEMENT: 7, value: false, text: "ECUs verwalten" },
  { module_id: 8, TUNE_CHECKSUM_SELECTION: 8, value: false, text: "Mit Checksumme Tunen" },
  { module_id: 9, LOAD_OLD_TASKS: 9, value: false, text: "Alte Tasks Laden" },
  { module_id: 10, TUNE_ASSIST_MODE: 10, value: false, text: "Tune mit Assist Modus" },
  { module_id: 11, TUNE_WITH_DTCS: 11, value: false, text: "Mit DTCs Tunen" },
  { module_id: 12, TUNE_MULTIPLE_METHODS: 12, value: false, text: "Mehrfache Methoden auswahl" },
  { module_id: 13, METHOD_INFORMATIONS: 13, value: false, text: "Methoden Informationen anzeigen" },
  { module_id: 14, BLUEPRINT_CREATE: 14, value: false, text: "Blueprint erstellen" },
  { module_id: 15, BLUEPRINT_LOAD: 15, value: false, text: "Blueprint laden" },
];
