<template>
  <div class="mt-2" v-if="newJob.selectedMethods.length > 0 && !switchMyMethodNotListed">
    <small v-if="newJob.selectedMethods.length === 2"> <strong>Eine weitere Methoden auswählen und Mengenrabatt sichern!</strong></small>

    <v-list subheader two-line>
      <!-- Regulärer Preis -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Regulärer Preis</v-list-item-title>
          <v-list-item-subtitle>Der ursprüngliche Preis ohne Rabatt</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-chip color="primary" :class="'white--text'"> {{ filePriceCurrent }} {{ $t("credits") }}</v-chip>
        </v-list-item-action>
      </v-list-item>

      <!-- Mengenrabatt -->
      <v-list-item v-if="discountPriceQuantity > 0">
        <v-list-item-content>
          <v-list-item-title>Mengenrabatt</v-list-item-title>
          <v-list-item-subtitle>Ein Rabatt basierend auf der gekauften Menge</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-chip color="red accent-4" :class="'white--text'"> - {{ discountPriceQuantityDifference }} {{ $t("credits") }} </v-chip>
        </v-list-item-action>
      </v-list-item>

      <!-- Finaler Preis -->
      <v-list-item v-if="discountPriceQuantity > 0">
        <v-list-item-content>
          <v-list-item-title>Finaler Preis</v-list-item-title>
          <v-list-item-subtitle>Der endgültige Preis nach Anwendung des Rabatts</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-chip color="green accent-4" :class="'black--text'">{{ discountPriceQuantityDifference }} {{ $t("credits") }}</v-chip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  name: "FilePriceList",
  props: {
    discountPriceQuantity: {},
    discountPriceQuantityDifference: {},
    filePriceCurrent: {},
    newJob: {},
    switchMyMethodNotListed: {},
  },
};
</script>
