<template>
  <div class="locale-changer">
    <template>
      <v-menu offset-y>
        <template #activator="{ on }">
          <v-avatar color="" size="2rem" v-on="on">
            <img :src="require(`@/assets/icons/icon_flag_${$i18n.locale}.svg`)" alt="icon-flaggs-language" />
          </v-avatar>
        </template>

        <v-list>
          <v-list-item v-for="(lang, i) in langs" :key="`Lang${i}`" class="text-center" @click="switchLanguage(lang)">
            <v-img :src="require(`@/assets/icons/icon_flag_${lang}.svg`)" height="35" class="mr-1"></v-img>
            <span class="text-uppercase">{{ lang }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>

<script>
export default {
  name: "language",
  data: function () {
    return {
      //langs: ["de", "en", "it", "sp", "pl", "fr", "ru", "nl"],
      langs: ["de", "en"],
    };
  },

  mounted() {
    if (localStorage.lang) {
      this.$i18n.locale = JSON.parse(localStorage.lang);
    }
  },

  methods: {
    switchLanguage(lang) {
      localStorage.setItem("lang", JSON.stringify(lang));
      this.$i18n.locale = lang;
    },
  },

  props: {},

  computed: {},
};
</script>
