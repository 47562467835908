<template>
  <div id="taskTicket">
    {{ openDialog }}
    <v-dialog v-model="openDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Ticket Task: #{{ task.task_id }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-responsive class="overflow-y-hidden fill-height" max-height="700px">
            <v-card flat class="d-flex flex-column fill-height">
              <v-card-title>
                <v-row justify="space-between">
                  <v-col cols="12">
                    <!--            task: {{ task }} messages: {{ messages.length }}-->
                    <!--            ticket erstellen wenn messages vom Task X nicht vorhanden sind-->

                    <!--                    {{ messages }}-->

                    <div v-if="messages.length === 0" class="text-center">
                      <v-icon x-large class="mb-4" color="amber accent-3">mdi-note-search</v-icon>
                      <h3 class="">Ticket erstellen</h3>

                      <small>Noch kein Ticket für den Task #{{ task.task_id }} erstellt. Klicke auf den Button um den Support zu kontaktieren.</small>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>

              <div class="message-container" ref="scrollContainer">
                <div v-for="(msg, chatIndex) in filteredMessages" :key="chatIndex">
                  <div :class="{ 'd-flex flex-row-reverse': msg.chat_owner }">
                    <v-card shaped :color="getMessageColor(msg)" style="max-width: 60%" class="pa-4 mb-2 messageBox">
                      <v-row>
                        <v-col cols="12" sm="2" align-self="center">
                          <v-icon v-if="msg.chat_isIcon" size="30">{{ msg.chat_avatar }}</v-icon>
                          <v-avatar v-else dark class="mr-4" color="amber accent-3" size="30">
                            <span>{{ msg.chat_avatar }} </span>
                          </v-avatar>
                        </v-col>
                        <v-col cols="12" sm="10">
                          <div class="d-flex flex-column">
                            <span>{{ msg.chat_text }}</span>

                            <div v-if="msg.hasAttachment">
                              <v-img
                                v-if="isAllowedPhotoExtension(msg.chat_attachmentPath)"
                                :lazy-src="msg.chat_attachmentPath"
                                max-height="150"
                                max-width="250"
                                :src="msg.chat_attachmentPath"></v-img>
                              <!--TODO:: andere filetypen erlauben-->
                              <DownloadFile
                                v-else-if="msg.chat_attachmentType === '.bin'"
                                :task-data="{}"
                                :show-text="true"
                                :show-icon="true"
                                icon-size="1.2rem"
                                icon-color="white"
                                :btnShowTextShort="false"
                                btnTextLong="Modified"
                                btnTextShort="Mod"
                                :btnOutlined="false"
                                btnColor="primary"
                                btnColorText="white--text"
                                downloadType="mod" />
                            </div>

                            <div class="d-flex justify-end">
                              <div class="mr-2">
                                <sub class="mt-3">{{ msg.chat_message_created }}</sub>
                              </div>
                              <template v-if="msg.chat_user === 'tuner'">
                                <div class="d-flex">
                                  <v-icon v-if="msg.chat_support_status === 'read'" color="blue">mdi-check-all</v-icon>
                                  <v-icon v-else color="grey">mdi-check-all</v-icon>
                                </div>
                              </template>

                              <template v-if="msg.chat_user === 'support'">
                                <div class="d-flex">
                                  <v-icon v-if="msg.chat_customer_status === 'read'" color="blue">mdi-check-all</v-icon>
                                  <v-icon v-else color="grey">mdi-check-all</v-icon>
                                </div>
                              </template>
                            </div>
                          </div>
                        </v-col>
                      </v-row>

                      <!--                            <v-icon v-if="showMessageAction" small> expand_more</v-icon>-->
                    </v-card>
                  </div>
                </div>
              </div>
              <v-card-text class="flex-shrink-1">
                <v-row align="center" justify="end" class="">
                  <v-col cols="12" v-if="messages.length > 0">
                    <v-btn rounded small color="primary" @click="toggleSearchField">
                      <v-icon color="white">{{ searchFieldVisible ? "mdi-close" : "mdi-magnify" }}</v-icon> Suche
                    </v-btn>
                    <transition name="slide-x">
                      <v-text-field v-model="searchQuery" hide-details v-if="searchFieldVisible" class="mt-2" dense label="Suche nach Nachrichten" outlined clearable>
                        <template v-slot:prepend-inner>
                          <v-icon>mdi-magnify</v-icon>
                        </template>
                      </v-text-field>
                    </transition>
                  </v-col>
                </v-row>

                <v-row class="">
                  <v-col>
                    <v-textarea v-model="newMessageInput" label="Deine Nachricht" type="text" no-details rows="4" outlined @keyup.enter="newMessage" hide-details />
                  </v-col>
                  <v-col>
                    <div>
                      <!--                      <div class="">Attachment</div>-->
                      <!-- TODO: FILEINPUT wird geleert wenn ich einen Schritt zurück gehe -->
                      <DragAndDrop ref="refDragDrop" @fileToUpload="setFileUpload"></DragAndDrop>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" class="mt-2" v-if="messages.length > 0" :disabled="newMessageInput === ''" rounded @click="newMessage()">Nachricht senden </v-btn>
                <v-btn color="primary" class="mt-2" v-else rounded :disabled="newMessageInput === ''" @click="createTicket(task)">Support Ticket erstellen </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="primary" outlined class="mt-2" rounded @click="closeDialog()">Schließen</v-btn>
              </v-card-actions>
            </v-card>
          </v-responsive>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import DownloadFile from "@/components/tasks/tableItems/fileDownload/DownloadFile.vue";
import DragAndDrop from "@/components/ui/DragAndDrop.vue";
import { customFunctions as $customFunctions } from "@/customFunctions";
import { mapGetters } from "vuex";

export default {
  name: "TaskTicketDialog",
  components: { DownloadFile, DragAndDrop },
  props: {
    jobDetailsData: Object,
    task: Object,
    openDialog: Boolean,
  },
  data() {
    return {
      newMessageInput: "",
      allowedPhotoExtensions: [".png", ".jpg", ".jpeg", ".gif"],
      messages: [],
      searchQuery: "", // Suchbegriff für die Suche nach Nachrichten
      searchFieldVisible: false, // Steuert die Sichtbarkeit des Suchfelds
    };
  },

  mounted() {},
  updated() {},

  watch: {},

  methods: {
    isAllowedPhotoExtension(filename) {
      const allowedPhotoExtensions = this.allowedPhotoExtensions;
      const extension = filename.substring(filename.lastIndexOf("."));
      return allowedPhotoExtensions.includes(extension.toLowerCase());
    },

    closeDialog() {
      this.$emit("closeDialog");
    },

    replayToMessage(msg) {
      console.log("replay", msg);

      this.newMessageInput = "Antwort zu '" + msg.chat_text + "'\n";
    },

    // Bestimmt die Card Farbe
    getMessageColor(message) {
      if (message.chat_owner) {
        return "primary white--text";
      } else return "amber acccent-3";
    },

    getMethodIcons(method) {
      return $customFunctions.getMethodIcons(method);
    },

    async getChats(task_id) {
      const response = await this.axios.get("/api/tasks/" + task_id + "/chats");
      return response.status === 200 && response.data.code === 120 ? response.data.data : [];
    },

    loadChats() {
      let task_id = this.task.task_id;

      try {
        this.getChats(task_id).then((response) => {
          this.openTicketDialog = true;

          // Chats vom backend laden
          response.forEach((chat) => {
            const ownMessage = chat.chat_user_id === this.userAccessData.data.user_id;
            const avatarName = $customFunctions.avatarName(this.userAccessData);
            const showIcon = avatarName === "" || !ownMessage;
            this.messages.push({
              chatData: chat,
              chat_user: chat.chat_user,
              chat_customer_status: chat.chat_customer_status,
              chat_support_status: chat.chat_support_status,
              chat_id: "m" + chat.chat_id,
              chat_text: chat.chat_text,
              chat_owner: ownMessage,
              chat_message_created: this.$customFunctions.dateFormatUSWithTime(chat.chat_create_date),
              chat_message_created_date: this.$customFunctions.dateFormatUSWithTime(chat.chat_create_date),
              chat_avatar: ownMessage ? avatarName : "mdi-face-agent",
              chat_isIcon: showIcon,
              chat_color: ownMessage ? "primary" : "",
            });
          });

          this.scrollToBottom();
        });
      } catch (e) {
        console.log(e);
      }
    },

    newMessage() {
      if (!this.newMessageInput) {
        return;
      }

      this.postChat(this.newMessageInput).then(async () => {
        this.newMessageInput = "";
        await this.loadChats();

        // Scrollen zur letzten Nachricht
        this.scrollToBottom();
      });
      this.newMessageInput = "";
    },
    scrollToBottom() {
      this.$nextTick(() => {
        let container = this.$refs.scrollContainer;
        container.scrollTop = container.scrollHeight;
      });
    },

    async postChat(message) {
      const response = await this.axios.post("/api/tasks/" + this.task.task_id + "/chat", {
        job_id: this.task.task_job_id,
        task_id: this.task.task_id,
        chat_text: message,
        user_type: "tuner",
        file_path: "",
        file_type: "",
        file_name: "",
      });
      return response.status === 200 && response.data.code === 120 ? response.data.data : [];
    },

    formatDate(time) {
      return time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
        return ` ${contents
          .split(" ")
          .map((v) => v.charAt(0))
          .join("")}`;
      });
    },

    setFileUpload(file) {},

    showImageControl(message) {
      return message.attachmentType === "png" || message.attachmentType === "jpg" || message.attachmentType === "jpeg";
    },

    showButtonControl(message) {
      return message.attachmentType === "bin" || message.attachmentType === ".bin";
    },

    showMessageAction(message) {
      return !this.showButtonControl(message);
    },

    getMethods(task) {
      return $customFunctions.getMethodsFromTaskModifications(task);
    },

    toggleSearchField() {
      this.searchFieldVisible = !this.searchFieldVisible;
      if (!this.searchFieldVisible) {
        this.searchQuery = ""; // Setze den Suchbegriff zurück, wenn das Suchfeld geschlossen wird
      }
    },
  },
  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),

    $customFunctions() {
      return $customFunctions;
    },

    filteredMessages() {
      if (this.searchQuery.trim() !== "") {
        // Filtern der Nachrichten basierend auf dem Suchbegriff
        return this.messages.filter((msg) => msg.chat_text.toLowerCase().includes(this.searchQuery.toLowerCase()));
      } else {
        // Wenn kein Suchbegriff vorhanden ist, gib alle Nachrichten zurück
        return this.messages;
      }
    },
  },
};
</script>

<style scoped>
.message-container {
  overflow-y: scroll;
  max-height: 500px;
  position: relative;
  overflow-x: hidden;

  /* Andere Styles */
}

.slide-x-enter-active,
.slide-x-leave-active {
  transition: transform 0.3s;
}

.slide-x-enter {
  transform: translateX(100%);
}

.slide-x-leave-to {
  transform: translateX(100%);
}
</style>
