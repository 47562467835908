<template>
  <v-badge
    v-if="item.task_type === 'reviewDone'"
    :content="item.task_type === 'reviewDone' ? '1' : ''"
    :value="item.task_type"
    :color="item.task_type === 'reviewDone' ? 'red accent-4' : ''"
    overlap
    bordered
    :offset-x="offsetX"
    :offset-y="offsetY">
  </v-badge>
</template>

<script>
export default {
  name: "TaskTicketBadgeCounter",
  props: {
    item: {},
    offsetX: {
      type: Number,
      default: 8,
    },
    offsetY: {
      type: Number,
      default: -5,
    },
  },
};
</script>
