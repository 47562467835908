<template>
  <div id="NewJobView">
    <v-container fluid>
      <v-row>
        <v-col cols="12" lg="9" xl="10" class="">
          <v-card elevation="0">
            <IntersectToolbar @intersectingData="($event) => $emit('intersectingData', $event)" :title="$t('jobCreateText_1')" />
            <v-card-text>
              <NewJob></NewJob>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" lg="3" xl="2" :class="$vuetify.breakpoint.lgAndUp ? 'sidebarRight' : ''">
          <SideBarRight></SideBarRight>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NewJob from "@/components/jobs/createJob/NewJob.vue";
import SideBarRight from "@/components/ui/SidebarRight.vue";
import IntersectToolbar from "@/components/ui/IntersectToolbar.vue";

export default {
  name: "NewJobView",
  components: {
    IntersectToolbar,
    SideBarRight,
    NewJob,
  },
};
</script>
