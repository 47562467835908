<template>
  <div id="jobsTable">
    <DialogTaskMessages v-if="selectedJobData" :taskData="selectedJobData" :dialog="dialogJobMessages" @close-dialog-task-messages="setCloseDialogJobMessages"></DialogTaskMessages>
    <v-data-table
      mobile-breakpoint="md"
      v-model="selectedJobFromTableItem"
      :headers="tasksTableField"
      :items="jobs"
      :single-select="true"
      item-key="job_id"
      checkbox-color="primary"
      :hide-default-footer="false"
      :items-per-page="10"
      :search="searchQuery"
      :custom-filter="customFilter">
      <template v-slot:item.job_status="{ item }">
        <div>
          <v-chip small color="primary" outlined class="mt-1"
            ><i>{{ item.job_status }}</i>
            <v-icon class="ml-1">{{ $customFunctions.job_status_icon(item).icon }}</v-icon>
          </v-chip>
        </div>
      </template>

      <template v-slot:item.job_info="{ item }">
        <h3 class="pb-0">
          {{ item.vehicle_brand }} -
          <span class=""
            ><i class=""> {{ item.vehicle_category }}</i></span
          >
        </h3>
        <i>{{ item.vehicle_model }}</i> -
        <i> VIN: {{ item.job_vehicle_VIN }}<br /></i>

        <div class="d-flex mt-0">
          <v-chip-group column>
            <v-chip color="" outlined small>
              <v-icon color="primary" class="mr-2">mdi-engine</v-icon>
              {{ item.vehicle_engineCCm }}L {{ item.vehicle_enginePS }}HP
            </v-chip>
            <v-chip color="" outlined small>
              <v-icon color="primary" class="mr-2">mdi-poll</v-icon>
              {{ item.vehicle_emission }}
            </v-chip>
            <v-chip color="" outlined small>
              <v-icon color="primary " class="mr-2">mdi-cpu-64-bit</v-icon>
              {{ item.vehicle_ecuBrand }} {{ item.vehicle_ecuType }}
            </v-chip>
          </v-chip-group>
        </div>
      </template>
      <template v-slot:item.job_info_2="{ item }">
        <div class="d-flex">
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip, attrs }">
                <v-btn
                  v-if="item.job_tasks_count > 0"
                  color="primary"
                  v-bind="attrs"
                  v-on="{ ...tooltip }"
                  elevation="1"
                  icon
                  outlined
                  dense
                  class="tooltip-action-btn ml-2"
                  @click="goToJobDetails(item)">
                  <v-badge :content="item.job_tasks_count" overlap bordered offset-x="10" offset-y="3">
                    <v-icon small>mdi-open-in-new</v-icon>
                  </v-badge>
                </v-btn>
              </template>
              <span>Fahrzeug öffnen - (Tasks: {{ item.job_tasks_count }})</span>
            </v-tooltip>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import DialogTaskMessages from "@/components/theme/dialogs/DialogTaskMessages.vue";
import DialogTaskList from "@/components/theme/dialogs/DialogTaskList.vue";
import axios from "axios";
import router from "@/router";

export default {
  name: "JobsTable",
  components: { DialogTaskList, DialogTaskMessages },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },

    jobs: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    selectedJobData: {},
    selectedTaskData: [],
    dialogTaskList: false,
    selectedJobFromTableItem: [],
    expandedRows: [],
    tasksTableField: [
      { value: "job_info", text: "Fahrzeugdaten" },
      // { value: "job_status", text: "Status" },
      // { value: "job_info_3", text: "" },
      { value: "job_info_2", text: "" },
    ],
    dialogJobMessages: false,
  }),

  watch: {
    // ToDo: muessen wir hier einen raum joinen?
    selectedJobFromTableItem: function (newVal, oldVal) {},
  },

  methods: {
    customFilter(value, search, item) {
      search = search.toLowerCase();
      const searchableProperties = [
        "job_vehicle_VIN",
        "vehicle_brand",
        "vehicle_model",
        "vehicle_category",
        "vehicle_engineCCm",
        "vehicle_enginePS",
        "vehicle_emission",
        "vehicle_ecuBrand",
        "vehicle_ecuType",
      ];

      return searchableProperties.some((prop) => String(item[prop]).toLowerCase().includes(search));
    },

    setCloseDialogJobMessages() {
      this.dialogJobMessages = false;
    },

    openJobMessageDialog(item) {
      this.selectedJobData = item;

      this.dialogJobMessages = true;
    },
    goToJobDetails(item) {
      router.push("/jobs/" + item.job_id);
    },
  },

  computed: {},
};
</script>

<!--<style scoped lang="scss">-->
<!--.tooltip-action-btn {-->
<!--  margin-left: 5px;-->
<!--}-->
<!--</style>-->
