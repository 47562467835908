import axios from "axios";
export default {
  state: {
    countries: [],
    states: [],
  },
  mutations: {
    COUNTRIES_LOAD(state, payload) {
      state.countries = payload;
    },
    CLEAR_COUNTRIES(state) {
      state.countries = [];
    },
    COUNTRY_STATES_LOAD(state, payload) {
      state.states = payload;
    },
  },

  //read our informations from the state and show it anywhere in the app
  getters: {
    loadCountries(state) {
      return state.countries;
    },
    loadCountryStates(state) {
      return state.states;
    },
  },
  //actions commit mutations
  actions: {
    getCountries({ commit }) {
      console.log("get countries");
      return new Promise((resolve) => {
        commit("CLEAR_COUNTRIES");

        axios
          .get("api/country/getAll")
          .then((response) => {
            console.log("got countries with response: ", response.data.data);
            commit("COUNTRIES_LOAD", response.data.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading countries data: " + error);
          });
      });
    },

    getCountryStates({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .get(`api/country/getStates?country_id=${payload}`)
          .then((response) => {
            commit("COUNTRY_STATES_LOAD", response.data.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading country states data: " + error);
          });
      });
    },

    getCountryAreaID({ _ }, payload) {
      return new Promise((resolve) => {
        axios
          .get("tf24API/getCountryAreaID", { params: payload })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log("error getting area ID from country: " + error);
          });
      });
    },
  },
};
