//------------------------------------------------------------------------
//	IMPORTS für die Auth Routen
//------------------------------------------------------------------------
import store from "@/store";

export default [
  {
    path: "/signin",
    name: "Signin",
    component: () => import("@/views/Account/Auth/SignIn.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        next();
      }
    },
  },
  {
    path: "/signUp",
    name: "SignUp",
    component: () => import("@/views/Account/Auth/SignUp.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next();
      } else {
        if (store.getters["userAccessData"].data.user_active != "active") {
          return next("/notActive");
        } else {
          next();
        }
      }
    },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/Account/Auth/Logout.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next("/signin");
      } else {
        if (store.getters["userAccessData"].data.user_active != "active") {
          return next("/notActive");
        } else {
          next();
        }
      }
    },
  },

  {
    path: "/resetpassword",
    name: "PasswordReset",
    component: () => import("@/views/Account/Auth/PasswordReset.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        next();
      } else {
        return next("/");
      }
    },
  },

  {
    path: "/notActive",
    name: "notActive",
    component: () => import("@/views/Account/Auth/NotActive.vue"),
    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next("/signin");
      } else {
        if (store.getters["userAccessData"].data.user_active != "active") {
          return next();
        } else {
          next("/account");
        }
      }
    },
  },
  {
    path: "/signUpThankyou",
    name: "signUpThankyou",
    component: () => import("@/views/Account/Auth/signUpThankyou.vue"),
  },
  {
    path: "/PasswordResetNewPass",
    name: "PasswordResetNewPass",
    component: () => import("@/views/Account/Auth/PasswordResetNewPass.vue"),
  },
];
