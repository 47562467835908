//------------------------------------------------------------------------
//	IMPORTS für die Task Routen
//------------------------------------------------------------------------
import Tasks from "@/views/Tasks.vue";
import store from "@/store";

export default [
  {
    path: "/tasks",
    name: "Tasks",
    component: Tasks,

    children: [],

    beforeEnter: (to, from, next) => {
      if (!store.getters["authenticated"]) {
        return next("/signin");
      } else {
        if (store.getters["userAccessData"].data.user_active != "active") {
          return next("/notActive");
        } else {
          // store.dispatch("getAllTasksStatus").then(() => {});

          next();
        }
      }
    },
  },
];
