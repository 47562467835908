import axios from "axios";

export default {
  state: {
    customers: [],
  },
  mutations: {
    CUSTOMER_CREATE(state, payload) {
      state.customers.push(payload);
    },
    CUSTOMERS_LOAD(state, payload) {
      state.customers = payload;
    },
  },

  //read our informations from the state and show it anywhere in the app
  getters: {
    loadCustomers(state) {
      return state.customers;
    },
  },
  //actions commit mutations
  actions: {
    getCustomers({ commit }) {
      return new Promise((resolve) => {
        commit("SHOW_TABLE_LOADING_INDICATOR", true);
        axios
          .get("tf24API/getUsers")
          .then((response) => {
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            commit("CUSTOMERS_LOAD", response.data);
            resolve();
          })
          .catch((error) => {
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            console.log("error loading customers data: " + error);
          });
      });
    },

    // erstellt einen neuen Kunden in der Datenbank
    createCustomer({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/createNewUser", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            commit("CUSTOMER_CREATE", response);
            resolve(true);
          })
          .catch((error) => {
            console.log("error create customer " + error);
            console.log(error);
          });
      });
    },

    updateCustomer(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateCustomer", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error update customer " + error);
            console.log(error);
          });
      });
    },
    updateCustomerEditData(_, payload) {
      return new Promise((resolve) => {
        axios
          .put("api/organization/update", payload, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            console.log(response);
            resolve(true);
          })
          .catch((error) => {
            console.log("error updateCustomerEditData " + error);
            console.log(error);
          });
      });
    },
  },
};
