<template>
  <div id="taskMainOptions" class="">
    <v-menu v-model="taskData.menuTaskMainOptions" :close-on-content-click="false" :nudge-right="0" transition="slide-x-transition" offset-y min-width="290px">
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              :disabled="taskData.task_status === 'inProgress'"
              elevation="1"
              icon
              dense
              class="tooltip-action-btn">
              <v-icon size="1.2rem" color="primary">mdi-cogs</v-icon>
            </v-btn>
          </template>
          <span>Task Optionen</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <DownloadFileOriginal :taskData="taskData" :showIcon="false" :showText="true" />
              <DownloadFile
                :task-data="taskData"
                :show-text="true"
                :show-icon="true"
                icon-size="1.2rem"
                icon-color="white"
                :btnShowTextShort="false"
                btnTextLong="Original"
                btnTextShort="Ori"
                :btnOutlined="false"
                btnColor="primary"
                btnColorText="white--text"
                downloadType="ori" />

              <DownloadFile
                :task-data="taskData"
                :show-text="true"
                :show-icon="true"
                icon-size="1.2rem"
                icon-color="white"
                :btnShowTextShort="false"
                btnTextLong="Modified"
                btnTextShort="Mod"
                :btnOutlined="false"
                btnColor="primary"
                btnColorText="white--text"
                downloadType="mod" />

              <DownloadFile
                :task-data="taskData"
                :show-text="true"
                :show-icon="true"
                icon-size="1.2rem"
                icon-color="white"
                :btnShowTextShort="false"
                btnTextLong="Ini"
                btnTextShort="Ini"
                :btnOutlined="false"
                btnColor="primary"
                btnColorText="white--text"
                downloadType="ini" />

              <DownloadFile
                :task-data="taskData"
                :show-text="true"
                :show-icon="true"
                icon-size="1.2rem"
                icon-color="white"
                :btnShowTextShort="false"
                btnTextLong="Zipped"
                btnTextShort="zip"
                :btnOutlined="false"
                btnColor="primary"
                btnColorText="white--text"
                downloadType="zip" />

              <v-divider class="mt-3 mb-1"></v-divider>
              <!--                  </v-btn-toggle>-->
              <v-row class="mt-2">
                <v-col cols="12" md="8">
                  <v-file-input
                    v-model="taskData.task_modFile"
                    :rules="fileRules"
                    outlined
                    dense
                    label="Mod File schicken"
                    append-outer-icon="mdi-send amber--text"
                    color="amber accent-3"
                    @click:append-outer="openCustomerFilePricesDialog"
                    @change="requestSendModFile(taskData)">
                  </v-file-input>
                  <!--                      <v-btn small rounded color="primary" @click="sendModFile(taskData)">Senden</v-btn>-->
                </v-col>
                <v-col cols="12" md="4">
                  <!--                      <v-btn small rounded color="primary" class="ml-2" @click="openCustomerFilePricesDialog()"> Kundenpreise</v-btn>-->
                  <v-text-field
                    dense
                    outlined
                    v-model="taskData.task_filePrice"
                    append-outer-icon="mdi-credit-card-edit-outline amber--text"
                    label="Preis ändern"
                    hide-details
                    @click:append-outer="openCustomerFilePricesDialog"
                    @input="handlePriceInput"></v-text-field>
                  <v-btn v-if="taskData.isPriceChanged" small rounded color="primary" class="mt-2" @click="savePrice()"> Preis speichern </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-chip small class="ml-2" :color="$customFunctions.masterSlaveFileCheckText(taskData.task_fileName) === 'Master' ? 'teal accent-3' : 'red accent-4'">
                {{ $customFunctions.masterSlaveFileCheckText(taskData.task_fileName) }}
              </v-chip>
              <v-chip small class="ml-2" color="primary"> Checksumme: {{ taskData.task_automaticChecksum }}</v-chip>
              <v-chip small class="ml-2" color="primary"> Job #{{ taskData.task_job_id }}</v-chip>

              <TaskItemInformations :taskData="taskData" class="mt-2" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!--        <v-btn text color="primary" @click="bindWorker(taskData)">Zuweisen</v-btn>-->
        </v-card-actions>
      </v-card>
    </v-menu>

    <!--            {{ taskData }}-->
  </div>
</template>
<script>
import { customFunctions as $customFunctions } from "@/customFunctions";
import DownloadFileOriginal from "@/components/tasks/tableItems/fileDownload/DownloadFile.vue";

import TaskItemInformations from "@/components/tasks/tableItems/ItemTaskInformations.vue";
import DownloadFile from "@/components/tasks/tableItems/fileDownload/DownloadFile.vue";

export default {
  name: "TaskMainOptions",
  components: {
    DownloadFile,
    DownloadFileOriginal,
    TaskItemInformations,
  },
  props: {
    taskData: {},
  },

  data: () => ({
    dialogFilePrices: false,
    fileRules: [(v) => !!v || "Datei ist erforderlich"],
  }),

  methods: {
    handlePriceInput(taskData) {
      taskData.isPriceChanged = true; // Markieren Sie das Datenelement als geändert
    },

    openCustomerFilePricesDialog() {
      this.dialogFilePrices = true;
    },
    setCloseDialogFilePrices() {
      this.dialogFilePrices = false;
    },
    savePrice() {
      // this.$store.dispatch("task/savePrice", this.taskData);
    },

    requestSendModFile(taskData) {
      // this.$store.dispatch("task/requestSendModFile", taskData);
    },
  },
  computed: {
    $customFunctions() {
      return $customFunctions;
    },
  },
};
</script>
