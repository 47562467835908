<template>
  <div id="DialogTaskMessages">
    <v-dialog v-model="dialog" max-width="800px" persistent>
      <v-card>
        <v-card-title>
          <v-row align="center" justify="space-between">
            <v-col cols="auto">
              <span class="headline">Task #{{ taskData.task_id }} Nachrichten</span>
            </v-col>
            <v-col cols="auto">
              <v-btn rounded color="primary" @click="closeDialog">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!--          <div>-->
          <!--            <span class="headline">Task #{{ taskData.task_id }} Nachrichten </span>-->
          <!--            <v-btn rounded color="primary" @click="closeDialog">-->
          <!--              <v-icon>mdi-close</v-icon>-->
          <!--            </v-btn>-->
          <!--          </div>-->
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <div>
                  <h4>{{ taskData.vehicle_brand }} {{ taskData.vehicle_model }}</h4>
                  {{ taskData.vehicle_engineCCm }}L {{ taskData.vehicle_enginePS }}PS
                  {{ taskData.vehicle_ecuBrand }}
                  {{ taskData.vehicle_ecuType }}<br />
                </div>
                <div>
                  <div class="d-flex">
                    <div v-for="method in getMethods" :key="method"><img alt="method" :src="getMethodIcons(method)" width="60" /></div>
                  </div>
                  <br />
                </div>
              </v-col>
              <v-col cols="12" md="6"> </v-col>
            </v-row>
          </v-container>

          <TaskMessages :selected-task="taskData"></TaskMessages>

          <!--          <v-container>-->
          <!--            <v-row no-gutters>-->
          <!--              <v-col cols="12" md="1">-->
          <!--                <v-avatar color="primary" size="56">-->
          <!--                  <span class="text-white">{{ avatarName }}</span>-->
          <!--                </v-avatar>-->
          <!--              </v-col>-->
          <!--              <v-col cols="12" md="11">-->
          <!--                <v-textarea v-model="input" rows="3" hide-details flat outlined label="Nachricht...." @keydown.enter="comment">-->
          <!--                  <template v-slot:append-outer>-->
          <!--                    <v-btn class="mx-0" color="primary" rounded depressed @click="comment">-->
          <!--                      <v-icon>mdi-send</v-icon>-->
          <!--                    </v-btn>-->
          <!--                  </template>-->
          <!--                </v-textarea>-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <!--            <v-row no-gutters>-->
          <!--              <v-col cols="12" md="1"></v-col>-->
          <!--              <v-col cols="12" md="6">-->
          <!--                <div>-->
          <!--                  <span class="text-dark">-->
          <!--                    <strong>Attachment</strong>-->
          <!--                  </span>-->
          <!--                  &lt;!&ndash; TODO: FILEINPUT wird geleert wenn ich einen Schritt zurück gehe &ndash;&gt;-->
          <!--                  <DragAndDrop ref="refDragDrop" @fileToUpload="setFileUpload"></DragAndDrop>-->
          <!--                </div>-->
          <!--              </v-col>-->
          <!--            </v-row>-->
          <!--          </v-container>-->

          <!--          <v-container>-->
          <!--            <v-timeline>-->
          <!--              <v-timeline-item-->
          <!--                v-for="(message, i) in messages"-->
          <!--                :key="i"-->
          <!--                :color="message.isUser ? 'green' : 'red'"-->
          <!--                :icon="message.isUser ? 'mdi-account-circle' : 'mdi-account'"-->
          <!--                :fill-dot="message.isUser"-->
          <!--                :reverse="message.isUser">-->
          <!--                <div :class="message.isUser ? 'text-right' : 'text-left'">-->
          <!--                  {{ message.text }}-->
          <!--                </div>-->
          <!--              </v-timeline-item>-->
          <!--            </v-timeline>-->
          <!--          </v-container>-->
          <!--          <v-container>-->
          <!--            <v-timeline>-->
          <!--              <v-timeline-item v-for="(event, index) in events" :key="index">-->
          <!--                &lt;!&ndash;                <template v-slot:icon>&ndash;&gt;-->
          <!--                &lt;!&ndash;                  <v-icon v-if="event.myMessage">{{ event.avatar }}</v-icon>&ndash;&gt;-->
          <!--                &lt;!&ndash;                  <v-icon v-else>{{ event.avatar }}</v-icon>&ndash;&gt;-->
          <!--                &lt;!&ndash;                </template>&ndash;&gt;-->
          <!--                <template v-slot:icon>-->
          <!--                  <div v-if="event.isIcon">-->
          <!--                    <v-icon class="text-white">{{ event.avatar }}</v-icon>-->
          <!--                  </div>-->
          <!--                  <span v-else class="text-white"> {{ event.avatar }} </span>-->
          <!--                </template>-->
          <!--                <v-timeline-item-title>{{ event.text }}</v-timeline-item-title>-->
          <!--                <v-timeline-item-content>-->
          <!--                  <p v-if="event.myMessage" class="text-right">{{ event.time }}</p>-->
          <!--                  <p v-else>{{ event.message }}</p>-->
          <!--                </v-timeline-item-content>-->
          <!--              </v-timeline-item>-->
          <!--            </v-timeline>-->
          <!--            &lt;!&ndash;            <v-timeline dense>&ndash;&gt;-->
          <!--            &lt;!&ndash;              <v-slide-x-transition group>&ndash;&gt;-->
          <!--            &lt;!&ndash;                <v-timeline-item v-for="event in timeline" :key="event.id" class="mb-4" :color="event.color" medium>&ndash;&gt;-->
          <!--            &lt;!&ndash;                  <template v-slot:icon>&ndash;&gt;-->
          <!--            &lt;!&ndash;                    <div v-if="event.isIcon">&ndash;&gt;-->
          <!--            &lt;!&ndash;                      <v-icon class="text-white">{{ event.avatar }}</v-icon>&ndash;&gt;-->
          <!--            &lt;!&ndash;                    </div>&ndash;&gt;-->
          <!--            &lt;!&ndash;                    <span v-else class="text-white"> {{ event.avatar }} </span>&ndash;&gt;-->
          <!--            &lt;!&ndash;                  </template>&ndash;&gt;-->
          <!--            &lt;!&ndash;                  <v-row justify="space-between">&ndash;&gt;-->
          <!--            &lt;!&ndash;                    <v-col cols="7" v-text="event.text"></v-col>&ndash;&gt;-->
          <!--            &lt;!&ndash;                    <v-col class="text-right" cols="5" v-text="event.time"></v-col>&ndash;&gt;-->
          <!--            &lt;!&ndash;                  </v-row>&ndash;&gt;-->
          <!--            &lt;!&ndash;                </v-timeline-item>&ndash;&gt;-->
          <!--            &lt;!&ndash;              </v-slide-x-transition>&ndash;&gt;-->
          <!--            &lt;!&ndash;            </v-timeline>&ndash;&gt;-->
          <!--          </v-container>-->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn rounded color="primary" @click="closeDialog">Schließen</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { customFunctions as $customFunctions } from "@/customFunctions";
import TaskItemInformations from "@/components/tasks/tableItems/ItemTaskInformations.vue";
import { mapGetters } from "vuex";
import DragAndDrop from "@/components/ui/DragAndDrop.vue";
import TaskMessages from "@/components/jobs/jobDetails/TaskMessages.vue";
export default {
  name: "DialogTaskMessages",
  components: { TaskMessages, DragAndDrop, TaskItemInformations },
  props: { taskData: Object, dialog: Boolean },

  data: () => ({
    events: [],
    input: null,
    nonce: 0,
    messages: [
      { text: "Hello, how are you?", isUser: false },
      { text: "Hello, how are you?", isUser: false },
      { text: "Hello, how are you?", isUser: false },
      { text: "I am fine, thank you.", isUser: true },
      { text: "Great to hear that!", isUser: false },
      // and so on...
    ],
  }),

  watch: {},

  mounted() {},

  methods: {
    closeDialog() {
      this.$emit("close-dialog-task-messages");
    },

    setFileUpload(file) {},
    comment() {
      if (!this.input) {
        return;
      }

      this.postChat().then(async () => {
        this.input = null;
        await this.loadEvents(this.taskData.task_id);
      });
    },

    addAttachment() {},

    formatDate(time) {
      return time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents, offset) => {
        return ` ${contents
          .split(" ")
          .map((v) => v.charAt(0))
          .join("")}`;
      });
    },

    async postChat() {
      const response = await this.axios.post("/api/tasks/" + this.taskData.task_id + "/chat", {
        job_id: this.taskData.task_job_id,
        task_id: this.taskData.task_id,
        chat_text: this.input,
        user_type: "tuner",
        file_path: "",
        file_type: "",
        file_name: "",
      });
      return response.status === 200 && response.data.code === 120 ? response.data.data : [];
    },

    async getChats(task_id) {
      const response = await this.axios.get("/api/tasks/" + task_id + "/chats");
      return response.status === 200 && response.data.code === 120 ? response.data.data : [];
    },

    async getHistory(task_id) {
      const response = await this.axios.get("/api/tasks/" + task_id + "/history");
      return response.status === 200 && response.data.code === 120 ? response.data.data : [];
    },

    async loadEvents(task_id) {
      this.events = []; // Clear the events array

      // Chats vom backend laden
      this.getChats(task_id).then((chats) => {
        chats.forEach((x) => {
          const ownMessage = x.chat_user_id === this.userAccessData.data.user_id;

          this.events.push({
            id: "m" + x.chat_id,
            text: x.chat_text,
            time: this.formatDate(x.chat_create_date),
            avatar: ownMessage ? this.avatarName : "mdi-face-agent",
            isIcon: !ownMessage,
            color: ownMessage ? "primary" : "pink",
            myMessage: ownMessage,
          });
        });
      });

      // History vom backend laden
      this.getHistory(task_id).then((history) => {
        history.forEach((x) => {
          const ownMessage = x.chat_user_id === this.userAccessData.data.user_id;

          this.events.push({
            id: "h" + x.history_id,
            color: ownMessage ? "primary" : "pink",
            text: x.text,
            time: this.formatDate(x.created_date_utc),
            avatar: "mdi-clipboard-text-clock",
            isIcon: true,
            myMessage: ownMessage,
          });
        });
      });
    },

    openDialog(item) {
      this.loadEvents(item.task_id);
    },

    getMethodIcons(method) {
      return $customFunctions.getMethodIcons(method);
    },
  },

  computed: {
    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),
    $customFunctions() {
      return $customFunctions;
    },
    timeline() {
      return this.events.slice().reverse();
    },

    ...mapGetters({
      authenticated: "user",
      user: "user",
      userAccessData: "userAccessData",
    }),

    avatarName() {
      if (!this.userAccessData) {
        return "";
      }
      const firstNameFirstChar = this.userAccessData.data.user_firstName.charAt(0);
      const secondNameFirstChar = this.userAccessData.data.user_secondName.charAt(0);

      return firstNameFirstChar + secondNameFirstChar;
    },

    getMethods() {
      if (!this.taskData) {
        return [];
      }
      return $customFunctions.getMethodsFromTaskModifications(this.taskData.task_modifications);
    },
  },
};
</script>
<style scoped>
.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
  background-color: green;
}

.text-right {
  text-align: right;
}
</style>
