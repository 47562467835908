import axios from "axios";

export default {
  state: {
    taskStatus: {},
    tasks: [],
    tasksForJob: [],
    taskPlacedDates: [],
    taskStatusData: {
      task_status: "",
    },
    taskCreateModal: false,
    taskEditModal: false,
    taskRentEditModal: false,
    taskAddToJobToggleModal: false,
    taskHistorys: [],
    taskHistoryModal: false,
    tasksAdminOpen: [],
    tasksAdminSupport: [],
    tasksAdminClosed: [],
    taskMetas: [],
    tasksCustomer: [],
  },
  mutations: {
    TASK_STATUS_DATA(state, payload) {
      state.taskStatusData = payload;
    },
    TASKS_LOAD(state, payload) {
      state.tasks = payload;
    },
    TASKS_JOB_LOAD(state, payload) {
      state.tasksForJob = payload;
    },
    TASK_CREATE(state, payload) {
      state.tasks.push(payload);
    },
    TASK_STATUS(state, payload) {
      state.taskStatusData = payload;
    },
    TASK_STATUS_CLEAR(state) {
      state.taskStatus = {};
    },
    TASK_ADD_TO_JOB_MODAL(state, payload) {
      state.taskAddToJobToggleModal = payload;
    },
    TASK_CREATE_MODAL(state, payload) {
      state.taskCreateModal = payload;
    },
    TASK_EDIT_MODAL(state, payload) {
      state.taskEditModal = payload;
    },
    TASK_HISTORY_MODAL(state, payload) {
      state.taskHistoryModal = payload;
    },
    TASK_ADMIN_OPEN(state, payload) {
      state.tasksAdminOpen = payload;
    },
    TASK_ADMIN_SUPPORT(state, payload) {
      state.tasksAdminSupport = payload;
    },
    TASK_ADMIN_CLOSED(state, payload) {
      state.tasksAdminClosed = payload;
    },
    TASK_METAS_LOAD(state, payload) {
      state.taskMetas = payload;
    },
    TASK_CUSTOMER_LOAD(state, payload) {
      state.tasksCustomer = payload;
    },
  },
  getters: {
    loadTasks(state) {
      return state.tasks;
    },
    taskStatusData(state) {
      return state.taskStatusData;
    },
    loadTasksForJob(state) {
      return state.tasksForJob;
    },
    taskStatus(state) {
      return state.taskStatus;
    },
    taskCreateModal(state) {
      return state.taskCreateModal;
    },
    taskEditModal(state) {
      return state.taskEditModal;
    },
    taskAddToJobToggleModal(state) {
      return state.taskAddToJobToggleModal;
    },
    loadTaskHistorys(state) {
      return state.taskHistorys;
    },
    taskHistoryModal(state) {
      return state.taskHistoryModal;
    },
    loadTaskAdminOpen(state) {
      return state.tasksAdminOpen;
    },
    loadTaskAdminSupport(state) {
      return state.tasksAdminSupport;
    },
    loadTaskAdminClosed(state) {
      return state.tasksAdminClosed;
    },
    loadTaskMetas(state) {
      return state.taskMetas;
    },
    loadTasksCustomer(state) {
      return state.tasksCustomer;
    },
  },

  actions: {
    taskHistoryModalToggle({ commit }, payload) {
      commit("TASK_HISTORY_MODAL", payload);
    },
    taskAddToJobToggleModal({ commit }, payload) {
      commit("TASK_ADD_TO_JOB_MODAL", payload);
    },
    taskCreateToggleModal({ commit }, payload) {
      commit("TASK_CREATE_MODAL", payload);
    },
    taskEditToggleModal({ commit }, payload) {
      commit("TASK_EDIT_MODAL", payload);
    },
    clearTaskStatus({ commit }) {
      commit("TASK_STATUS_CLEAR");
    },

    // erstellt einen neuen Task
    createTask({ commit }, payload) {
      return new Promise((resolve) => {
        console.log("payload data create Task: ", payload);
        axios
          .post("api/task", payload, {
            headers: { "Content-type": "application/json" },
          })
          .then((response) => {
            commit("TASK_DATA", response);
            resolve(response);
          })
          .catch((error) => {
            console.log("createTask error: ", error);
            resolve(false);
          });
      });
    },

    // lädt den Task Status
    loadTaskStatusData({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("api/loadTaskStatusData", { params: payload })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data.code === 120) {
                console.log("loadTaskStatusData res", response.data);
                resolve(response.data.taskStatusData);
                commit("TASK_STATUS_DATA", response.data.taskStatusData);
              } else {
                reject(false);
              }
            }
          })
          .catch((error) => {
            console.log("error loading loadTaskStatusData: " + error);
          });
      });
    },

    // lade alle Tasks vom User X
    getAllTasksFromUser({ commit }, payload) {
      commit("SHOW_TABLE_LOADING_INDICATOR", true);
      return new Promise((resolve) => {
        const filter = {
          selectedDateRange: payload.selectedDateRange,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        };

        axios
          .get("api/tasks/getAllTasksWithVehicleFromUser", {
            params: filter,
          })
          .then((response) => {
            console.log("response.data.data: ", response.data.data);
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            commit("TASKS_LOAD", response.data.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading tasks data: " + error);
          });
      });
    },

    updateTaskWithExistsOriginalFilePath({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateTaskWithExistsOriginalFilePath", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((responseTaskData) => {
            resolve(responseTaskData);
          })
          .catch((error) => {
            resolve(false);
            const statusObj = {
              toast: true,
              position: "top-start",
              icon: "error",
              title: "TASK Update cant be created! 0x92338x88",
              showConfirmButton: false,
              timer: 5000,
            };

            commit("TASK_STATUS", statusObj);
            console.log("updateTaskWithExistsOriginalFilePath error" + error);
          });
      });
    },

    // update das TASK mit den Änderungen
    updateTask({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/updateTask", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            console.log(response);
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              //anderer statuscode als 200
              //success false
              const statusObj = {
                toast: true,
                position: "top-start",
                icon: "error",
                title: "Fehler TASK konnte nicht geändert werden!. Code 0x20032",
                showConfirmButton: false,
                timer: 5000,
              };
              commit("ALERT_STATUS", statusObj);
              console.log("TASK Update Error! 0x20032");

              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with update TASK" + error);
          });
      });
    },

    // ladet alle TASKe vom Job mit der ID X von der Datenbank
    loadTasksForJob({ commit }, payload) {
      commit("SHOW_TABLE_LOADING_INDICATOR", true);

      return new Promise((resolve) => {
        axios
          .get("api/jobs/" + payload + "/tasks")
          .then((response) => {
            commit("SHOW_TABLE_LOADING_INDICATOR", false);
            commit("TASKS_JOB_LOAD", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            console.log("error loading tasks for Oldjob data: " + error);
          });
      });
    },

    // Entfernt das TASK von einem Job bzw. Auftrag
    removeTaskFromJob({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/removeTaskFromJob", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // zahl die als Row gelöscht oder update gemacht wurde.
              if (response.data > 0) {
                const statusObj = {
                  toast: true,
                  position: "top-start",
                  icon: "success",
                  title: "TASK erfolgreich vom Auftrag entfernt!",
                  showConfirmButton: false,
                  timer: 3000,
                };
                commit("ALERT_STATUS", statusObj);

                resolve(true);
              } else {
                //success false
                const statusObj = {
                  toast: true,
                  position: "top-start",
                  icon: "info",
                  title: "Keine Änderungen für TASK entfernen gefunden.",
                  showConfirmButton: false,
                  timer: 5000,
                };
                commit("ALERT_STATUS", statusObj);
                console.log("rent remove from Oldjob success false 0x99002x");

                resolve(false);
              }
            } else {
              //anderer statuscode als 200
              //success false
              const statusObj = {
                toast: true,
                position: "top-start",
                icon: "error",
                title: "Fehler TASK konnte vom Auftrag nicht entfernt werden!. Code 0x20032234",
                showConfirmButton: false,
                timer: 5000,
              };
              commit("ALERT_STATUS", statusObj);
              console.log("Rent not possible to remove from Oldjob! 0x200322");

              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with remove rent from Oldjob." + error);
          });
      });
    },

    // fügt das TASK zu einen Auftrag hinzu
    addTaskToJob({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/addTaskToJob", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                const statusObj = {
                  toast: true,
                  position: "top-start",
                  icon: "success",
                  title: "TASK erfolgreich hinzugefügt!",
                  showConfirmButton: false,
                  timer: 3000,
                };
                commit("ALERT_STATUS", statusObj);

                resolve(true);
              } else {
                //success false
                const statusObj = {
                  toast: true,
                  position: "top-start",
                  icon: "info",
                  title: "Keine Änderungen für die Speicherung gefunden.",
                  showConfirmButton: false,
                  timer: 5000,
                };
                commit("ALERT_STATUS", statusObj);
                console.log("TASKe keine Änderungen 0x99002");

                resolve(false);
              }
            } else {
              //anderer statuscode als 200
              //success false
              const statusObj = {
                toast: true,
                position: "top-start",
                icon: "error",
                title: "Fehler TASK konnte nicht hinzugefügt werden!. Code 0x200322",
                showConfirmButton: false,
                timer: 5000,
              };
              commit("ALERT_STATUS", statusObj);
              console.log("TASK hinzufügen Error! 0x200322");

              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with add rent to Oldjob" + error);
          });
      });
    },

    // holt sich den status für den TASK MIT ID X
    getTaskStatusWithTaskID(_, payload) {
      return new Promise((resolve) => {
        const data = {
          task_id: payload.task_id,
        };
        axios
          .post("tf24API/getTaskStatusWithTaskID", data, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error getTaskStatus" + error);
          });
      });
    },
    // holt sich die task metas für den Task
    getTaskMetasForTask({ commit }, payload) {
      return new Promise((resolve) => {
        const data = {
          task_id: payload.task_id,
        };
        axios
          .get("tf24API/getTaskMetasForTask", {
            params: data,
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              commit("TASK_METAS_LOAD", response.data);
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error getTaskMetasForTask" + error);
          });
      });
    },

    // LADET BZW. UPDATED ALLE TASKS DIE IN PROGRESS SIND UND AUCH MÖGLICH IST DIESE IN FAILED ODER FINISHED UPZUDATEN.
    // getAllTasksStatus() {
    //   return new Promise(resolve => {
    //     axios
    //       .post("tf24API/getAllTasksStatus", {
    //         headers: {
    //           "Content-type": "application/json",
    //         },
    //       })
    //       .then(response => {
    //         if (response.status === 200) {
    //           // success true
    //           if (response.data === true) {
    //             resolve(true);
    //           } else {
    //             resolve(false);
    //           }
    //         } else {
    //           resolve(false);
    //         }
    //       })
    //       .catch(error => {
    //         console.log("error getAllTasksStatus" + error);
    //       });
    //   });
    // },

    payTaskWithTaskID(_, payload) {
      return new Promise((resolve) => {
        const data = {
          task_payment_status: payload.task_payment_status,
        };
        axios
          .put("api/tasks/updatePaymentStatus/" + payload.task_id, data, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            console.log(response);
            resolve(response.status === 200);
          })
          .catch((error) => {
            console.log("error payTaskWithTaskID" + error);
          });
      });
    },

    // ladet alle Tasks die offen, updateUpgrade, inReview, reviewDone sind, für den Admin
    getOpenTasksForAdmin({ commit }, payload) {
      return new Promise((resolve) => {
        //Files die Offen, Fertig, oder Support benötigen
        const dataSupport = [];
        const dataOpen = [];

        axios
          .get("tf24API/getOpenTasksForAdmin", {
            params: payload,
          })
          .then((response) => {
            response.data.forEach((task) => {
              // wenn offen oder update bzw. upgrade
              if (task.task_type == "updateUpgrade" || task.task_type == "inReview" || task.task_type == "reviewDone") {
                dataSupport.push(task);
              } else {
                dataOpen.push(task);
              }
            });
            commit("TASK_ADMIN_OPEN", dataOpen);
            commit("TASK_ADMIN_SUPPORT", dataSupport);
            resolve();
          })
          .catch((error) => {
            console.log("error loading tasks for admin error : " + error);
          });
      });
    },

    // lädt alle geschlossenden Tasks für den Admin
    getClosedTasksForAdmin({ commit }, payload) {
      return new Promise((resolve) => {
        const dataClosed = [];

        axios
          .get("tf24API/getClosedTasksForAdmin", {
            params: payload,
          })
          .then((response) => {
            response.data.forEach((task) => {
              dataClosed.push(task);
            });

            console.log("getClosedTasksForAdmin", dataClosed);

            commit("TASK_ADMIN_CLOSED", dataClosed);
            resolve();
          })
          .catch((error) => {
            console.log("error loading tasks for admin error : ", error);
          });
      });
    },

    // schließt den Aufträge mit der ID
    closeTask(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/closeTask", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with close of Task" + error);
          });
      });
    },
    // löscht den Aufträge mit der ID
    deleteTask(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/deleteTask", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with Löschung Task" + error);
          });
      });
    },
    // Archiviert den Task
    archiveTask(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/archiveTask", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with Archive Task" + error);
          });
      });
    },

    // transferiert den Task auf einen anderen Job
    transferTaskToJob(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/transferTaskToJob", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with transferTaskToJob " + error);
          });
      });
    },
    // ladet alle Tasks für den Kunden
    loadTasksForCustomer({ commit }, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/loadTasksForCustomer", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            commit("TASK_CUSTOMER_LOAD", response.data);
            resolve();
          })
          .catch((error) => {
            console.log("error loading tasks for customer error : " + error);
          });
      });
    },
    // Ändert das Fahrzeug im Task und Job
    taskChangeVehicle(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/taskChangeVehicle", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with taskChangeVehicle " + error);
          });
      });
    },
    // Ändert die VIN (Fahrgestellnummer) im Task und Job
    taskChangeVehicleVIN(_, payload) {
      return new Promise((resolve) => {
        axios
          .post("tf24API/taskChangeVehicleVIN", payload, {
            headers: {
              "Content-type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              // success true
              if (response.data === true) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          })
          .catch((error) => {
            console.log("error with taskChangeVehicleVIN " + error);
          });
      });
    },
  },
};
