<template>
  <div id="taskTable">
    <v-data-table
      v-model="selectedTasksFromTableItem"
      :headers="tasksTableField"
      :items="tasks"
      :single-select="true"
      item-key="task_id"
      checkbox-color="primary"
      :hide-default-footer="false"
      :items-per-page="10"
      :search="searchQuery"
      :custom-filter="customFilter">
      <template v-slot:item.task_id="{ item }">
        <v-badge
          :content="$customFunctions.getShortText(item.task_type)"
          :value="item.task_type"
          :color="$customFunctions.getTaskTypeColor(item.task_type)"
          overlap
          bordered
          offset-x="30"
          offset-y="3">
          <v-chip small class="" color="primary" outlined> {{ "#" + item.task_id }}</v-chip>
        </v-badge>
      </template>
      <template v-slot:item.task_info_1="{ item }">
        <div class="mt-1 mb-1">
          <h3 class="pb-0">
            {{ item.vehicle_brand }} -
            <span class=""
              ><i class=""> {{ item.vehicle_category }}</i></span
            >
          </h3>
          <i>{{ item.vehicle_model }}</i>
        </div>

        <div class="d-flex">
          <v-chip-group column>
            <v-chip color="" outlined small>
              <v-icon color="primary" class="mr-2">mdi-engine</v-icon>
              {{ item.vehicle_engineCCm }}L {{ item.vehicle_enginePS }}HP
            </v-chip>
            <v-chip color="" outlined small>
              <v-icon color="primary" class="mr-2">mdi-poll</v-icon>
              {{ item.vehicle_emission }}
            </v-chip>
            <v-chip color="" outlined small>
              <v-icon color="primary" class="mr-2">mdi-cpu-64-bit</v-icon>
              {{ item.vehicle_ecuBrand }} {{ item.vehicle_ecuType }}
            </v-chip>
          </v-chip-group>
        </div>
      </template>

      <!--      <template v-slot:item.support="{ item }">-->
      <!--        <v-btn rounded dense outlined small color="red accent-4" class="white&#45;&#45;text" v-if="item.task_type === 'new'" @click="createTicket(item)">Ticket erstellen </v-btn>-->
      <!--        <v-btn rounded dense outlined small color="green accent-4" class="white&#45;&#45;text" v-else @click="openTicket(item)"> Ticket ansehen </v-btn>-->
      <!--      </template>-->

      <template v-slot:item.task_info_2="{ item }">
        <strong>{{ item.task_modifications }}</strong
        ><br />
        <span>{{ item.task_filePrice }}Cr.</span>
        <v-icon size="1rem" :color="$customFunctions.paymentStatusColor(item.task_paymentStatus)">
          {{ $customFunctions.paymentStatusIcon(item.task_paymentStatus) }}
        </v-icon>
        <br />
        <span>{{ $customFunctions.dateFormatWithTime(item.task_created) }}</span>
      </template>

      <!--      <template v-slot:item.progress="{ item }">-->
      <!--       -->
      <!--      </template>-->

      <template v-slot:item.task_messages="{ item }">
        <TaskTableOptions :item="item" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import SocketioService from "@/socketServiceClient";
import TaskTableOptions from "@/components/tasks/TaskTableOptions.vue";

export default {
  name: "TasksTable",
  components: {
    TaskTableOptions,
  },
  props: {
    searchQuery: {
      type: String,
      default: "",
    },

    tasks: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    selectedTaskData: {},
    selectedTasksFromTableItem: [],
    expandedRows: [],
    tasksTableField: [
      { value: "task_id", text: "Task #" },
      { value: "task_info_1", text: "Fahrzeug" },
      { value: "task_info_2", text: "Info" },
      // { value: "support", text: "Support" },
      { value: "task_messages", text: "Task Optionen" },
    ],
    dialogTaskMessages: false,
  }),

  watch: {
    // tasks: {
    //   deep: true, // Überwachen Sie die Änderungen der Eigenschaften des Objekts die tiefer als die erste Ebene sind
    //   handler(newTasks, oldTasks) {
    //     // Überprüfen Sie die Preisänderungen für jedes Datenelement
    //     // console.log("newTasks ", newTasks);
    //     newTasks.forEach((newTask, index) => {
    //       console.log("newTask.task_filePrice ", newTask.task_filePrice);
    //       console.log("oldTasks[index].task_filePrice ", oldTasks[index].task_filePrice);
    //       if (newTask.task_filePrice !== oldTasks[index].task_filePrice) {
    //         console.log("Preis geändert" + newTask.task_filePrice);
    //         newTask.isPriceChanged = true;
    //       } else {
    //         newTask.isPriceChanged = false;
    //       }
    //     });
    //   },
    // },

    // überwache die ausgewählten tasks, solbald sich diese ändern, dann müssen wir den alten Raum verlassen und den neuen Raum betreten
    selectedTasksFromTableItem: function (newVal, oldVal) {
      // wenn wir einen neuen task auswählen, dann müssen wir den alten Raum verlassen
      if (oldVal != null) {
        if (oldVal.length > 0) {
          SocketioService.leaveRoom("task_room_" + oldVal[0].task_id);
        }
      }
      // wenn wir einen neuen task auswählen, dann müssen wir den neuen Raum betreten
      if (newVal != null) {
        if (newVal.length > 0) {
          // join task room damit wir die updates bekommen
          // SocketioService
          SocketioService.joinTaskRoom("task_room_" + newVal[0].task_id);
        }
      }
    },
  },

  methods: {
    customFilter(value, search, item) {
      search = search.toLowerCase();
      const searchableProperties = [
        "task_id",
        "task_fileName",
        "task_filePrice",
        "task_fileType",
        "task_created",
        "task_modifications",
        "task_info",
        "vehicle_brand",
        "vehicle_model",
        "vehicle_category",
        "vehicle_engineCCm",
        "vehicle_enginePS",
        "vehicle_emission",
        "vehicle_ecuBrand",
        "vehicle_ecuType",
      ];

      return searchableProperties.some((prop) => String(item[prop]).toLowerCase().includes(search));
    },
  },

  computed: {},
};
</script>
